import React, { useState } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import { useTheme, Menu, MenuItem, Box } from '@mui/material';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { logout } from './api';
import { useUser } from './context/UserContext';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 64,
  height: 32,
  padding: 8,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px) translateY(3px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(32px) translateY(3px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 24,
    height: 24,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const HEADER_HEIGHT = 72;

const Layout = ({ children, mode, toggleColorMode }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const hideHeaderOnLogin = useMatch('/login');
  const hideHeaderOnRegister = useMatch('/register');
  const hideHeaderOnInvoicePdf = useMatch('/invoices/:invoiceId/pdf');
  const hideHeaderOnPurchaseOrderPdf = useMatch('/purchase-orders/:poId/pdf');

  const shouldShowHeader = !(
    hideHeaderOnLogin ||
    hideHeaderOnRegister ||
    hideHeaderOnInvoicePdf ||
    hideHeaderOnPurchaseOrderPdf
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    handleClose();
    navigate('/profile');
  };

  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = '/login';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const layoutStyle = {
    height: shouldShowHeader ? `calc(100vh - ${HEADER_HEIGHT}px)` : '100vh',
    display: 'flex',
    flexDirection: 'column',
  };

  const headerStyle = {
    height: `${HEADER_HEIGHT}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px 0 50px',
    backgroundColor: theme.palette.background.default,
  };

  return (
    <div style={{ height: '100vh' }}>
      {shouldShowHeader && (
        <div style={headerStyle}>
          <img src={`${process.env.PUBLIC_URL}/images/Logo.png`} alt="Company Logo" style={{ height: '40px' }} />

          <Box flexGrow={1} />

          <Box display="flex" alignItems="center" gap="20px">
            <Box display="flex" alignItems="center">
              <WbSunnyIcon style={{ color: mode === 'light' ? '#f57e1f' : '#333333', fontSize: '20px' }} />
              <MaterialUISwitch
                checked={mode === 'dark'}
                onChange={toggleColorMode}
                inputProps={{ 'aria-label': 'toggle dark mode' }}
              />
              <Brightness2Icon style={{ color: mode === 'dark' ? '#f57e1f' : '#333333', fontSize: '20px' }} />
            </Box>
            <Avatar shape="square" size={40} onClick={handleClick} icon={<UserOutlined />} style={{ backgroundColor: theme.palette.primary.main, cursor: 'pointer' }}>{user ? user.first_name.charAt(0) : ''}</Avatar>
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      )}
      <Box style={layoutStyle}>
        {children}
      </Box>
    </div>
  );
};

export default Layout;
