import React, { useState, useEffect, useCallback } from 'react';
import { Button, ConfigProvider, Progress, Tag, message, Modal, Tooltip } from 'antd';
import { SyncOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Box, Typography, CircularProgress } from '@mui/material';
import RenderData from '../components/RenderData';
import SideMenu from '../components/SideMenu';
import SyncServiceHistories from '../components/SyncServiceHistories';
import EditEventModal from '../components/EditEvent';
import { fetchEvents, fetchWeatherStations } from '../api';
import { useConstants } from '../context/ConstantsContext';

const tagsData = ['All', 'Active', 'Snow', 'Salt'];

const EventsPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const constants = useConstants();
  const [constantsLoading, setConstantsLoading] = useState(true);
  const [selectedTags, setSelectedTags] = React.useState(['All']);
  const [events, setEvents] = useState([]);
  const [weatherStations, setWeatherStations] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [detailOpen, setDetailOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const [syncModalOpen, setSyncModalOpen] = useState(false);

  useEffect(() => {
    if (constants) {
      setConstantsLoading(false);
    }
  }, [constants]);

  const loadEvents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchEvents();
      console.log('Fetched events:', response);
      setEvents(response);
    } catch (error) {
      messageApi.error('Failed to fetch events');
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  }, [messageApi]);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  useEffect(() => {
    const loadStations = async () => {
      setLoading(true);
      try {
        const stations = await fetchWeatherStations();
        setWeatherStations(stations);
      } catch (error) {
        messageApi.error('Failed to fetch weather stations');
        console.error('Error fetching weather stations:', error);
      } finally {
        setLoading(false);
      }
    }

    loadStations();
  }, [messageApi]);

  const handleTagChange = (tag, checked) => {
    if (tag === 'All') {
      setSelectedTags(checked ? ['All'] : []);
    } else {
      const nextSelectedTags = checked
        ? [...selectedTags.filter((t) => t !== 'All'), tag]
        : selectedTags.filter((t) => t !== tag);
      setSelectedTags(nextSelectedTags.length > 0 ? nextSelectedTags : ['All']);
    }
  };

  const filteredEvents = selectedTags.includes('All')
  ? events
  : events.filter(event => {
      const matchesEventTypeSalt = selectedTags.includes('Salt') && event.event_type === constants.event_type.salt;
      const matchesEventTypeSnow = selectedTags.includes('Snow') && event.event_type === constants.event_type.snow;
      const allTypes = !selectedTags.includes('Snow') && !selectedTags.includes('Salt');
      const matchesStatus = selectedTags.includes('Active') ? event.status === constants.status.active : true;

      return matchesStatus && (matchesEventTypeSalt || matchesEventTypeSnow || allTypes);
  });

  const handleEditClick = (event) => {
    setSelectedEvent(event);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setSelectedEvent(null);
    loadEvents();
    setEditOpen(false);
  };

  const handleDetailClick = (event) => {
    setSelectedEvent(event);
    setDetailOpen(true);
  };

  const handleDetailClose = () => {
    setSelectedEvent(null);
    setDetailOpen(false);
  };

  const handleSyncClick = (event) => {
    setSelectedEvent(event);
    setSyncModalOpen(true);
  };

  const handleSyncClose = () => {
    setSelectedEvent(null);
    setSyncModalOpen(false);
  }

  if (constantsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F57D20',
          colorLinkHover: '#F57D20',
          colorLink: '#F57D20',
        },
      }}
    >
      <Box display="flex" sx={{ marginTop: '20px', marginRight: '16px' }}>
        {contextHolder}
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <span style={{ fontSize: '36px', fontWeight: '500', color: 'black' }}>Events</span>
          <br />

          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box>
              {tagsData.map((tag) => (
                <Tag.CheckableTag
                  key={tag}
                  checked={selectedTags.includes(tag)}
                  onChange={(checked) => handleTagChange(tag, checked)}
                  style={{ padding: '5px 10px', margin: '5px', fontSize: '14px' }}
                >
                  {tag}
                </Tag.CheckableTag>
              ))}
            </Box>

            {/* Sync Service Histories Button */}
            <Button
              type="primary"
              onClick={() => setSyncModalOpen(true)}
            >
              Sync Service Histories
            </Button>
          </Box>
          <Box 
            style={{
              width: '100%',
              display: 'flex',
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: 'row',
              gap: '20px',
              marginTop: '20px',
              flexWrap: 'wrap'
            }}
          >
            {loading? (
              <Typography>Loading events...</Typography>
            ) : (
              filteredEvents.map((event) => (
                  <Box
                    key={event.id}
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '5px',
                      width: '280px',
                      height: '280px',
                      padding: '15px',
                      display: 'flex',
                      flexDirection: 'column',
                      position: "relative",
                      boxShadow: event.status === constants.status.active ? '0 0 15px rgba(245, 125, 32, 0.6)' : '0 0 10px rgba(0, 0, 0, 0.1)',
                      border: event.status === constants.status.active ? "2px solid #F57D20" : "2px solid white",
                      overflow: 'hidden',
                    }}
                  >
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                      <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', gap: '3px' }}>
                        <Typography style={{ fontSize: '14px', fontWeight: '400', color: "#737373", textAlign: 'left' }}>
                          {event.event_start_date}
                        </Typography>
                        <Tag
                          color={event.event_type === constants.event_type.snow ? "blue" : "green"} 
                          style={{ fontSize: '12px', width: '100px', textAlign: 'center' }}
                        >
                          {event.service_type}
                        </Tag>
                      </Box>
                      
                      <Tooltip
                        title={
                          <>
                            Sites Dispatched: {event.num_sites_dispatched || 0}
                            <br />
                            Sites Serviced: {event.num_sites_serviced || 0}
                          </>
                        }
                      >
                        <Progress
                          type="circle"
                          format={() => `${Math.round((event.num_sites_serviced / event.num_sites_dispatched) * 100) || 0}%`}
                          percent={Math.round((event.num_sites_serviced / event.num_sites_dispatched) * 100) || 0}
                          size={40}
                          strokeColor="#F57D20"
                          strokeWidth={18}
                        />
                      </Tooltip>
                    </Box>

                    <Typography style={{ fontSize: '22px', fontWeight: '600', color: "black", marginTop: "8px", marginBottom: "8px" }}>
                      {event.event_name}
                    </Typography>


                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', height: '100%'}}>
                      <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Typography style={{ fontSize: '14px', fontWeight: '400', color: "#737373", lineHeight: '1', marginBottom: '3px' }}>
                          Locations:
                        </Typography>
                        {event.locations.slice(0, 5).map((location, idx) => (
                          <Typography key={location.station_name} style={{ fontSize: '14px', fontWeight: '500', color: "black", paddingLeft: '8px' }}>
                            {location.country === "Canada" ? <>🇨🇦{" "}</> : <>🇺🇸{" "}</>}
                            {location.station_name}
                          </Typography>
                        ))}
                        {event.locations.length - 5 === 1 && (
                          <Typography key={event.locations[6].station_name} style={{ fontSize: '14px', fontWeight: '500', color: "black", paddingLeft: '8px' }}>
                            {event.locations[5].country === "Canada" ? <>🇨🇦{" "}</> : <>🇺🇸{" "}</>}
                            {event.locations[5].station_name}
                          </Typography>
                        )}
                        {event.locations.length - 5 > 1 && (
                          <Typography style={{ fontSize: '14px', fontWeight: '500', color: "black" }}>
                            + {event.locations.length - 5} locations
                          </Typography>
                        )}
                      </Box>

                      <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end', gap: '10px', height: '100%' }}>
                        <Tooltip title="Sync Service Histories">
                          <SyncOutlined onClick={() => handleSyncClick(event)}/>
                        </Tooltip>
                        <Tooltip title="Edit Event">
                          <EditOutlined onClick={() => handleEditClick(event)}/>
                        </Tooltip><Tooltip title="View Event Details">
                          <InfoCircleOutlined onClick={() => handleDetailClick(event)}/>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
              ))
            )}

            {/* Event Details Modal */}
            <Modal
              title="Event Details"
              open={detailOpen}
              onCancel={handleDetailClose}
              footer={null}
              width='60%'
            >
              <RenderData data={selectedEvent} />
            </Modal>

            {/* Edit Event Modal */}
            <EditEventModal
              open={editOpen}
              onClose={handleEditClose}
              event={selectedEvent}
              weatherStations={weatherStations}
              message={messageApi}
            />

            {/* Sync Service Histories Modal */}
            <SyncServiceHistories
              open={syncModalOpen}
              onClose={handleSyncClose}
              eventId={selectedEvent?.event_id}
            />
          </Box>
        </Box>
      </Box>
    </ConfigProvider>
  );
};

export default EventsPage;