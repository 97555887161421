// src/App.js
import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ConstantsProvider } from './context/ConstantsContext';
import { CurrentEventProvider } from './context/EventContext';
import { useUser } from './context/UserContext';
import CssBaseline from '@mui/material/CssBaseline';
import NotifyPage from './pages/NotifyPage';
import DispatchPage from './pages/DispatchPage';
import ClientsPage from './pages/ClientsPage';
import BillingPage from './pages/BillingPage';
import SubcontractorsPage from './pages/SubcontractorsPage';
import RoutesPage from './pages/RoutesPage';
import ServiceHistoryPage from './pages/ServiceHistoryPage';
import SitesPage from './pages/SitesPage';
import SubcontractorsPOPage from './pages/SubcontractorPOsPage';
import ClientInvoicesPage from './pages/ClientInvoicesPage';
import EventHistoryPage from './pages/EventHistoryPage';
import LoginPage from './pages/LoginPage';
import Layout from './Layout';
import ProfilePage from './pages/ProfilePage';
import RegistrationPage from './pages/RegistrationPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import DashboardPage from './pages/DashboardPage';
import EventsPage from './pages/EventsPage';
import WeatherHistoryPage from './pages/WeatherHistoryPage';
import InvoicePdfPage from './pages/InvoicePdfPage';
import PurchaseOrderPdfPage from './pages/PurchaseOrderPdfPage';
import EquipmentPage from './pages/EquipmentPage';

// Import Google Fonts
import '@fontsource/roboto';
import '@fontsource/open-sans';
import '@fontsource/lato';
import '@fontsource/montserrat';
import { Spin } from 'antd';

const App = () => {
  const [mode, setMode] = useState('light');
  const { user, loading } = useUser();

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#f57e1f',
          },
          background: {
            default: mode === 'light' ? '#fafafa' : '#000000',
            paper: mode === 'light' ? '#ffffff' : '#1e1e1e',
          },
          text: {
            primary: mode === 'light' ? '#333333' : '#ffffff',
            secondary: mode === 'light' ? '#4a4a4a' : '#b0b0b0',
          },
        },
        typography: {
          // uncomment the fontFamily you want to use
          //fontFamily: 'Roboto, Arial, sans-serif',
          //fontFamily: 'Open Sans, Arial, sans-serif',
          fontFamily: 'Lato, Arial, sans-serif',
          //fontFamily: 'Montserrat, Arial, sans-serif',
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                color: mode === 'light' ? '#333333' : '#ffffff',
                backgroundColor: '#f57e1f',
                '&:hover': {
                  backgroundColor: '#d35400',
                },
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: '#f57e1f',
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: mode === 'light' ? '#ffffff' : '#1e1e1e',
              },
            },
          },
        },
      }),
    [mode],
  );

  const ProtectedRoute = ({ children }) => {
    if (loading) {
      return <Spin>Loading </Spin>
    }
    if (!user?.authenticated) {
      return <Navigate to="/login" />;
    }
    return (
      <ConstantsProvider>
        <CurrentEventProvider>
          {children}
        </CurrentEventProvider>
      </ConstantsProvider>
    );
  };

  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
            <Layout mode={mode} toggleColorMode={colorMode.toggleColorMode}>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register/:token" element={<RegistrationPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/reset-password/:token" element={<ResetPasswordPage />} />

                <Route path="/" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
                <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
                <Route path="/notify" element={<ProtectedRoute><NotifyPage /></ProtectedRoute>} />
                <Route path="/dispatch" element={<ProtectedRoute><DispatchPage /></ProtectedRoute>} />
                <Route path="/clients" element={<ProtectedRoute><ClientsPage /></ProtectedRoute>} />
                <Route path="/billing" element={<ProtectedRoute><BillingPage /></ProtectedRoute>} />
                <Route path="/subcontractors" element={<ProtectedRoute><SubcontractorsPage /></ProtectedRoute>} />
                <Route path="/routes" element={<ProtectedRoute><RoutesPage /></ProtectedRoute>} />
                <Route path="/servicehistory" element={<ProtectedRoute><ServiceHistoryPage /></ProtectedRoute>} />
                <Route path="/sites" element={<ProtectedRoute><SitesPage /></ProtectedRoute>} />
                <Route path="/purchase-orders" element={<ProtectedRoute><SubcontractorsPOPage /></ProtectedRoute>} />
                <Route path="/invoices" element={<ProtectedRoute><ClientInvoicesPage /></ProtectedRoute>} />
                <Route path="/eventhistory" element={<ProtectedRoute><EventHistoryPage /></ProtectedRoute>} />
                <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
                <Route path="/events" element={<ProtectedRoute><EventsPage /></ProtectedRoute>} />
                <Route path="/weatherhistory" element={<ProtectedRoute><WeatherHistoryPage /></ProtectedRoute>} />
                <Route path="/purchase-orders/:poId/pdf" element={<ProtectedRoute><PurchaseOrderPdfPage /></ProtectedRoute>} />
                <Route path="/invoices/:invoiceId/pdf" element={<ProtectedRoute><InvoicePdfPage /></ProtectedRoute>} />
                <Route path="/equipment" element={<ProtectedRoute><EquipmentPage /></ProtectedRoute>} />
              </Routes>
            </Layout>
        </Router>
    </ThemeProvider>
  );
};

export default App;
