// api.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ? `${process.env.REACT_APP_API_BASE_URL}/api` : 'http://127.0.0.1:5000/api';

axios.defaults.withCredentials = true;

// Axios interceptor for handling 401 responses
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('401 Unauthorized - Redirecting to login');
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
      setAuthToken(null);
      window.location.href = '/login';
      return Promise.resolve({ data: null });
    }
    return Promise.reject(error);
  }
);

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

const token = localStorage.getItem('authToken');
if (token) {
  setAuthToken(token);
}

export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/login`, { email, password });

    const { token, user } = response.data;
    localStorage.setItem('authToken', token);
    setAuthToken(token);
    localStorage.setItem('user', JSON.stringify(user));

    return response;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const fetchUserDetails = async (email) => {
  const response = await axios.get(`${API_BASE_URL}/user-details?email=${email}`);
  return response.data;
};

export const logout = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('user');
  setAuthToken(null);
  return axios.post(`${API_BASE_URL}/logout`);
};

export const sendInvite = async (email, role) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/invite`, { email, role });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else if (error.request) {
      throw new Error('Network error, no response received');
    } else {
      throw new Error('Error in setting up request: ' + error.message);
    }
  }
};

export const registerUser = async ({ token, firstName, lastName, phoneNumber, password }) => {
  try {
    return await axios.post(`${API_BASE_URL}/register`, { token, firstName, lastName, phoneNumber, password });
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};

export const sendPasswordResetEmail = async (email) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/reset-password`, { email }, {
      withCredentials: false
    });
    return response.data;
  } catch (error) {
    console.error('Error sending password reset email:', error);
    throw error;
  }
};

export const getProfile = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/profile`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Network error');
  }
};

export const updateProfile = async (profileData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/profile`, profileData);
    return response.data;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users`);
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const updateUserRole = async (userId, role, userRole) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/users/${userId}/role`, { role, userRole });
    return response.data;
  } catch (error) {
    console.error('Error updating user role:', error);
    throw error;
  }
};

export const deleteUser = async (userId, currentUserRole) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/users/${userId}`, {
      data: { current_user_role: currentUserRole },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

export const fetchWeatherData = async (eventId = null) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/weather`, {
      params: eventId ? { event_id: eventId } : {},
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching weather data:', error);
    throw error;
  }
};

export const fetchClients = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/clients`);
    return response.data.map(client => ({
      ...client,
      // Having a `key` element in the object is required for rendering the clients uniquely in Ant Design tables.
      key: client.client_id,
    }));
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw error;
  }
};

export const fetchRemovedClients = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/clients/removed`);
    return response.data.map(client => ({
      ...client,
      key: client.client_id,
    }));
  } catch (error) {
    console.error('Error fetching removed clients:', error);
    throw error;
  }
};

export const fetchEquipment = async() => {
  try {
    const response = await axios.get(`${API_BASE_URL}/equipment`);
    return response.data.map(equip => ({
      ...equip,
      key: equip.id,
      label: equip.name,
      value: equip.id,
    }));
  } catch (error) {
    console.error('Error fetching equipment:', error);
    throw error;
  }
}

export const updateEquipment = async (equipmentId, rates) => {
  try {
    const res = await axios.patch(`${API_BASE_URL}/equipment/${equipmentId}`, rates);
    return res.data;
  } catch (error) {
    console.error('Error updating equipment:', error);
    throw error;
  }
};

export const updateSubcontract = async (routeIds, subcontractorId, subcontract) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/subcontractors/${subcontractorId}/subcontract`, { subcontract_type: subcontract, route_ids: routeIds });
    return response.data;
  } catch (error) {
    console.error('Error updating subcontract type:', error);
    throw error;
  }
};

export const fetchSites = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/sites`);
    return response.data.map(site => ({
      ...site,
      // Having `value` and `label` elements in the object is required for rendering the sites in searchable select box from Ant
      value: site.site_id,
      label: site.site_name,
    }));
  } catch (error) {
    console.error('Error fetching sites:', error.message);
    throw error;
  }
};

export const fetchRemovedSites = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/sites/removed`);
    console.log(response)
    return response.data.map(site => ({
      ...site,
      value: site.site_id,
      label: site.site_name,
    }));
  } catch (error) {
    console.error('Error fetching sites:', error.message);
    throw error;
  }
};

export const fetchSitesByRouteId = async (routeId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/sites?route_id=${routeId}`);
    return response.data.map(site => ({
      ...site,
      // Having a `key` element in the object is required for rendering the routes uniquely in Ant Design tables.
      key: site.id,
    }));
  } catch (error) {
    console.error('Error fetching sites by route ID:', error.message);
    throw error;
  }
};

export const fetchEventRouteSites = async (eventRouteId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/event-routes/${eventRouteId}/sites`);
    return response.data;
  } catch (error) {
    console.error('Error fetching sites by route ID:', error.message);
    throw error;
  }
};

export const fetchSaltRouteSites = async (saltDispatchRouteId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/salt-dispatch-routes/${saltDispatchRouteId}/salt-sites`);
    return response.data;
  } catch (error) {
    console.error('Error fetching salt sites by salt dispatch route ID:', error.message);
    throw error;
  }
};

export const fetchSnowRouteSites = async (snowDispatchRouteId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/snow-dispatch-routes/${snowDispatchRouteId}/snow-sites`);
    return response.data;
  } catch (error) {
    console.error('Error fetching snow sites by snow dispatch route ID:', error.message);
    throw error;
  }
};

export const fetchDispatchableSaltRouteSites = async (saltDispatchRouteId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/salt-dispatch-routes/${saltDispatchRouteId}/dispatchable-salt-sites`);
    return response.data;
  } catch (error) {
    console.error('Error fetching salt sites by salt dispatch route ID:', error.message);
    throw error;
  }
};

export const fetchDispatchableSnowRouteSites = async (snowDispatchRouteId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/snow-dispatch-routes/${snowDispatchRouteId}/dispatchable-snow-sites`);
    return response.data;
  } catch (error) {
    console.error('Error fetching snow sites by snow dispatch route ID:', error.message);
    throw error;
  }
};

export const fetchSiteById = async (siteId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/sites/${siteId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching site with ID ${siteId}:`, error.message);
    throw error;
  }
};

export const fetchRoutes = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/routes`);
    return response.data.map(route => ({
      ...route,
      // Having a `key` element in the object is required for rendering the routes uniquely in Ant Design tables.
      key: route.route_id,
      dispatched_subcontractor_id: route.dispatched_subcontractor_id || null
    }));
  } catch (error) {
    console.error('Error fetching routes:', error);
    throw error;
  }
};

export const fetchRemovedRoutes = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/routes/removed`);
    return response.data.map(route => ({
      ...route,
      key: route.route_id,
      dispatched_subcontractor_id: route.dispatched_subcontractor_id || null
    }));
  } catch (error) {
    console.error('Error fetching routes:', error);
    throw error;
  }
};

export const fetchSubcontractors = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/subcontractors`);
    return response.data.map(subcontractor => ({
      ...subcontractor,
      value: subcontractor.subcontractor_id,
      label: subcontractor.name,
    }));
  } catch (error) {
    console.error('Error fetching subcontractors:', error);
    throw error;
  }
};

export const fetchRemovedSubcontractors = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/subcontractors`);
    console.log(response);
    return response.data.map(subcontractor => ({
      ...subcontractor,
      value: subcontractor.subcontractor_id,
      label: subcontractor.name,
    }));
  } catch (error) {
    console.error('Error fetching subcontractors:', error);
    throw error;
  }
};

export const fetchRouteSubcontractors = async (routeId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/routes/${routeId}/subcontractors`);
    return response.data;
  } catch (error) {
    console.error('Error fetching route subcontractors:', error);
    console.log(error);
    throw error;
  }
};

export const notifyClients = async (data) => {
  try {
    console.log('Sending request to notify...')
    const response = await axios.put(`${API_BASE_URL}/routes/notify`, data);
    return response;
  } catch (error) {
    console.error('Error notifying clients:', error);
    throw error;
  }
};

export const submitDispatchForm = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/dispatch`, formData);
    return response.data;
  } catch (error) {
    console.error('Error submitting dispatch form:', error);
    throw error;
  }
};

export const updateClient = async (clientData, clientId) => {
  try {
    console.log('Client data being sent:', clientData);
    const response = await axios.put(`${API_BASE_URL}/clients/${clientId}`, clientData);
    return response.data;
  } catch (error) {
    console.error('Error updating client:', error);
    throw error;
  }
};

export const addSubcontractor = async (subcontractorData) => {
  try{
    const response = await axios.post(`${API_BASE_URL}/subcontractors`, subcontractorData);
    return response.data;
  } catch (error) {
    console.error('Error adding subcontractor:', error);
    throw error;
  }
};

export const updateSubcontractor = async (subcontractorData, subcontractorId) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/subcontractors/${subcontractorId}`, subcontractorData);
    return response.data;
  } catch (error) {
    console.error('Error updating subcontractor:', error);
    throw error;
  }
};

export const deleteSubcontractor = async (subcontractorId) => {
  try{
    const response = await axios.delete(`${API_BASE_URL}/subcontractors/${subcontractorId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting subcontractor:', error);
    throw error;
  }
};

export const updateRouteDetails = async (routeId, data) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/routes/${routeId}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating route details:', error);
    throw error;
  }
};

export const createRoute = async (routeData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/routes`, routeData);
    return response.data;
  } catch (error) {
    console.error('Error creating a new route:', error);
    throw error;
  }
};

export const deleteRoute = async (routeId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/routes/${routeId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting the route:', error);
    throw error;
  }
};

export const fetchServiceHistories = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/service-histories`);
    return response.data;
  } catch (error) {
    console.error('Error fetching service history:', error);
    throw error;
  }
};

export const syncServiceHistories = async (startTime, endTime) => {
  try {
    console.log('Syncing Service History Reports since ', startTime);
    const response = await axios.post(`${API_BASE_URL}/service-histories/sync`,
      { start_time: startTime, end_time: endTime }
    );
    return response.data;
  } catch (error) {
    console.error('Error syncing service histories:', error);
    throw error;
  }
};

export const importAllDataFromApi = async (onStepChange) => {
  const summary = {
    clients: { added: [], updated: [], skipped: [] },
    sites: { added: [], updated: [], skipped: [] },
    subcontractors: { added: [], updated: [], skipped: [] },
    routes: { added: [], updated: [], skipped: [] }
  };

  try {
    onStepChange('Weather Stations');
    await axios.post(`${API_BASE_URL}/import-weather-stations`);
    console.log('Weather stations imported successfully');

    onStepChange('Clients');
    const clientsResponse = await axios.post(`${API_BASE_URL}/yeti/sync/clients`);
    summary.clients = clientsResponse.data;
    console.log('Clients synced successfully');

    onStepChange('Sites');
    const sitesResponse = await axios.post(`${API_BASE_URL}/yeti/sync/sites`);
    summary.sites = sitesResponse.data;
    console.log('Sites imported successfully');

    onStepChange('Subcontractors');
    const subcontractorsResponse = await axios.post(`${API_BASE_URL}/yeti/sync/subcontractors`);
    summary.subcontractors = subcontractorsResponse.data;
    console.log('Subcontractors synced successfully');

    onStepChange('Routes');
    const routesResponse = await axios.post(`${API_BASE_URL}/yeti/sync/routes`);
    summary.routes = routesResponse.data;
    console.log('Routes imported successfully');

    await axios.post(`${API_BASE_URL}/yeti/sync/send-summary-email`, { summary });

    return summary;
  } catch (error) {
    console.error('Error importing data:', error);
    throw error;
  }
};

export const updateSiteContacts = async (siteId, contactsData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/sites/${siteId}/contacts`, contactsData);
    return response.data;
  } catch (error) {
    console.error('Error updating site contacts:', error);
    throw error;
  }
};

export const updateSiteContract = async (siteId, contractData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/sites/${siteId}/contract`, contractData);
    return response.data;
  } catch (error) {
    console.error('Error updating site contract:', error);
    throw error;
  }
};

export const getSiteEquipmentRates = async (siteId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/sites/${siteId}/equipment-rates`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching equipment rates for site ${siteId}:`, error.message);
    throw error;
  }
};

export const deleteSiteEquipmentRate = async (siteId, siteEquipmentRateId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/sites/${siteId}/equipment-rates/${siteEquipmentRateId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting equipment rate ${siteEquipmentRateId} for site ${siteId}:`, error.message);
    throw error;
  }
};

export const addSiteEquipmentRate = async (siteId, equipmentId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/sites/${siteId}/equipment-rates`, { equipment_id: equipmentId });
    return response.data;
  } catch (error) {
    console.error(`Error adding equipment rate for site ${siteId}:`, error.message);
    throw error;
  }
};

export const updateSiteEquipmentRate = async (siteId, siteEquipmentRateId, updatedRateData) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/sites/${siteId}/equipment-rates/${siteEquipmentRateId}`,
      updatedRateData
    );
    return response.data;
  } catch (error) {
    console.error(`Error updating equipment rate ${siteEquipmentRateId} for site ${siteId}:`, error.message);
    throw error;
  }
};

export const fetchEventHistory = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/event-history`);
    return response.data;
  } catch (error) {
    console.error('Error fetching event history:', error);
    throw error;
  }
};

export const fetchInvoices = async (event_id = '', status = '') => {
  try {
    const params = {};
    if (event_id) params.event_id = event_id;
    if (status) params.status = status;

    const response = await axios.get(`${API_BASE_URL}/invoices`, { params });

    return response.data.map(invoice => ({
      ...invoice,
      // Having a `key` element in the object is required for rendering the routes uniquely in Ant Design tables.
      key: invoice.invoice_id,
    }));
  } catch (error) {
    console.error('Error fetching invoices:', error);
    throw error;
  }
};

export const fetchInvoice = async (invoiceId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/invoices/${invoiceId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching invoice data:', error);
    throw error;
  }
};

export const deleteInvoices = async (invoiceIds) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/invoices`, {
      data: { invoice_ids: invoiceIds },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting invoices:', error);
    throw error;
  }
};

export const updateInvoiceStatus = async (invoiceId, newStatus) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/invoices/${invoiceId}/status`, { newStatus });
    return response.data;
  } catch (error) {
    console.error('Error updating invoice status:', error);
    throw error;
  }
};

export const approveInvoice = async (invoiceId, email) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/invoices/${invoiceId}/approve`, { email });
    return response.data;
  } catch (error) {
    console.error('Error approving invoice:', error);
    throw error;
  }
};

export const generateInvoicePdf = async (invoiceId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/invoices/${invoiceId}/generate-pdf`);
    return response.data;
  } catch (error) {
    console.error('Error generating invoice pdf:', error);
    throw error;
  }
};

export const synchronizeInvoice = async (invoiceId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/invoices/${invoiceId}/sync`);
    return response.data.details || [];
  } catch (error) {
    const errorMessages = error.response?.data?.details || ['Failed to synchronize invoice'];
    console.error('Error synchronizing invoice:', errorMessages);
    return errorMessages;
  }
};

export const fetchInvoicePDF = async (invoiceId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/invoices/${invoiceId}/pdf`, {
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching invoice PDF:', error);
    throw error;
  }
};

export const fetchInvoicePdfUrl = async (invoiceId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/invoices/${invoiceId}/pdf-url`);
    return response.data;
  } catch (error) {
    console.error('Error fetching invoice PDF URL:', error);
    throw error;
  }
};

export const fetchPurchaseOrders = async (event_id = '', status = '') => {
  try {
    const params = {};
    if (event_id) params.event_id = event_id;
    if (status) params.status = status;

    const response = await axios.get(`${API_BASE_URL}/purchase-orders`, { params });

    return response.data.map(po => ({
      ...po,
      // Having a `key` element in the object is required for rendering the routes uniquely in Ant Design tables.
      key: po.po_id,
    }));
  } catch (error) {
    console.error('Error fetching subcontractor POs:', error);
    throw error;
  }
};

export const deleteSubcontractorPOs = async (poIds) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/purchase-orders`, {
      data: { po_ids: poIds },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting POs:', error);
    throw error;
  }
};

export const fetchPurchaseOrder = async (poId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/purchase-orders/${poId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching PO data:', error);
    throw error;
  }
};

export const updatePurchaseOrderStatus = async (poId, newStatus) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/purchase-orders/${poId}/status`, { newStatus });
    return response.data;
  } catch (error) {
    console.error('Error updating PO status:', error);
    throw error;
  }
};

export const updatePurchaseOrderAmount = async (poId, amount) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/purchase-orders/${poId}/amount`, { amount });
    return response.data;
  } catch (error) {
    console.error('Error updating PO amount:', error);
    throw error;
  }
};

export const approvePO = async (poId, approveAsDraft) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/purchase-orders/${poId}/approve`, { as_draft: approveAsDraft });
    return response.data;
  } catch (error) {
    console.error('Error approving PO:', error);
    throw error;
  }
};

export const generatePurchaseOrderPdf = async (poId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/purchase-orders/${poId}/generate-pdf`);
    return response.data;
  } catch (error) {
    console.error('Error generating purchase order pdf:', error);
    throw error;
  }
};

export const synchronizePurchaseOrder = async (poId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/purchase-orders/${poId}/sync`);
    return response.data.details || [];
  } catch (error) {
    const errorMessages = error.response?.data?.details || ['Failed to synchronize purchase order'];
    console.error('Error synchronizing purchase order:', errorMessages);
    return errorMessages;
  }
};

export const fetchPurchaseOrderPDF = async (poId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/purchase-orders/${poId}/pdf`, {
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching purchase order PDF:', error);
    throw error;
  }
};

export const fetchPurchaseOrderPdfUrl = async (poId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/purchase-orders/${poId}/pdf-url`);
    return response.data;
  } catch (error) {
    console.error('Error fetching purchase order PDF URL:', error);
    throw error;
  }
};

export const updateSubcontractorRoutes = async (subcontractorId, routesData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/subcontractors/${subcontractorId}/routes`, routesData );
    return response.data;
  } catch (error) {
    console.error('Error updating subcontractor routes:', error);
    throw error;
  }
};

export const addRouteSubcontractor = async (routeId, subcontractorId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/route-subcontractor/${routeId}/${subcontractorId}`);
    return response.data;
  } catch (error) {
    console.error('Error creating route subcontractor assignment:', error);
    throw error;
  }
};

export const removeRouteSubcontractor = async (routeId, subcontractorId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/route-subcontractor/${routeId}/${subcontractorId}`);
    return response.data;
  } catch (error) {
    console.error('Error removing route subcontractor assignment:', error);
    throw error;
  }
};

export const fetchSubcontractorRoutes = async (subcontractorId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/subcontractors/${subcontractorId}/routes`);
    return response.data;
  } catch (error) {
    console.error('Error fetching subcontractor routes:', error);
    throw error;
  }
};

export const refreshAllWeatherStations = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/weather-stations/refresh`);
    return response.data;
  } catch (error) {
    console.error('Error refreshing weather station data:', error);
    throw error;
  }
};

export const refreshlWeatherStation = async (weatherStationId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/weather-stations/${weatherStationId}/refresh`);
    return response.data;
  } catch (error) {
    console.error('Error refreshing weather station data:', error);
    throw error;
  }
};

export const fetchWeatherStations = async ({ country = null, snowForcasted = null } = {}) => {
  try {
      const params = new URLSearchParams();
      if (country) params.append('country', country);
      if (snowForcasted) params.append('snow_forecasted', snowForcasted);

      const response = await axios.get(`${API_BASE_URL}/weather-stations`, {
        params,
      });

      return response.data;
  } catch (error) {
      console.error('Error fetching weather stations:', error);
      throw error;
  }
};

export const fetchAssociatedRouteSiteStations = async (stationIds) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/weather-stations/route-site-stations`, { weather_station_ids: stationIds } );
    return response.data;
  } catch (error) {
    console.error('Error fetching associated route site weather stations:', error);
    throw error;
  }
};

export const generateEvent = async (eventName, eventType, weatherStations, additionalStations, forecastTime, serviceType = null) => {
  const weatherStationsPayload = []
  weatherStations.forEach(station => {
    weatherStationsPayload.push({
      weather_station_id: station.weather_station_id,
      pdf_source: station.pdf_source,
    })
  })

  const additionalStationsPayload = []
  additionalStations.forEach(station => {
    additionalStationsPayload.push({
      weather_station_id: station.weather_station_id,
      pdf_source: station.pdf_source,
    })
  })

  try {
    const response = await axios.post(`${API_BASE_URL}/events`,
      {
        event_name: eventName,
        event_type: eventType,
        weather_stations: weatherStationsPayload,
        additional_stations: additionalStationsPayload,
        forecast_time: forecastTime,
        service_type: serviceType
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error generating event:', error);
    throw error;
  }
};

export const fetchEvents = async ({ active = 'false', order = 'desc' } = {}) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/events`, {
      params: { active, order },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

export const fetchEvent = async (eventId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/events/${eventId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching event ${eventId}:`, error);
    throw error;
  }
};

export const updateForecastAccumulation = async (forecastId, accum) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/forecasts/${forecastId}/accumulation`, { accum });
    return response.data;
  } catch (error) {
    console.error('Error updating forecast accumulation:', error);
    throw error;
  }
};

export const fetchEventForecasts = async (eventId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/events/${eventId}/forecasts`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching event forecasts for event ${eventId}:`, error);
    throw error;
  }
};

export const fetchEventLocations = async (eventId, weatherStationIds=[]) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/events/${eventId}/locations`, { weather_station_ids: weatherStationIds });
    return response.data;
  } catch (error) {
    console.error('Error fetching addtional event locations:', error);
    throw error;
  }
};

export const updateEventLocations = async (eventId, weatherStations, forecasts) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/events/${eventId}/locations`, { weather_stations: weatherStations, forecasts: forecasts });
    return response.data;
  } catch (error) {
    console.error('Error updating event locations:', error);
    throw error;
  }
};

export const fetchForecastPDF = async (forecastId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/forecasts/${forecastId}/pdf`, {
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching forecast PDF:', error);
    throw error;
  }
};

export const syncForecastPDF = async (forecastId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/forecasts/${forecastId}/sync-pdf`);
    return response.data;
  } catch (error) {
    console.error('Error syncing forecast PDF with Google Drive:', error);
    throw error;
  }
};

// export const fetchForecastPdfUrl = async (forecastId) => {
//   try {
//     const response = await axios.get(`${API_BASE_URL}/forecasts/${forecastId}/pdf-url`);
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching forecast PDF:', error);
//     throw error;
//   }
// };

export const fetchLastActiveEvent = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/last-active-event`);
    return response.data;
  } catch (error) {
    console.error('Error fetching the last event:', error);
    throw error;
  }
};

export const fetchSaltDispatchRoutes = async (eventId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/events/${eventId}/salt-dispatch-routes`);
    return response.data;
  } catch (error) {
    console.error('Error fetching salt dispatch routes:', error);
    throw error;
  }
};

export const fetchSnowDispatchRoutes = async (eventId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/events/${eventId}/snow-dispatch-routes`);
    return response.data;
  } catch (error) {
    console.error('Error fetching snow dispatch routes:', error);
    throw error;
  }
};

export const fetchEventHistoryCSV = async (eventId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/events/${eventId}/event-history-csv`, {
      responseType: 'blob'
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching event history CSV:', error);
    throw error;
  }
};

export const fetchNotifiableEventRoutes = async (eventId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/events/${eventId}/notifiable-routes`);
    return response.data.map(route => ({
      ...route,
      // Having a `key` element in the object is required for rendering the routes uniquely in Ant Design tables.
      key: route.id,
    }));
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

export const fetchDispatchableSaltRoutes = async (eventId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/events/${eventId}/dispatchable-salt-routes`);
    return response.data.map(route => ({
      ...route,
      // Having a `key` element in the object is required for rendering the routes uniquely in Ant Design tables.
      key: route.id,
    }));
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

export const fetchDispatchableSnowRoutes = async (eventId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/events/${eventId}/dispatchable-snow-routes`);
    return response.data.map(route => ({
      ...route,
      // Having a `key` element in the object is required for rendering the routes uniquely in Ant Design tables.
      key: route.id,
    }));
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

// export const mergeNotificationEvents = async (eventIds, targetEventId) => {
//   try {
//     const response = await axios.post(`${API_BASE_URL}/notification-events/merge`, {
//       event_ids: eventIds,
//       target_event_id: targetEventId
//     });
//     return response.data;
//   } catch (error) {
//     console.error('Error merging notification events:', error);
//     throw error;
//   }
// };

export const importDemoData = async (onStepChange) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/import-demo-data`);
    console.log('Demo data imported successfully');
    return response.data;
  } catch (error) {
    console.error('Error importing demo data:', error);
    throw error;
  }
};

export const verifyResetPasswordToken = async (token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/reset-password/${token}`);
    return response.data;
  } catch (error) {
    console.error('Error verifying reset password token:', error);
    throw error;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/reset-password/${token}`, { new_password: newPassword });
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};

export const addEventRouteSites = async (eventId, eventRouteId, siteIds) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/events/${eventId}/routes/${eventRouteId}/sites`, siteIds);
    return response;
  } catch (error) {
    console.error('Error adding event routes sites:', error);
    throw error;
  }
};

export const removeEventRouteSite = async (eventId, eventRouteId, eventRouteSiteId) => {
  try {
    console.log('Sending request to notify...')
    const response = await axios.delete(`${API_BASE_URL}/events/${eventId}/routes/${eventRouteId}/sites/${eventRouteSiteId}`);
    return response;
  } catch (error) {
    console.error('Error notifying clients:', error);
    throw error;
  }
};

export const addSaltRouteSites = async (saltDispatchRouteId, siteIds) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/salt-dispatch-routes/${saltDispatchRouteId}/salt-sites`, siteIds);
    return response;
  } catch (error) {
    console.error('Error adding salt route sites:', error);
    throw error;
  }
};

export const removeSnowRouteSite = async (snowDispatchSiteId, sendCancellationNotice, userEmail) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/snow-dispatch-sites/${snowDispatchSiteId}/delete`, {
      send_cancellation_notice: sendCancellationNotice,
      user_email: userEmail,
    });
    return response;
  } catch (error) {
    console.error('Error removing snow route site:', error);
    throw error;
  }
};

export const updateSaltRouteSubcontractor = async (saltDispatchRouteId, subcontractorId) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/salt-dispatch-routes/${saltDispatchRouteId}/salt-subcontractor`, {
      subcontractor_id: subcontractorId
    });
    return response;
  } catch (error) {
    console.error('Error updating salt route subcontractor:', error);
    throw error;
  }
};

export const updateSaltRouteSubcontractorAcknowledgement = async (saltDispatchRouteId, acknowledgement) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/salt-dispatch-routes/${saltDispatchRouteId}/salt-subcontractor/acknowledgement`, {
      acknowledgement: acknowledgement
    });
    return response;
  } catch (error) {
    console.error('Error updating salt route subcontractor acknowledgement:', error);
    throw error;
  }
};

export const addSnowRouteSites = async (snowDispatchRouteId, siteIds) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/snow-dispatch-routes/${snowDispatchRouteId}/snow-sites`, siteIds);
    return response;
  } catch (error) {
    console.error('Error adding snow route sites:', error);
    throw error;
  }
};

export const removeSaltRouteSite = async (saltDispatchSiteId, sendCancellationNotice, userEmail) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/salt-dispatch-sites/${saltDispatchSiteId}/delete`, {
      send_cancellation_notice: sendCancellationNotice,
      user_email: userEmail,
    });
    return response;
  } catch (error) {
    console.error('Error removing salt route site:', error);
    throw error;
  }
};

export const updateSnowSiteSubcontractor = async (snowDispatchRouteId, snowSiteId, subcontractorId) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/snow-dispatch-routes/${snowDispatchRouteId}/snow-sites/${snowSiteId}/snow-subcontractor`, {
      subcontractor_id: subcontractorId
    });
    return response;
  } catch (error) {
    console.error('Error updating snow site subcontractor:', error);
    throw error;
  }
};

export const updateSnowSiteSubcontractorAcknowledgement = async (snowDispatchRouteId, snowSiteId, acknowledgement) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/snow-dispatch-routes/${snowDispatchRouteId}/snow-sites/${snowSiteId}/snow-subcontractor/acknowledgement`, {
      acknowledgement: acknowledgement
    });
    return response;
  } catch (error) {
    console.error('Error updating salt route subcontractor acknowledgement:', error);
    throw error;
  }
};

export const createWildcardRoute = async (eventId, body) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/events/${eventId}/wildcard`, body);
    return response.data;
  } catch (error) {
    console.error('Error creating wildcard route:', error);
    throw error;
  }
}

export const createEventRouteNotifications = async (eventId, body) => {
  try {
    console.log(body)
    const response = await axios.post(`${API_BASE_URL}/events/${eventId}/routes/client-notifications`, body);
    return response.data;
  } catch (error) {
    console.error('Error notifying clients:', error);
    throw error;
  }
};

export const dispatchEventRoutes = async (eventId, body) => {
  try {
    console.log(body);
    const response = await axios.post(`${API_BASE_URL}/events/${eventId}/routes/dispatch`, body);
    return response;
  } catch (error) {
    console.error('Error dispatching routes:', error);
    throw error;
  }
};

export const reDispatchSaltRoute = async (saltDispatchRouteId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/salt-dispatch-routes/${saltDispatchRouteId}/redispatch`);
    return response;
  } catch (error) {
    console.error('Error redispatching salt route:', error);
    throw error;
  }
};

export const reDispatchSnowRoute = async (snowDispatchRouteId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/snow-dispatch-routes/${snowDispatchRouteId}/redispatch`);
    return response;
  } catch (error) {
    console.error('Error redispatching snow route:', error);
    throw error;
  }
};

export const evaluateArchiveRoute = async (payload) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/dispatch-routes/evaluate-archival`, payload);
    return response.data;
  } catch (error) {
    console.error('Error evaluating dispatch route archival:', error);
    throw error.response?.data || error;
  }
};

export const archiveRoute = async (payload) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/dispatch-routes/archive`, payload);
    return response.data;
  } catch (error) {
    console.error('Error archiving dispatch route:', error);
    throw error.response?.data || error;
  }
};

export const cancelSaltDispatchSite = async (saltDispatchSiteId, sendCancellationNotice, userEmail) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/salt-dispatch-sites/${saltDispatchSiteId}/cancel`, {
        send_cancellation_notice: sendCancellationNotice,
        user_email: userEmail
    });
    return response;
  } catch (error) {
    console.error('Error cancelling salt dispatch site:', error);
    throw error;
  }
};

export const cancelSnowDispatchSite = async (snowDispatchSiteId, sendCancellationNotice, userEmail) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/snow-dispatch-sites/${snowDispatchSiteId}/cancel`, {
        send_cancellation_notice: sendCancellationNotice,
        user_email: userEmail
    });
    return response;
  } catch (error) {
    console.error('Error cancelling snow dispatch site:', error);
    throw error;
  }
};

export const checkIfLastSiteOnSaltRoute = async (saltDispatchSiteId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/salt-dispatch-sites/${saltDispatchSiteId}/is-last-site`);
    return response.data;
  } catch (error) {
    console.error("Error determining whether site is the last one on the salt route:", error);
    throw error;
  }
};

export const checkIfLastSiteOnSubcontractorSnowRoute = async (snowDispatchSiteId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/snow-dispatch-sites/${snowDispatchSiteId}/is-last-site`);
    return response.data;
  } catch (error) {
    console.error("Error determining whether site is the last one on the subcontractor's Yeti route:", error);
    throw error;
  }
};

export const fetchConstants = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/constants`);
    return response.data;
  } catch (error) {
    console.error('Error fetching constants:', error);
    throw error;
  }
};
