import React, { useState } from 'react';
import { Modal, Button, Typography, Spin, Space } from 'antd';
import { importAllDataFromApi } from '../api';

const YetiSync = ({ open, onClose, isSyncing }) => {
  const [isImporting, setIsImporting] = useState(false);
  const [currentImportStep, setCurrentImportStep] = useState('');
  const [importSummary, setImportSummary] = useState(null);

  const handleImport = async () => {
    setIsImporting(true);
    setImportSummary(null);

    try {
      isSyncing(true);
      const summary = await importAllDataFromApi((step) => setCurrentImportStep(step));
      console.log('Import Summary:', summary);
      setImportSummary(summary);
    } catch (error) {
      console.error('Error importing data:', error);
    } finally {
      isSyncing(false);
    }

    setIsImporting(false);
    setCurrentImportStep('');
  };

  const handleClose = () => {
    setImportSummary(null);
    onClose();
  };

  const renderClientsSummary = () => (
    <>
      <Typography.Title level={5}>Clients</Typography.Title>
      <Space direction="vertical" size="small">
        <Typography.Text strong>New records added: {importSummary.clients.added.length}</Typography.Text>
        <Space direction="vertical" size={4}>
          {importSummary.clients.added.map((client, index) => (
            <Typography.Text key={index}>{client.client_name}</Typography.Text>
          ))}
        </Space>
        <br />
        <Typography.Text strong>Records updated: {importSummary.clients.updated.length}</Typography.Text>
        <Space direction="vertical" size={4}>
          {importSummary.clients.updated.map((client, index) => (
            <div key={index}>
              <Typography.Text>{client.client_name}</Typography.Text>
              <Typography.Text style={{ marginLeft: '20px', display: 'block' }}>Updated Fields:</Typography.Text>
              <Space direction="vertical" size={2} style={{ marginLeft: '40px' }}>
                {Object.entries(client.updated_fields).map(([field, [oldValue, newValue]]) => (
                  <Typography.Text key={field}>
                    {field}: "{oldValue}" → "{newValue}"
                  </Typography.Text>
                ))}
              </Space>
            </div>
          ))}
        </Space>
        <br />
        <Typography.Text strong>Records skipped: {importSummary.clients.skipped.length}</Typography.Text>
        <Space direction="vertical" size={4}>
          {importSummary.clients.skipped.map((client, index) => (
            <Typography.Text key={index}>
              {client.client_id} - Reason: {client.reason}
            </Typography.Text>
          ))}
        </Space>
        <br />
        <Typography.Text strong>Records removed: {importSummary.clients.removed.length}</Typography.Text>
        <Space direction="vertical" size={4}>
          {importSummary.clients.removed.map((client, index) => (
            <Typography.Text key={index}>{client.client_name}</Typography.Text>
          ))}
        </Space>
      </Space>
      <br /><br />
    </>
  );

  const renderSitesSummary = () => (
    <>
      <Typography.Title level={5}>Sites</Typography.Title>
      <Space direction="vertical" size="small">
        <Typography.Text strong>New records added: {importSummary.sites.added.length}</Typography.Text>
        <Space direction="vertical" size={4}>
          {importSummary.sites.added.map((site, index) => (
            <div key={index}>
              <Typography.Text>{site.site_name} (Client: {site.client_name})</Typography.Text>
              {site.notification_email && (
                <Typography.Text style={{ marginLeft: '20px', display: 'block' }}>
                  Notification Email: {site.notification_email}
                </Typography.Text>
              )}
              {site.cc_emails && site.cc_emails.length > 0 && (
                <Typography.Text style={{ marginLeft: '20px', display: 'block' }}>
                  CC Emails: {site.cc_emails.join(', ')}
                </Typography.Text>
              )}
            </div>
          ))}
        </Space>
        <br />
        <Typography.Text strong>Records updated: {importSummary.sites.updated.length}</Typography.Text>
        <Space direction="vertical" size={4}>
          {importSummary.sites.updated.map((site, index) => (
            <div key={index}>
              <Typography.Text>{site.site_name} (Client: {site.client_name})</Typography.Text>
              {site.notification_email && (
                <Typography.Text style={{ marginLeft: '20px', display: 'block' }}>
                  Notification Email: {site.notification_email}
                </Typography.Text>
              )}
              {site.cc_emails && site.cc_emails.length > 0 && (
                <Typography.Text style={{ marginLeft: '20px', display: 'block' }}>
                  CC Emails: {site.cc_emails.join(', ')}
                </Typography.Text>
              )}
              <Typography.Text style={{ marginLeft: '20px', display: 'block' }}>Updated Fields:</Typography.Text>
              <Space direction="vertical" size={2} style={{ marginLeft: '40px' }}>
                {Object.entries(site.updated_fields).map(([field, [oldValue, newValue]]) => (
                  <Typography.Text key={field}>
                    {field}: "{oldValue}" → "{newValue}"
                  </Typography.Text>
                ))}
              </Space>
            </div>
          ))}
        </Space>
        <br />
        <Typography.Text strong>Records skipped: {importSummary.sites.skipped.length}</Typography.Text>
        <Space direction="vertical" size={4}>
          {importSummary.sites.skipped.map((site, index) => (
            <Typography.Text key={index}>
              {site.site_id} - Reason: {site.reason}
            </Typography.Text>
          ))}
        </Space>
        <br />
        <Typography.Text strong>Records removed: {importSummary.sites.removed.length}</Typography.Text>
        <Space direction="vertical" size={4}>
          {importSummary.sites.removed.map((site, index) => (
            <Typography.Text key={index}>{site.site_name}</Typography.Text>
          ))}
        </Space>
      </Space>
      <br /><br />
    </>
  );

  const renderSubcontractorsSummary = () => (
    <>
      <Typography.Title level={5}>Subcontractors</Typography.Title>
      <Space direction="vertical" size="small">
        <Typography.Text strong>New records added: {importSummary.subcontractors.added.length}</Typography.Text>
        <Space direction="vertical" size={4}>
          {importSummary.subcontractors.added.map((subcontractor, index) => (
            <Typography.Text key={index}>{subcontractor.subcontractor_name}</Typography.Text>
          ))}
        </Space>
        <br />
        <Typography.Text strong>Records updated: {importSummary.subcontractors.updated.length}</Typography.Text>
        <Space direction="vertical" size={4}>
          {importSummary.subcontractors.updated.map((subcontractor, index) => (
            <div key={index}>
              <Typography.Text>{subcontractor.subcontractor_name}</Typography.Text>
              <Typography.Text style={{ marginLeft: '20px', display: 'block' }}>Updated Fields:</Typography.Text>
              <Space direction="vertical" size={2} style={{ marginLeft: '40px' }}>
                {Object.entries(subcontractor.updated_fields).map(([field, [oldValue, newValue]]) => (
                  <Typography.Text key={field}>
                    {field}: "{oldValue}" → "{newValue}"
                  </Typography.Text>
                ))}
              </Space>
            </div>
          ))}
        </Space>
        <br />
        <Typography.Text strong>Records skipped: {importSummary.subcontractors.skipped.length}</Typography.Text>
        <Space direction="vertical" size={4}>
          {importSummary.subcontractors.skipped.map((subcontractor, index) => (
            <Typography.Text key={index}>
              {subcontractor.subcontractor_id} - Reason: {subcontractor.reason}
            </Typography.Text>
          ))}
        </Space>
        <br />
        <Typography.Text strong>Records removed: {importSummary.subcontractors.removed.length}</Typography.Text>
        <Space direction="vertical" size={4}>
          {importSummary.subcontractors.removed.map((subcontractor, index) => (
            <Typography.Text key={index}>{subcontractor.subcontractor_name}</Typography.Text>
          ))}
        </Space>
      </Space>
      <br /><br />
    </>
  );

  const renderRoutesSummary = () => (
    <>
      <Typography.Title level={5}>Routes</Typography.Title>
      <Space direction="vertical" size="small">
        {/* Display new routes */}
        <Typography.Text strong>
          New records added: {importSummary?.routes?.added?.length || 0}
        </Typography.Text>
        <Space direction="vertical" size={8}>
          {importSummary?.routes?.added?.map((route, index) => (
            <div key={index}>
              <Typography.Text>{route.route_name}</Typography.Text>
              {/* Display added sites */}
              {route.added_sites?.length > 0 && (
                <Typography.Text style={{ marginLeft: '20px', display: 'block' }}>
                  Added Sites: {route.added_sites.join(', ')}
                </Typography.Text>
              )}
              {/* Display new subcontractors */}
              {route.added_subcontractors?.length > 0 && (
                <Typography.Text style={{ marginLeft: '20px', display: 'block' }}>
                  Added Subcontractors: {route.added_subcontractors.join(', ')}
                </Typography.Text>
              )}
              {/* Display skipped subcontractors or sites */}
              {route.skipped_sites_or_subcontractors?.length > 0 && (
                <Typography.Text style={{ marginLeft: '20px' }}>
                  Skipped: {route.skipped_sites_or_subcontractors.join(', ')}
                </Typography.Text>
              )}
            </div>
          ))}
        </Space>

        {/* Display updated routes */}
        <br />
        <Typography.Text strong>
          Records updated: {importSummary?.routes?.updated?.length || 0}
        </Typography.Text>
        <Space direction="vertical" size={8}>
          {importSummary?.routes?.updated?.map((route, index) => (
            <div key={index}>
              <Typography.Text>{route.route_name}</Typography.Text>
              {/* Display updated fields */}
              <Typography.Text style={{ marginLeft: '20px', display: 'block' }}>Updated Fields:</Typography.Text>
              <Space direction="vertical" size={2} style={{ marginLeft: '40px' }}>
                {Object.entries(route.updated_fields || {}).map(([field, [oldValue, newValue]]) => (
                  <Typography.Text key={field}>
                    {field}: "{oldValue}" → "{newValue}"
                  </Typography.Text>
                ))}
              </Space>
              {/* Display added sites */}
              {route.added_sites?.length > 0 && (
                <Typography.Text style={{ marginLeft: '20px', display: 'block' }}>
                  Added Sites: {route.added_sites.join(', ')}
                </Typography.Text>
              )}
              {/* Display removed sites */}
              {route.removed_sites?.length > 0 && (
                <Typography.Text style={{ marginLeft: '20px', display: 'block' }}>
                  Removed Sites: {route.removed_sites.join(', ')}
                </Typography.Text>
              )}
              {/* Display new subcontractors */}
              {route.added_subcontractors?.length > 0 && (
                <Typography.Text style={{ marginLeft: '20px', display: 'block' }}>
                  Added Subcontractors: {route.added_subcontractors.join(', ')}
                </Typography.Text>
              )}
              {/* Display skipped subcontractors or sites */}
              {route.skipped_sites_or_subcontractors?.length > 0 && (
                <Typography.Text style={{ marginLeft: '20px' }}>
                  Skipped: {route.skipped_sites_or_subcontractors.join(', ')}
                </Typography.Text>
              )}
            </div>
          ))}
        </Space>

        {/* Display skipped routes */}
        <br />
        <Typography.Text strong>
          Records skipped: {importSummary?.routes?.skipped?.length || 0}
        </Typography.Text>
        <Space direction="vertical" size={8}>
          {importSummary?.routes?.skipped?.map((route, index) => (
            <div key={index}>
              <Typography.Text>{route.route_name}</Typography.Text>
              <Typography.Text style={{ marginLeft: '20px' }}>
                {`(${route.reason})`}
              </Typography.Text>
            </div>
          ))}
        </Space>
        <br />
        <Typography.Text strong>Records removed: {importSummary?.routes?.removed?.length || 0}</Typography.Text>
        <Space direction="vertical" size={8}>
          {importSummary?.routes?.removed?.map((route, index) => (
            <Typography.Text key={index}>{route.route_name}</Typography.Text>
          ))}
        </Space>
      </Space>
      <br /><br />
    </>
  );

  return (
    <Modal
      title={importSummary ? "Yeti Sync Summary" : "Sync Yeti Data"}
      open={open}
      onCancel={handleClose}
      footer={[
        <Button key="close" onClick={onClose} disabled={isImporting}>Close</Button>,
        !importSummary && <Button key="start" type="primary" onClick={handleImport}>Start Sync</Button>
      ]}
      style={{ top: '5vh', height: '80vh' }}
      styles={{ body: { maxHeight: '70vh', overflowY: 'auto' } }}
    >
      {isImporting ? (
        <Space direction="horizontal" size="middle">
          <Typography.Text>{`Importing ${currentImportStep}`}</Typography.Text>
          <Spin />
        </Space>
      ) : importSummary ? (
        <div>
          {renderClientsSummary()}
          {renderSitesSummary()}
          {renderSubcontractorsSummary()}
          {renderRoutesSummary()}
        </div>
      ) : (
        <Typography.Text>Syncing Yeti data will add new records and update existing records.</Typography.Text>
      )}
    </Modal>
  );
};

export default YetiSync;