import React, { useEffect, useState, useCallback } from 'react';
import { Select, Button, Input, Popconfirm, Table } from 'antd';
import { PlusOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, RollbackOutlined, EditOutlined } from '@ant-design/icons';
import {
  fetchEquipment,
  getSiteEquipmentRates,
  addSiteEquipmentRate,
  updateSiteEquipmentRate,
  deleteSiteEquipmentRate
} from '../api';

const EquipmentRatesTab = ({ site, messageApi, constants }) => {
  // Holds *all* equipment from DB
  const [allEquipmentMaster, setAllEquipmentMaster] = useState([]);
  // Holds a filtered list of equipment that is *not* assigned to this site
  const [allEquipment, setAllEquipment] = useState([]);
  const [selectedEquipmentId, setSelectedEquipmentId] = useState(null);

  // Existing equipment rates on this site
  const [siteEquipRates, setSiteEquipRates] = useState([]);
  
  // { [siteEquipmentRateId]: { client_rate: X, subcontractor_rate: Y } }
  const [editingRates, setEditingRates] = useState({});

  // -------------------------------------------
  //  Helpers
  // -------------------------------------------
  const filterUnassignedEquipment = (equipmentList, assignedRates) => {
    return equipmentList
      .filter(
        (eq) => !assignedRates.some((r) => r.equipment_id === eq.value)
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  };
  
  // -------------------------------------------
  //  Fetch equipment & site equipment rates
  // -------------------------------------------
  const refreshSiteEquipmentRates = useCallback(async () => {
    try {
      const { equipment_rates } = await getSiteEquipmentRates(site.site_id);
      const sortedRates = (equipment_rates ?? []).sort((a, b) =>
        a.equipment_name.localeCompare(b.equipment_name)
      );
      setSiteEquipRates(sortedRates);

      setAllEquipment((prevAll) =>
        filterUnassignedEquipment(allEquipmentMaster, equipment_rates ?? [])
      );
    } catch (error) {
      console.error('Error refreshing site equipment rates:', error);
      messageApi.error('Failed to load site equipment rates.');
    }
  }, [site.site_id, messageApi, allEquipmentMaster]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eqData = await fetchEquipment(); 
        setAllEquipmentMaster(eqData);
        
        const { equipment_rates } = await getSiteEquipmentRates(site.site_id);
        const sortedRates = (equipment_rates ?? []).sort((a, b) =>
          a.equipment_name.localeCompare(b.equipment_name)
        );
        setSiteEquipRates(sortedRates);

        const unassigned = filterUnassignedEquipment(eqData, equipment_rates ?? []);
        setAllEquipment(unassigned);
      } catch (error) {
        console.error('Error fetching equipment or site equipment rates:', error);
        messageApi.error('Failed to load equipment data.');
      }
    };

    fetchData();
  }, [site.site_id, messageApi]);
  
  // -------------------------------------------
  //  Add new site equipment rate
  // -------------------------------------------
  const handleAddEquipmentRate = async () => {
    if (!selectedEquipmentId) return;
    try {
      await addSiteEquipmentRate(site.site_id, selectedEquipmentId);
      messageApi.success('Equipment rate added successfully.');

      setAllEquipment((prev) =>
        prev.filter((eq) => eq.value !== selectedEquipmentId)
      );
      setSelectedEquipmentId(null);

      await refreshSiteEquipmentRates();
    } catch (error) {
      console.error('Error adding site equipment rate:', error);
      messageApi.error('Failed to add site equipment rate.');
    }
  };
  
  // -------------------------------------------
  //  Delete existing site equipment rate
  // -------------------------------------------
  const handleDeleteRate = async (rateId, equipmentId) => {
    try {
      await deleteSiteEquipmentRate(site.site_id, rateId);
      messageApi.success('Deleted site equipment rate.');

      const eqObj = allEquipmentMaster.find((eq) => eq.value === equipmentId);
      if (eqObj) {
        setAllEquipment((prev) => [...prev, eqObj]);
      }

      await refreshSiteEquipmentRates();
    } catch (error) {
      console.error('Error deleting site equipment rate:', error);
      messageApi.error('Failed to delete site equipment rate.');
    }
  };
  
  // -------------------------------------------
  //  Inline editing logic
  // -------------------------------------------
  const startEditing = (rateItem) => {
    setEditingRates((prev) => ({
      ...prev,
      [rateItem.id]: {
        // Keep original values to revert back to
        original_client_rate: rateItem.client_rate,
        original_subcontractor_rate: rateItem.subcontractor_rate,
  
        // Current editing values
        client_rate: rateItem.client_rate,
        subcontractor_rate: rateItem.subcontractor_rate,
      },
    }));
  };
  
  const cancelEditing = (rateId) => {
    setEditingRates((prev) => {
      const updated = { ...prev };
      delete updated[rateId];
      return updated;
    });
  };
  
  const revertChanges = (rateId) => {
    setEditingRates((prev) => {
      const updated = { ...prev };
      const { original_client_rate, original_subcontractor_rate } = updated[rateId];
  
      updated[rateId].client_rate = original_client_rate;
      updated[rateId].subcontractor_rate = original_subcontractor_rate;
  
      return updated;
    });
  };
  
  const updateEditingValue = (rateId, field, value) => {
    setEditingRates((prev) => ({
      ...prev,
      [rateId]: {
        ...prev[rateId],
        [field]: value,
      },
    }));
  };
  
  const confirmUpdate = async (rateId) => {
    try {
      const { client_rate, subcontractor_rate } = editingRates[rateId];
      await updateSiteEquipmentRate(site.site_id, rateId, {
        client_rate,
        subcontractor_rate,
      });
      messageApi.success('Updated equipment rate successfully.');
    } catch (error) {
      console.error('Error updating equipment rate:', error);
      messageApi.error('Failed to update equipment rate.');
    } finally {
      await refreshSiteEquipmentRates();
      cancelEditing(rateId);
    }
  };

  // -------------------------------------------
  //  Table columns configuration
  // -------------------------------------------
  const columns = [
    {
      title: 'Equipment Name',
      dataIndex: 'equipment_name',
      key: 'equipment_name',
      width: '30%',
      render: (text) => {
        return <strong>{text}</strong>;
      },
    },
    {
      title: 'Subcontractor Rate',
      dataIndex: 'subcontractor_rate',
      key: 'subcontractor_rate',
      width: '25%',
      render: (value, record) => {
        const isEditing = editingRates[record.id];
        if (isEditing) {
          return (
            <Input
              prefix="$"
              addonAfter={site.country === constants.country.canada ? 'CAD' : 'USD'}
              value={isEditing.subcontractor_rate}
              onChange={(e) => updateEditingValue(record.id, 'subcontractor_rate', e.target.value)}
              style={{ width: '150px' }}
            />
          );
        }
        return value
          ? `$${Number(value).toFixed(2)} ${site.country === constants.country.canada ? 'CAD' : 'USD'}`
          : null;
      },
    },
    {
      title: 'Client Rate',
      dataIndex: 'client_rate',
      key: 'client_rate',
      width: '25%',
      render: (value, record) => {
        const isEditing = editingRates[record.id];
        if (isEditing) {
          return (
            <Input
              prefix="$"
              addonAfter={site.country === constants.country.canada ? 'CAD' : 'USD'}
              value={isEditing.client_rate}
              onChange={(e) => updateEditingValue(record.id, 'client_rate', e.target.value)}
              style={{ width: '150px' }}
            />
          );
        }
        return value
          ? `$${Number(value).toFixed(2)} ${site.country === constants.country.canada ? 'CAD' : 'USD'}`
          : null;
      },
    },
    {
      key: 'actions',
      align: 'right',
      render: (text, record) => {
        const isEditing = editingRates[record.id];
        if (isEditing) {
          return (
            <>
              <CloseOutlined
                onClick={() => cancelEditing(record.id)}
                style={{ color: 'red', cursor: 'pointer', fontSize: '16px', marginRight: '12px' }}
              />
              <RollbackOutlined
                onClick={() => revertChanges(record.id)}
                style={{ color: 'blue', cursor: 'pointer', fontSize: '16px', marginRight: '12px' }}
              />
              <CheckOutlined
                onClick={() => confirmUpdate(record.id)}
                style={{ color: 'green', cursor: 'pointer', fontSize: '16px' }}
              />
            </>
          );
        }
        return (
          <>
            <EditOutlined
              onClick={() => startEditing(record)}
              style={{ cursor: 'pointer', marginRight: '16px' }}
            />
            <Popconfirm
              title="Are you sure you want to delete this rate?"
              onConfirm={() => handleDeleteRate(record.id, record.equipment_id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <div>
      {/* Equipment Selector + Add Button */}
      <div style={{ display: 'flex', gap: '10px', marginBottom: '16px' }}>
        <Select
          options={allEquipment}
          showSearch
          optionFilterProp="label"
          placeholder="Select Equipment"
          value={selectedEquipmentId}
          onChange={(val) => setSelectedEquipmentId(val)}
          style={{ width: '100%' }}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddEquipmentRate}
          disabled={!selectedEquipmentId}
        >
          Add
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={siteEquipRates}
        rowKey="id"
        pagination={false}
      />
    </div>
  );
};

export default EquipmentRatesTab;