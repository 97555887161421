// SubcontractorsPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { Typography, TextField, Box, Select, Menu, MenuItem, Snackbar, Card, CardContent, FormControl, InputLabel, CircularProgress, InputAdornment } from '@mui/material';
import { Search as SearchIcon} from '@mui/icons-material';
import { Select as AntdSelect, ConfigProvider, Table, Button, Input, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { US, CA } from 'country-flag-icons/react/3x2'
import {
  fetchSubcontractors,
  updateSubcontractor,
  fetchRoutes,
  fetchSubcontractorRoutes,
  updateSubcontractorRoutes,
  addRouteSubcontractor,
  removeRouteSubcontractor
} from '../api';
import { useConstants } from '../context/ConstantsContext';
import SideMenu from '../components/SideMenu';

const SubcontractorsPage = () => {
  const constants = useConstants();
  const [constantsLoading, setConstantsLoading] = useState(true);
  const [subcontractors, setSubcontractors] = useState([]);
  const [filteredSubcontractors, setFilteredSubcontractors] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [error, setError] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editSubcontractorData, setEditSubcontractorData] = useState({ subcontractor_id: '', name: '', company_code: '', contact_name: '', contact_num: '', contact_email: '', snow_contract_type: '' });
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSubcontractor, setSelectedSubcontractor] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [expandedSubcontractor, setExpandedSubcontractor] = useState(null);
  const [openEditRouteDialog, setOpenEditRouteDialog] = useState(false);
  const [editingRoute, setEditingRoute] = useState(null);
  const [routeSearchQuery, setRouteSearchQuery] = useState('');
  const [selectedRouteForAssignment, setSelectedRouteForAssignment] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('site_id');

  useEffect(() => {
    if (constants) {
      setConstantsLoading(false);
    }
  }, [constants]);

  useEffect(() => {
    loadRoutes();
    loadSubcontractors();
  }, []);

  useEffect(() => {
    const filteredData = subcontractors.filter(subcontractor => {
      const name = subcontractor.name || 'N/A';
  
      return (
        name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  
    setFilteredSubcontractors(filteredData);
  }, [searchQuery, subcontractors]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const loadSubcontractors = async () => {
    try {
      const data = await fetchSubcontractors();
      setSubcontractors(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const loadRoutes = async () => {
    try {
      const data = await fetchRoutes();
      setRoutes(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEditClickOpen = (subcontractor) => {
    setEditSubcontractorData(subcontractor);
    setOpenEditDialog(true);
  };

  const handleEditClose = () => {
    setOpenEditDialog(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditSubcontractorData({ ...editSubcontractorData, [name]: value });
  };

  const handleUpdateSubcontractor = async () => {
    try {
      const updatedData = {
        ...editSubcontractorData,
        routes: editSubcontractorData.routes ? editSubcontractorData.routes.map(route => route.route_id) : []
      };
      await updateSubcontractor(updatedData, editSubcontractorData.subcontractor_id);
      handleEditClose();
      await loadSubcontractors();
      setSnackbarMessage('Subcontractor updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update subcontractor:', error);
      setSnackbarMessage(`Failed to update subcontractor: ${error.response?.data?.error || error.message}`);
      setSnackbarOpen(true);
    }
  };

  const handleExpandSubcontractor = async (subcontractorId) => {
    if (expandedSubcontractor === subcontractorId) {
      setExpandedSubcontractor(null);
    } else {
      try {
        const assignedRoutes = await fetchSubcontractorRoutes(subcontractorId);
        const assignedRouteIds = assignedRoutes.map(route => route.route_id);

        const availableRoutesForSubcontractor = routes.filter(route => !assignedRouteIds.includes(route.route_id));

        const updatedSubcontractors = subcontractors.map(sub => 
          sub.subcontractor_id === subcontractorId
            ? { ...sub, routes: assignedRoutes, availableRoutes: availableRoutesForSubcontractor }
            : sub
        );

        setSubcontractors(updatedSubcontractors);
        setExpandedSubcontractor(subcontractorId);
        setRouteSearchQuery('');
        setSelectedRouteForAssignment(null);
      } catch (error) {
        console.error('Error fetching subcontractor routes:', error);
        setError('Failed to fetch subcontractor routes');
      }
    }
  };

const handleAddSubcontractorRoute = async (subcontractorId, routeId) => {
  try {
    await addRouteSubcontractor(routeId, subcontractorId);

    const updatedRoutes = await fetchSubcontractorRoutes(subcontractorId);

    setSubcontractors(prevSubcontractors =>
      prevSubcontractors.map(sub =>
        sub.subcontractor_id === subcontractorId
          ? { ...sub, routes: updatedRoutes }
          : sub
      )
    );

    const assignedRouteIds = updatedRoutes.map(route => route.route_id);
    const availableRoutesForSubcontractor = routes.filter(route => !assignedRouteIds.includes(route.route_id));

    setSubcontractors(prevSubcontractors =>
      prevSubcontractors.map(sub =>
        sub.subcontractor_id === subcontractorId
          ? { ...sub, availableRoutes: availableRoutesForSubcontractor }
          : sub
      )
    );

    setSelectedRouteForAssignment(null);

    setExpandedSubcontractor(subcontractorId);

    setSnackbarMessage('Route assigned successfully');
    setSnackbarOpen(true);
  } catch (error) {
    console.error('Failed to assign route:', error);
    setSnackbarMessage('Failed to assign route');
    setSnackbarOpen(true);
  }
};

  const handleRemoveSubcontractorRoute = async (routeId, subcontractorId) => {
    console.log(`removing route subcontractor ${routeId},${subcontractorId}`)
    try {
      await removeRouteSubcontractor(routeId, subcontractorId);

      const removedRoute = routes.find(route => route.route_id === routeId);

      setSubcontractors(prevSubcontractors =>
        prevSubcontractors.map(sub =>
          sub.subcontractor_id === subcontractorId
            ? {
                ...sub,
                routes: sub.routes.filter(route => route.route_id !== routeId),
                availableRoutes: [...sub.availableRoutes, removedRoute]
              }
            : sub
        )
      );

      setSnackbarMessage('Route assignment removed successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to remove route assignment:', error);
      setSnackbarMessage('Failed to remove route assignment');
      setSnackbarOpen(true);
    }
  };

  const handleEditRouteClose = () => {
    setOpenEditRouteDialog(false);
    setEditingRoute(null);
  };

  const handleEditRouteOpen = (subcontractorId, route) => {
    setEditingRoute({ 
      ...route, 
      subcontractorId,
      salting_amount: route.salting_amount || 0,
      number_of_saltings_included: route.number_of_saltings_included || 0,
      number_of_saltings_performed: route.number_of_saltings_performed || 0
    });
    setOpenEditRouteDialog(true);
  };

  const handleEditRouteChange = (e) => {
    const name = e.target ? e.target.name : 'contract_type';
    const value = e.target ? e.target.value : e;
  
    setEditingRoute(prev => ({ ...prev, [name]: value }));
  };

  const handleEditRouteSave = async () => {
    try {
      await updateSubcontractorRoutes(editingRoute.subcontractorId, {
        routes: [{
          route_id: editingRoute.route_id,
          contract_type: editingRoute.contract_type,
          salting_amount: parseInt(editingRoute.salting_amount),
          number_of_saltings_included: parseInt(editingRoute.number_of_saltings_included),
          number_of_saltings_performed: parseInt(editingRoute.number_of_saltings_performed)
        }]
      });

      const updatedRoutes = await fetchSubcontractorRoutes(editingRoute.subcontractorId);

      setSubcontractors(prevSubcontractors =>
        prevSubcontractors.map(sub =>
          sub.subcontractor_id === editingRoute.subcontractorId
            ? { ...sub, routes: updatedRoutes }
            : sub
        )
      );

      handleEditRouteClose();
      setExpandedSubcontractor(editingRoute.subcontractorId);
      setSnackbarMessage('Route assignment updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update route assignment:', error);
      setSnackbarMessage('Failed to update route assignment');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      align: 'center',
      render: (country) => {
        if (country === 'Canada') {
          return <CA title="Canada" style={{ width: '24px', height: '16px' }} />;
        } else if (country === 'USA') {
          return <US title="United States" style={{ width: '24px', height: '16px' }} />;
        }
      },
    },
    {
      title: 'Contact Name',
      dataIndex: 'contact_name',
      key: 'contact_name',
    },
    {
      title: 'Contact Email',
      dataIndex: 'contact_email',
      key: 'contact_email',
    },
    {
      title: 'Contact Number',
      dataIndex: 'contact_num',
      key: 'contact_num',
    },
    {
      title: '',
      key: 'actions',
      render: (subcontractor) => (
        <Box>
          <ThreeDotsMenu
            record={subcontractor}
            handleEditClick={() => handleEditClickOpen(subcontractor)}
          />
        </Box>
      ),
    },
  ]

  const expandedColumns = [
    {
      title: 'Route Name',
      dataIndex: 'route_name',
      key: 'route_name',
    },
    {
      title: 'Contract Type',
      dataIndex: 'contract_type',
      key: 'contract_type',
    },
    {
      title: 'Salting Amount',
      dataIndex: 'salting_amount',
      key: 'salting_amount',
      render: (salting_amount, record) => {
        if (record.contract_type === constants.contract_type.all_inclusive) {
          return 'N/A';
        }
        return salting_amount != null ? `$${salting_amount.toFixed(2)}` : '-';
      },
    },
    {
      title: 'Saltings Performed',
      dataIndex: 'number_of_saltings_performed',
      key: 'number_of_saltings_performed',
      render: (text, record) => (
        record.contract_type === constants.contract_type.flat_rate ? text : 'N/A'
      ),
    },
    {
      title: 'Saltings Included',
      dataIndex: 'number_of_saltings_included',
      key: 'number_of_saltings_included',
      render: (text, record) => (
        record.contract_type === constants.contract_type.flat_rate ? text : 'N/A'
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (routeRecord) => (
        <Box display="flex" alignItems="center" gap="20px">
          <EditOutlined
            onClick={() => handleEditRouteOpen(routeRecord.subcontractor_id, routeRecord)}
            style={{ color: 'grey', cursor: 'pointer', fontSize: '18px' }}
          />
          <DeleteOutlined
            onClick={() => handleRemoveSubcontractorRoute(routeRecord.route_id, routeRecord.subcontractor_id)}
            style={{ color: 'grey', cursor: 'pointer', fontSize: '18px' }}
          />
        </Box>
      ),
    },
  ];


  if (constantsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ConfigProvider
      theme={{
        token: {
        colorPrimary: '#F57D20',
        colorLinkHover: '#F57D20',
        colorLink: '#F57D20',
        },
      }}
    >
      <Box display="flex" style={{ width: '98vw', marginTop: '20px' }}>
        <Box width="250px">
          <SideMenu />
        </Box>
        <Box flexGrow={1}>
        <Card style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', }}>
          <CardContent>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
              <Box style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '5px', flexDirection: "column" }}>
                <Typography variant="h5" component="div">
                  Subcontractors
                </Typography>
              </Box>
              <Input
                type="text"
                placeholder="Search Subcontractors"
                onChange={handleSearchChange}
                value={searchQuery}
                style={{ width: '250px' }}
                prefix={ <SearchIcon style={{ color: "#d9d9d9" }} fontSize="small" /> }
              />
            </Box>
            <Table
              columns={columns}
              dataSource={filteredSubcontractors}
              pagination={false}
              expandable={{
                expandedRowRender: (record) => (
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginBottom: '10px',
                      }}
                    >
                      <Typography variant="h6">Route Assignments</Typography>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px', // Add some space between the select and the button
                          width: '50%',
                        }}
                      >
                        <ReactSelect
                          options={
                            (record.availableRoutes || [])
                              .filter((route) =>
                                route.route_name.toLowerCase().includes(routeSearchQuery.toLowerCase())
                              )
                              .map((route) => ({
                                value: route.route_id,
                                label: route.route_name,
                              }))
                          }
                          onChange={(option) => setSelectedRouteForAssignment(option)}
                          value={selectedRouteForAssignment}
                          placeholder="Select a route to assign"
                          isClearable
                          isSearchable
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              width: '100%',
                            }),
                          }}
                        />
                        <Button
                          type="primary"
                          disabled={!selectedRouteForAssignment}
                          onClick={() =>
                            handleAddSubcontractorRoute(record.subcontractor_id, selectedRouteForAssignment?.value)
                          }
                        >
                          Assign Route
                        </Button>
                      </Box>
                    </Box>
                    <Table
                      columns={expandedColumns}
                      dataSource={
                        record.routes
                          ? record.routes.map((route) => ({
                              ...route,
                              subcontractor_id: record.subcontractor_id,
                            }))
                          : []
                      }
                      rowKey="route_id"
                      pagination={false}
                      style={{ width: '100%' }}
                    />
                  </Box>
                ),
                expandedRowKeys: [expandedSubcontractor],
                onExpand: (expanded, record) => {
                  handleExpandSubcontractor(record.subcontractor_id);
                },
              }}
              rowKey={(record) => record.subcontractor_id}
            />

            <Modal
              width={600}
              open={openEditDialog}
              onClose={handleEditClose}
              onCancel={handleEditClose}
              onOk={handleUpdateSubcontractor}
              title="Edit Subcontractor"
              centered
              footer={[
                <Button key="cancel-button" onClick={handleEditClose}>Cancel</Button>,
                <Button key="update-button" type="primary" onClick={handleUpdateSubcontractor}>Update</Button>,
              ]}
            >
              <span style={{ width: '100%', textAlign: 'left', fontSize: '12px', fontWeight: '500', fontFamily: "'Plus Jakarta Sans', sans-serif", marginLeft: '5px', color: "#4a4a4a", marginTop: '10px' }}>Name</span>
              <Input
                fullWidth
                placeholder="Name"
                style={{ marginBottom: '10px' }}
                onChange={handleEditChange}
                value={editSubcontractorData.name}
                name="name"
              />
              <span style={{ width: '100%', textAlign: 'left', fontSize: '12px', fontWeight: '500', fontFamily: "'Plus Jakarta Sans', sans-serif", marginLeft: '5px', color: "#4a4a4a"}}>Company Code</span>
              <Input
                fullWidth
                placeholder="Company Code"
                onChange={handleEditChange}
                value={editSubcontractorData.company_code}
                style={{ marginBottom: '10px' }}
                name="company_code"
              />  
              <span style={{ width: '100%', textAlign: 'left', fontSize: '12px', fontWeight: '500', fontFamily: "'Plus Jakarta Sans', sans-serif", marginLeft: '5px', color: "#4a4a4a"}}>Contact Name</span>
              <Input
                fullWidth
                placeholder="Contact Name"
                onChange={handleEditChange}
                value={editSubcontractorData.contact_name}
                style={{ marginBottom: '10px' }}
                name="contact_name"
              />
              <span style={{ width: '100%', textAlign: 'left', fontSize: '12px', fontWeight: '500', fontFamily: "'Plus Jakarta Sans', sans-serif", marginLeft: '5px', color: "#4a4a4a"}}>Contact Number</span>
              <Input
                fullWidth
                placeholder="Contact Number"
                onChange={handleEditChange}
                value={editSubcontractorData.contact_num}
                style={{ marginBottom: '10px' }}
                name="contact_num"
              />
              <span style={{ width: '100%', textAlign: 'left', fontSize: '12px', fontWeight: '500', fontFamily: "'Plus Jakarta Sans', sans-serif", marginLeft: '5px', color: "#4a4a4a"}}>Contact Email</span>
              <Input
                fullWidth
                placeholder="Contact Email"
                onChange={handleEditChange}
                value={editSubcontractorData.contact_email}
                style={{ marginBottom: '10px' }}
                name="contact_email"
              />
              <span style={{ width: '100%', textAlign: 'left', fontSize: '12px', fontWeight: '500', fontFamily: "'Plus Jakarta Sans', sans-serif", marginLeft: '5px', color: "#4a4a4a"}}>Snow Contract Type</span>
              <AntdSelect
                name="snow_contract_type"
                value={editSubcontractorData.snow_contract_type}
                onChange={(value) =>
                  setEditSubcontractorData({
                    ...editSubcontractorData,
                    snow_contract_type: value,
                  })
                }
                options={[
                  { value: constants.contract_type.hourly, label: constants.contract_type.hourly },
                  { value: constants.contract_type.accum, label: constants.contract_type.accum }
                ]}
                style={{ width: '100%', marginBottom: '10px' }}
                placeholder="Snow Contract Type"
              />
            </Modal>
          
            <Modal 
              open={openEditRouteDialog} 
              onClose={handleEditRouteClose} 
              onCancel={handleEditRouteClose} 
              onOk={handleEditRouteSave} 
              centered 
              title="Edit Route Subcontractor Assignment"
            >
              <FormControl fullWidth margin="dense" variant="outlined">
                <InputLabel id="salting-contract-type-label">Salting Contract Type</InputLabel>
                <Select
                  labelId="salting-contract-type-label"
                  name="contract_type"
                  value={editingRoute?.contract_type}
                  onChange={handleEditRouteChange}
                  label="Salting Contract Type"
                  displayEmpty
                >
                  <MenuItem value={constants?.contract_type.per_visit}>{constants?.contract_type.per_visit}</MenuItem>
                  <MenuItem value={constants?.contract_type.flat_rate}>{constants?.contract_type.flat_rate}</MenuItem>
                  <MenuItem value={constants?.contract_type.all_inclusive}>{constants?.contract_type.all_inclusive}</MenuItem>
                </Select>
              </FormControl>
              
              {/* Only render Salting Amount if the contract type is not All-Inclusive */}
              {editingRoute?.contract_type !== constants?.contract_type.all_inclusive && (
                <>
                  <TextField
                    margin="dense"
                    name="salting_amount"
                    label={`Salting Amount`}
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editingRoute?.salting_amount}
                    onChange={handleEditRouteChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </>
              )}
              
              {/* Only render Saltings Included and Performed fields if the contract type is Flat Rate */}
              {editingRoute?.contract_type === constants?.contract_type.flat_rate && (
                <>
                  <TextField
                    margin="dense"
                    name="number_of_saltings_performed"
                    label="Number of Saltings Performed"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editingRoute?.number_of_saltings_performed}
                    onChange={handleEditRouteChange}
                  />

                  <TextField
                    margin="dense"
                    name="number_of_saltings_included"
                    label="Number of Saltings Included"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editingRoute?.number_of_saltings_included}
                    onChange={handleEditRouteChange}
                  />
                </>
              )}
            </Modal>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={4000}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
            />
          </CardContent>
        </Card>
        </Box>
      </Box>
    </ConfigProvider>
  );
};

const ThreeDotsMenu = (props) => {
  const navigate = useNavigate();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleEditClick = () => {
    setAnchorEl(null);
    props.handleEditClick()
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const flyoutOpen = Boolean(anchorEl);

  return (
    <div>
      <Button id="edit-button" onClick={handleOpenMenu} type="primary" icon={<MoreOutlined />}>
      </Button>
      <Menu
        id={`edit-flyout-${props.record.subcontractor_id}`}
        anchorEl={anchorEl}
        open={flyoutOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': `edit-flyout-${props.record.subcontractor_id}`,
        }}
      >
        <MenuItem onClick={() => handleEditClick()}>Edit Subcontrctor</MenuItem>
        <MenuItem onClick={() => navigate(`/subcontractors/${props.record.subcontractor_id}/equipment`)}>Add Equipment Rates</MenuItem>
      </Menu>
    </div>
  );
}

ThreeDotsMenu.propTypes = {
  record: PropTypes.object,
  handleEditClick: PropTypes.func,
};

export default SubcontractorsPage;
