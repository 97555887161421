import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, Button, Spin, List, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { createWildcardRoute, fetchEvent, fetchSites } from '../api';

const CreateWildcardRoute = ({ eventId, type, open, onClose, onRouteCreated, messageApi }) => {
  const [event, setEvent] = useState(null);
  const [allSites, setAllSites] = useState([]);
  const [availableSites, setAvailableSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const sortSites = (sites) =>
    sites.slice().sort((a, b) =>
      a.label.localeCompare(b.label, undefined, { numeric: true })
    );

  useEffect(() => {
    if (open) {
      const initializeForm = async () => {
        setLoading(true);
        try {
          const event = await fetchEvent(eventId);
          setEvent(event);
        
          const sites = await fetchSites();
          const sortedSites = sortSites(sites);
          setAllSites(sortedSites);
          setAvailableSites(sortedSites);

          form.resetFields();
          setSelectedSites([]);
          setSearchValue('');
        } catch (error) {
          console.error('Failed to initialize form:', error);
          messageApi.error('Failed to load data. Please try again.');
        } finally {
          setLoading(false);
        }
      };

      initializeForm();
    }
  }, [open, eventId, form, messageApi]);

  const handleOk = async () => {
    try {
      await form.validateFields();
      setLoading(true);
      const { name, zone, sites } = form.getFieldsValue();
      const payload = { type, name, zone, sites };

      const response = await createWildcardRoute(eventId, payload);
      messageApi.success(response.message || 'Wildcard route created successfully');

      if (onRouteCreated) {
        onRouteCreated();
      }

      form.resetFields();
      setSelectedSites([]);
      setSearchValue('');
      onClose();
    } catch (error) {
      console.error('Error creating wildcard route:', error);
      messageApi.error('Failed to create wildcard route. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setSelectedSites([]);
    setSearchValue('');
    onClose();
  };

  const handleSiteRemove = (siteToRemove) => {
    const updatedSites = selectedSites.filter((site) => site !== siteToRemove);
    setSelectedSites(updatedSites);
    form.setFieldsValue({ sites: updatedSites });

    const site = allSites.find((s) => s.value === siteToRemove);
    if (site) {
      setAvailableSites((prev) => sortSites([...prev, site]));
    }
  };

  const handleSiteSelect = (value) => {
    if (!selectedSites.includes(value)) {
      const updatedSites = [...selectedSites, value];
      setSelectedSites(updatedSites);
      form.setFieldsValue({ sites: updatedSites });

      setAvailableSites((prev) => prev.filter((site) => site.value !== value));
    }
    // Clear search after selection
    setSearchValue('');
  };

  return (
    <Modal
      title="Create Wildcard Route"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk} loading={loading}>
          Create
        </Button>,
      ]}
    >
      {loading && <Spin style={{ position: 'absolute', top: '50%', left: '50%' }} />}
        <Form
          form={form}
          layout="vertical"
          requiredMark="optional"
          initialValues={{ name: '', zone: '', sites: [] }}
        >
          {/* Name and Zone Section */}
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please enter a route name' }]}
              style={{ marginBottom: '24px' }}
            >
              <Input
                placeholder="Enter route name"
                addonAfter={event?.event_name ? `- ${event.event_name}` : ''}
                onChange={(e) => {
                  const value = e.target.value;
                  form.setFieldsValue({ name: value });
                }}
              />
            </Form.Item>

            <Form.Item
              label="Zone"
              name="zone"
              rules={[{ required: true, message: 'Please enter a zone name' }]}
              style={{ marginBottom: '24px' }}
            >
              <Input placeholder="Enter zone" />
            </Form.Item>

          
          <Form.Item
            label="Sites"
            name="sites"
            rules={[{ required: true, message: 'Please select at least one site' }]}
            style={{ marginBottom: '24px' }}
          >
              <Select
                showSearch
                placeholder="Search sites"
                value={null}
                searchValue={searchValue}
                onSearch={(value) => setSearchValue(value)}
                onSelect={(value) => handleSiteSelect(value)}
                onBlur={() => setSearchValue('')} // Reset after user leaves the input
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                options={availableSites}
              />

              {selectedSites.length > 0 &&
                <List
                  size="small"
                  dataSource={selectedSites}
                  renderItem={(site) => (
                    <List.Item
                      actions={[
                        <CloseOutlined
                          onClick={() => handleSiteRemove(site)}
                        />,
                      ]}
                    >
                      {allSites.find((s) => s.value === site)?.label || site}
                    </List.Item>
                  )}
                  bordered
                  style={{ marginTop: '8px' }}
                />
              }
          </Form.Item>
        </Form>
    </Modal>
  );
};

export default CreateWildcardRoute;