// SitesPage.js
import React, { useState, useEffect } from 'react';
import { Typography, Box, Card, CardContent } from '@mui/material';
import { Search as SearchIcon} from '@mui/icons-material';
import { ConfigProvider, Table, Button, Modal, Input, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { fetchSites, fetchSiteById } from '../api';
import SideMenu from '../components/SideMenu';
import SiteEdit from '../components/SiteEdit';

const SitesPage = () => {
  const [sites, setSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [error, setError] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('site_id');

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    loadSites();
  }, []);

  useEffect(() => {
    const filteredData = sites.filter(site => {
      const siteName = site.site_name || 'N/A';
      const location = site.city || 'N/A';
      const client = site.client_name || 'N/A';
  
      const routes = site.routes_details
        ? site.routes_details.map((r) => r.route_name).join(', ').toLowerCase()
        : '';
      const zones = site.routes_details
        ? site.routes_details.map((r) => r.zone_name).join(', ').toLowerCase()
        : '';
  
      return (
        siteName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        location.toLowerCase().includes(searchQuery.toLowerCase()) ||
        client.toLowerCase().includes(searchQuery.toLowerCase()) ||
        routes.includes(searchQuery.toLowerCase()) ||
        zones.includes(searchQuery.toLowerCase())
      );
    });
  
    setFilteredSites(filteredData);
  }, [searchQuery, sites]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedSites = filteredSites.sort((a, b) => {
    const aValue = (a[orderBy] ?? 'N/A').toString();
    const bValue = (b[orderBy] ?? 'N/A').toString();
  
    return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });

  const loadSites = async () => {
    try {
      const data = await fetchSites();
      setSites(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSiteSelect = async (siteId) => {
    try {
      const siteData = await fetchSiteById(siteId);
      setSelectedSite(siteData);
    } catch (error) {
      console.error('Error fetching site data:', error);
    }
  };

  const handleEditContract = async (siteId) => {
    try {
      const siteData = await fetchSiteById(siteId);
      setSelectedSite(siteData);
    } catch (error) {
      console.error('Error fetching site data:', error);
    }
  };

  const columns = [
    {
      title: 'Site Name',
      dataIndex: 'site_name',
      key: 'site_name',
      sorter: true,
      sortOrder: orderBy === 'site_name' && order,
      onHeaderCell: () => ({ onClick: () => handleRequestSort('site_name') }),
    },
    {
      title: 'Location',
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      sortOrder: orderBy === 'city' && order,
      onHeaderCell: () => ({ onClick: () => handleRequestSort('city') }),
    },
    {
      title: 'Reference',
      dataIndex: 'site_reference',
      key: 'site_reference',
      sorter: true,
      sortOrder: orderBy === 'site_reference' && order,
      onHeaderCell: () => ({ onClick: () => handleRequestSort('site_reference') }),
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      sorter: true,
      sortOrder: orderBy === 'client_name' && order,
      onHeaderCell: () => ({ onClick: () => handleRequestSort('client_name') }),
    },
    {
      title: 'Route',
      dataIndex: 'routes_details',
      key: 'routes_details',
      render: (routes) => routes.map((r) => r.route_name).join(', '),
    },
    {
      title: 'Zone',
      dataIndex: 'routes_details',
      key: 'routes_details',
      render: (routes) => routes.map((r) => r.zone_name).join(', '),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (_, record) => (
        <EditOutlined
          onClick={(e) => {
            e.stopPropagation();
            handleEditContract(record.site_id);
          }}
        />
      ),
    },
  ];

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F57D20',
        },
      }}
    >
      {contextHolder}
      <Box display="flex" style={{ width: '98vw', marginTop: '20px' }}>
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>
        <Box flexGrow={1}>
          <Card style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
            <CardContent>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="h5" component="div">
                  Sites
                </Typography>
                <Input
                  type="text"
                  placeholder="Search Sites"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{ width: '250px' }}
                  prefix={ <SearchIcon style={{ color: "#d9d9d9" }} fontSize="small" /> }
                />
              </Box>
              <Table
                columns={columns}
                dataSource={sortedSites}
                pagination={false}
                onRow={(record) => ({
                  onClick: () => handleSiteSelect(record.site_id),
                  style: { cursor: 'pointer' },
                })}
                style={{ marginTop: '20px' }}
              />
              {selectedSite && (
                <Modal
                  open={Boolean(selectedSite)}
                  onClose={() => setSelectedSite(null)}
                  onCancel={() => setSelectedSite(null)}
                  centered
                  width='50%'
                  footer={[
                    <Button
                      key="submit"
                      type="primary"
                      onClick={() => setSelectedSite(null)}
                    >
                      Close
                    </Button>,
                  ]}
                >
                  <SiteEdit
                    site={selectedSite}
                    messageApi={messageApi}
                  />
                </Modal>
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </ConfigProvider>
  );
};

export default SitesPage;