// frontend/src/components/SiteEdit.js
import React, { useState } from 'react';
import { Tabs, Input, Select, Button, List } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useConstants } from '../context/ConstantsContext';
import { updateSiteContacts, updateSiteContract } from '../api';
import EquipmentRatesTab from './SiteEditEquipment';

const { TabPane } = Tabs;

const SiteEdit = ({ site, messageApi }) => {
  const constants = useConstants();

  const [siteContactData, setSiteContactData] = useState({
    notification_email: site.notification_email || '',
    cc_emails: site.cc_emails || [],
  });

  const [siteContractData, setSiteContractData] = useState({
    salting_contract_type: site.salting_contract_type,
    snow_contract_type: site.snow_contract_type,
    salting_amount: site.salting_amount || 500,
    saltings_included: site.saltings_included || 20,
    saltings_performed: site.saltings_performed || 0,
    per_accum_client_rate: site.per_accum_client_rate || 75,
    per_accum_subcontractor_rate: site.per_accum_subcontractor_rate || 50,
  });

  const detailData = [
    { label: 'Site Name', value: site.site_name },
    { label: 'Client Name', value: site.client_name },
    { label: 'Site Contact Email', value: site.notification_email },
    {
      label: 'Site CC Emails',
      value: site.cc_emails?.length ? (
        <div style={{ whiteSpace: 'pre-line' }}>
          {site.cc_emails.join('\n')}
        </div>
      ) : (
        null
      ),
    },
    { label: 'Street Address', value: site.street_address },
    { label: 'City', value: site.city },
    { label: 'Province/State', value: site.province_state },
    { label: 'Postal/Zip Code', value: site.postal_zip_code },
    { label: 'Country', value: site.country },
    { label: 'Salting Contract Type', value: site.salting_contract_type },
    { label: 'Snow Contract Type', value: site.snow_contract_type },
    { label: 'Salting Amount', value: `$${site.salting_amount}` },
    { label: 'Saltings Performed', value: site.saltings_performed },
    { label: 'Saltings Included', value: site.saltings_included },
    { label: 'Per-Accum Client Rate', value: `$${site.per_accum_client_rate}` },
    { label: 'Per-Accum Subcontractor Rate', value: `$${site.per_accum_subcontractor_rate}` },
  ];

  /* --------------------------------------------
   * Handlers for siteContactData
   * -------------------------------------------- */
  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setSiteContactData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addCCEmailRow = () => {
    setSiteContactData((prev) => ({
      ...prev,
      cc_emails: [...prev.cc_emails, ''],
    }));
  };

  const handleCCEmailChange = (index, value) => {
    const updatedCC = [...siteContactData.cc_emails];
    updatedCC[index] = value;
    setSiteContactData((prev) => ({
      ...prev,
      cc_emails: updatedCC,
    }));
  };

  const deleteCCEmailRow = (index) => {
    const updatedCC = [...siteContactData.cc_emails];
    updatedCC.splice(index, 1);
    setSiteContactData((prev) => ({
      ...prev,
      cc_emails: updatedCC,
    }));
  };

  /* --------------------------------------------
   * Handlers for siteContractData
   * -------------------------------------------- */
  const handleContractSelect = (fieldName, value) => {
    setSiteContractData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleContractInputChange = (e) => {
    const { name, value } = e.target;
    setSiteContractData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  /* --------------------------------------------
   * Submit handlers
   * -------------------------------------------- */
  const handleSubmitSiteContacts = async (e) => {
    e.preventDefault();
    try {
      await updateSiteContacts(site.site_id, siteContactData);
      messageApi.success('Site contacts updated successfully.');
    } catch (error) {
      console.error('Error updating site contacts', error);
      messageApi.error('Failed to update site contacts.');
    }
  };

  const handleSubmitSiteContract = async (e) => {
    e.preventDefault();
    try {
      await updateSiteContract(site.site_id, siteContractData);
      messageApi.success('Site contract updated successfully.');
    } catch (error) {
      console.error('Error updating site contract', error);
      messageApi.error('Failed to update site contract.');
    }
  };

  /* --------------------------------------------
   * Render CC Email Rows
   * -------------------------------------------- */
  const renderCCEmailRows = () =>
    siteContactData.cc_emails.map((email, index) => (
      <div
        key={index}
        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
      >
        <Input
          placeholder="CC Email"
          value={email}
          onChange={(e) => handleCCEmailChange(index, e.target.value)}
          style={{ flexGrow: 1 }}
        />
        <DeleteOutlined
          onClick={() => deleteCCEmailRow(index)}
          style={{ marginLeft: '10px', fontSize: '18px', cursor: 'pointer' }}
        />
      </div>
    ));

  return (
    <Tabs defaultActiveKey="details">
      {/* ------------------ DETAILS TAB (READ-ONLY) ------------------ */}
      <TabPane tab="Details" key="details">
      <List
        dataSource={detailData}
        size="small"
        renderItem={(item) => (
          <List.Item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <div>{item.label}</div>
            <div>{item.value}</div>
          </List.Item>
        )}
        />
      </TabPane>

      {/* --------------- EDIT SITE CONTACT TAB --------------- */}
      <TabPane tab="Edit Site Contacts" key="contact">
        <form onSubmit={handleSubmitSiteContacts}>
          <label style={labelStyle}>
            Notification Email (overrides client contact for notification emails)
          </label>
          <Input
            name="notification_email"
            value={siteContactData.notification_email}
            onChange={handleContactChange}
            placeholder="Notification Email (optional)"
            style={{ marginBottom: '10px' }}
          />

          <label style={{ ...labelStyle, display: 'block' }}>CC Emails</label>
          {renderCCEmailRows()}
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={addCCEmailRow}
          >
            Add CC Email
          </Button>

          <Button
            block
            style={{ marginTop: '20px', marginBottom: '20px' }}
            onClick={handleSubmitSiteContacts}
          >
            Update Site Contact
          </Button>
        </form>
      </TabPane>

      {/* --------------- EDIT SITE CONTRACT TAB --------------- */}
      <TabPane tab="Edit Site Contract" key="contract">
        <form onSubmit={handleSubmitSiteContract}>
          <label style={labelStyle}>Salting Contract Type</label>
          <Select
            name="salting_contract_type"
            value={
              siteContractData.salting_contract_type === 'Event Assigned'
                ? undefined
                : siteContractData.salting_contract_type
            }
            onChange={(val) => handleContractSelect('salting_contract_type', val)}
            options={[
              {
                value: constants.contract_type.per_visit,
                label: constants.contract_type.per_visit,
              },
              {
                value: constants.contract_type.flat_rate,
                label: constants.contract_type.flat_rate,
              },
              {
                value: constants.contract_type.all_inclusive,
                label: constants.contract_type.all_inclusive,
              },
            ]}
            style={{ width: '100%', marginBottom: '10px' }}
            placeholder="Salting Contract Type"
          />

          <label style={labelStyle}>Snow Contract Type</label>
          <Select
            name="snow_contract_type"
            value={
              siteContractData.snow_contract_type === 'Event Assigned'
                ? undefined
                : siteContractData.snow_contract_type
            }
            onChange={(val) => handleContractSelect('snow_contract_type', val)}
            options={[
              {
                value: constants.contract_type.hourly,
                label: constants.contract_type.hourly,
              },
              {
                value: constants.contract_type.accum,
                label: constants.contract_type.accum,
              },
            ]}
            style={{ width: '100%', marginBottom: '10px' }}
            placeholder="Snow Contract Type"
          />

          <label style={labelStyle}>Salting Amount</label>
          <Input
            name="salting_amount"
            value={siteContractData.salting_amount}
            onChange={handleContractInputChange}
            type="number"
            placeholder="Salting Amount"
            style={{ marginBottom: '10px' }}
          />

          <label style={labelStyle}>Saltings Included</label>
          <Input
            name="saltings_included"
            value={siteContractData.saltings_included}
            onChange={handleContractInputChange}
            type="number"
            placeholder="Saltings Included"
            style={{ marginBottom: '10px' }}
          />

          <label style={labelStyle}>Saltings Performed</label>
          <Input
            name="saltings_performed"
            value={siteContractData.saltings_performed}
            onChange={handleContractInputChange}
            type="number"
            placeholder="Saltings Performed"
            style={{ marginBottom: '10px' }}
          />

          <label style={labelStyle}>
            Per {site.country === constants.country.canada ? 'CM' : 'Inch'} (Client)
          </label>
          <Input
            name="per_accum_client_rate"
            value={siteContractData.per_accum_client_rate}
            onChange={handleContractInputChange}
            type="number"
            placeholder={`Per ${
              site.country === constants.country.canada ? 'CM' : 'Inch'
            } (Client)`}
            style={{ marginBottom: '10px' }}
          />

          <label style={labelStyle}>
            Per {site.country === constants.country.canada ? 'CM' : 'Inch'} (Subcontractor)
          </label>
          <Input
            name="per_accum_subcontractor_rate"
            value={siteContractData.per_accum_subcontractor_rate}
            onChange={handleContractInputChange}
            type="number"
            placeholder={`Per ${
              site.country === constants.country.canada ? 'CM' : 'Inch'
            } (Subcontractor)`}
            style={{ marginBottom: '10px' }}
          />

          <Button
            block
            style={{ marginTop: '20px', marginBottom: '20px' }}
            onClick={handleSubmitSiteContract}
          >
            Update Site Contract
          </Button>
        </form>
      </TabPane>

      {/* --------------- EDIT SITE EQUIPMENT RATES TAB --------------- */}
      <TabPane tab="Equipment Rates" key="equipment-rates">
        <EquipmentRatesTab
          site={site}
          messageApi={messageApi}
          constants={constants}
        />
      </TabPane>
    </Tabs>
  );
};

const labelStyle = {
  width: '100%',
  textAlign: 'left',
  fontSize: '12px',
  fontWeight: '500',
  fontFamily: "'Plus Jakarta Sans', sans-serif",
  marginLeft: '5px',
  color: '#4a4a4a',
  marginBottom: '5px',
};

export default SiteEdit;