import React, { useState, useEffect } from "react";
import { Modal, Button, Select, Table, Radio, Form, List } from "antd";
import { useConstants } from "../context/ConstantsContext";
import { fetchWeatherStations, fetchEventLocations, updateEventLocations } from "../api";

const { Option } = Select;

const EditEventModal = ({ open, onClose, event, message }) => {
  const constants = useConstants();
  const [sortedStations, setSortedStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState(null);
  const [eventLocations, setEventLocations] = useState([]);
  const [forecasts, setForecasts] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadWeatherStations = async () => {
      setLoading(true);
      try {
        const weatherStations = await fetchWeatherStations();
        setSortedStations(sortWeatherStations(weatherStations));
      } catch (error) {
        message.error('Failed to fetch weather stations');
        console.error('Error fetching weather stations:', error);
      } finally {
        setLoading(false);
      }
    }

    loadWeatherStations();
  }, [open, message]);

  useEffect(() => {
    if (open) {
      setEventLocations(event?.locations || []);
      setForecasts(event?.forecasts || []);
      console.log(eventLocations);
      console.log(forecasts);
    }
  }, [open, event]);

  useEffect(() => {
    if (forecasts.length > 0) {
      const initialValues = {};
  
      forecasts.forEach((forecast) => {
        initialValues[`${forecast.weather_station_id}_pdf_source`] = forecast.pdf_source || null;
        initialValues[`${forecast.weather_station_id}_pdf_time`] = forecast.pdf_time || null;
      });
  
      form.setFieldsValue(initialValues);
    }
  }, [forecasts, form]);


  const sortWeatherStations = (stations) => {
    return [...stations].sort((a, b) => {
      if (a.country !== b.country) return a.country.localeCompare(b.country);
      if (a.province_state !== b.province_state)
        return a.province_state.localeCompare(b.province_state);
      return a.station_name.localeCompare(b.station_name);
    });
  };

  const handleAddStation = async () => {
    if (selectedStation) {
      setLoading(true);
      try {
        const updatedData = await fetchEventLocations(event.event_id, [selectedStation]);
        console.log(updatedData);
  
        const newLocations = updatedData.locations.filter(
          (location) =>
            !eventLocations.some(
              (existingLocation) =>
                existingLocation.weather_station_id === location.weather_station_id
            )
        );
  
        const newForecasts = updatedData.forecasts.filter(
          (forecast) =>
            !forecasts.some(
              (existingForecast) =>
                existingForecast.weather_station_id === forecast.weather_station_id
            )
        );
  
        setEventLocations((prev) => sortWeatherStations([...prev, ...newLocations]));
        setForecasts((prev) => sortWeatherStations([...prev, ...newForecasts]));
  
        setSelectedStation(null);
      } catch (error) {
        message.error("Failed to fetch associated locations. Please try again.");
        console.error("Error adding station:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const formData = await form.validateFields();

      const updatedLocations = eventLocations.map((location) => ({
        weather_station_id: location.weather_station_id
      }));
  
      const updatedForecasts = forecasts.map((forecast) => ({
        weather_station_id: forecast.weather_station_id,
        pdf_source: formData[`${forecast.weather_station_id}_pdf_source`],
        pdf_time: formData[`${forecast.weather_station_id}_pdf_time`],
      }));
  
      await updateEventLocations(event.event_id, updatedLocations, updatedForecasts);
      message.success("Event locations and forecasts updated successfully!");
      onClose();
    } catch (error) {
      message.error("Failed to save changes. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleFieldChange = (stationId, field, value) => {
    setForecasts((prev) =>
      prev.map((forecast) =>
        forecast.weather_station_id === stationId
          ? { ...forecast, [field]: value }
          : forecast
      )
    );
  };

  const forecastColumns = [
    {
      title: "Location",
      dataIndex: "station_name",
      key: "station_name",
      render: (_, record) => `${record.station_name}, ${record.province_state || ""}`,
    },
    {
      title: "Forecast Source",
      dataIndex: "pdf_source",
      key: "pdf_source",
      render: (_, record) => (
        <Form.Item
          name={`${record.weather_station_id}_pdf_source`}
          rules={[{ required: true, message: "Please select a forecast source" }]}
          initialValue={record.pdf_source || null} // Ensure initial value is set
        >
          <Radio.Group
            onChange={(e) =>
              handleFieldChange(record.weather_station_id, "pdf_source", e.target.value)
            }
          >
            {record.country === constants.country.canada && (
              <Radio value={constants.forecast_source.ec}>
                {constants.forecast_source.ec}
              </Radio>
            )}
            {record.country === constants.country.usa && (
              <Radio value={constants.forecast_source.nws}>
                {constants.forecast_source.nws}
              </Radio>
            )}
            <Radio value={constants.forecast_source.wn}>
              {constants.forecast_source.wn}
            </Radio>
          </Radio.Group>
        </Form.Item>
      ),
    },
    {
      title: "Forecast Time",
      dataIndex: "pdf_time",
      key: "pdf_time",
      render: (_, record) => (
        <Form.Item
          name={`${record.weather_station_id}_pdf_time`}
          rules={[{ required: true, message: "Please select a forecast time" }]}
          initialValue={record.pdf_time || null} // Ensure initial value is set
        >
          <Radio.Group
            onChange={(e) =>
              handleFieldChange(record.weather_station_id, "pdf_time", e.target.value)
            }
          >
            <Radio value="8am">8am</Radio>
            <Radio value="11am">11am</Radio>
          </Radio.Group>
        </Form.Item>
      ),
    },
  ];

  return (
    <Modal
      title="Edit Event Locations"
      open={open}
      onCancel={onClose}
      onOk={handleSave}
      confirmLoading={loading}
      okText="Save"
      cancelText="Cancel"
      width="80%"
    >
      <div style={{ display: "flex", gap: "10px", marginBottom: "16px" }}>
        <Select
          value={selectedStation}
          onChange={(value) => setSelectedStation(value)}
          showSearch
          placeholder="Select a weather station"
          style={{ flex: 1 }}
          optionFilterProp="label"
        >
          {sortedStations.map((station) => (
            <Option
              key={station.weather_station_id}
              value={station.weather_station_id}
              label={`${station.station_name}, ${station.province_state}, ${station.country}`}
            >
              {`${station.station_name}, ${station.province_state}`}
            </Option>
          ))}
        </Select>
        <Button
          type="primary"
          onClick={handleAddStation}
          disabled={!selectedStation}
        >
          Add
        </Button>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <h3>Event Locations</h3>
        <List
          dataSource={eventLocations}
          renderItem={(location) => (
            <List.Item>
              {`${location.station_name}, ${location.province_state || ""}, ${location.country}`}
            </List.Item>
          )}
          bordered
          size="small"
        />
      </div>

      <div>
        <h3>Event Forecasts</h3>
        <Form form={form}>
          <Table
            dataSource={forecasts}
            columns={forecastColumns}
            rowKey="weather_station_id"
            pagination={false}
          />
        </Form>
      </div>
    </Modal>
  );
};

export default EditEventModal;