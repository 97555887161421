// SubcontractorsPage.js
import React, { useState, useEffect } from 'react';

import {
  fetchEquipment,
  updateEquipment,
} from '../api';
import { Typography, Box, Card, CardContent } from '@mui/material';
import SideMenu from '../components/SideMenu';
import { Input, ConfigProvider, Table, message } from 'antd';
import { EditOutlined, RollbackOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

const EquipmentPage = () => {
  const [equipment, setEquipment] = useState([]);
  const [editingKey, setEditingKey] = useState();
  const [originalRates, setOriginalRates] = useState({});
  const [editingValue, setEditingValue] = useState();
  const [error, setError] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    loadEquipment();
  }, []);

  const loadEquipment = async () => {
    try {
      const data = await fetchEquipment();
      setEquipment(data.sort(function(a,b) {
        return a.id - b.id
      }));
    } catch (err) {
      setError(err.message);
    }
  };

  const handleUpdateRates = async (equipment) => {
    try {
      await updateEquipment(equipment.id, editingValue);
      messageApi.success('Equipment rates updated successfully');
      loadEquipment();
    } catch (error) {
      messageApi.error('Failed to update equipment rates');
    } finally {
      setEditingKey(null);
      setEditingValue(null);
    }
  };

  const handleResetRates = async () => {
    if (originalRates) {
      setEditingValue({ ...originalRates });
    }
  };

  const cancelEditRate = () => {
    setEditingKey(null);
    setEditingValue(null);
  }

  const editRate = (equipment) => {
    setEditingKey(equipment.id);
    setEditingValue({
      subcontractor_rate_cad: equipment.subcontractor_rate_cad || '',
      subcontractor_rate_usd: equipment.subcontractor_rate_usd || '',
      client_rate_cad: equipment.client_rate_cad || '',
      client_rate_usd: equipment.client_rate_usd || '',
    });
  
    setOriginalRates({
      subcontractor_rate_cad: equipment.subcontractor_rate_cad || '',
      subcontractor_rate_usd: equipment.subcontractor_rate_usd || '',
      client_rate_cad: equipment.client_rate_cad || '',
      client_rate_usd: equipment.client_rate_usd || '',
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: 'Yeti Subcontractor Rate',
      dataIndex: 'yeti_subcontractor_rate',
      key: 'yeti_subcontractor_rate',
      width: '12.5%',
      render: (yeti_subcontractor_rate) => yeti_subcontractor_rate !== null ? `$ ${yeti_subcontractor_rate.toFixed(2)}` : null,
    },
    {
      title: 'Subcontractor Rates',
      key: 'subcontractor_rates',
      width: '20%',
      render: (equipment) =>
        editingKey === equipment.id ? (
          <div>
            <div style={{ marginBottom: '8px' }}>
              <Input
                addonAfter="CAD"
                prefix="$"
                value={editingValue?.subcontractor_rate_cad}
                onChange={(e) =>
                  setEditingValue((prev) => ({
                    ...prev,
                    subcontractor_rate_cad: e.target.value,
                  }))
                }
                style={{ marginRight: '8px' }}
              />
              <Input
                addonAfter="USD"
                prefix="$"
                value={editingValue?.subcontractor_rate_usd}
                onChange={(e) =>
                  setEditingValue((prev) => ({
                    ...prev,
                    subcontractor_rate_usd: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>
              CAD: {equipment.subcontractor_rate_cad !== null ? `$${equipment.subcontractor_rate_cad.toFixed(2)}` : null}
            </span>
            <span>
              USD: {equipment.subcontractor_rate_usd !== null ? `$${equipment.subcontractor_rate_usd.toFixed(2)}` : null}
            </span>
          </div>
        ),
    },
    {
      title: 'Yeti Client Rate',
      dataIndex: 'yeti_client_rate',
      key: 'yeti_client_rate',
      width: '12.5%',
      render: (yeti_client_rate) => yeti_client_rate !== null ? `$ ${yeti_client_rate.toFixed(2)}` : null,
    },
    {
      title: 'Client Rates',
      key: 'client_rates',
      width: '20%',
      render: (equipment) =>
        editingKey === equipment.id ? (
          <div>
            <div style={{ marginBottom: '8px' }}>
              <Input
                addonAfter="CAD"
                prefix="$"
                value={editingValue?.client_rate_cad}
                onChange={(e) =>
                  setEditingValue((prev) => ({
                    ...prev,
                    client_rate_cad: e.target.value,
                  }))
                }
                style={{ marginRight: '8px' }}
              />
              <Input
                addonAfter="USD"
                prefix="$"
                value={editingValue?.client_rate_usd}
                onChange={(e) =>
                  setEditingValue((prev) => ({
                    ...prev,
                    client_rate_usd: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>
              CAD: {equipment.client_rate_cad !== null ? `$${equipment.client_rate_cad.toFixed(2)}` : null}
            </span>
            <span>
              USD: {equipment.client_rate_usd !== null ? `$${equipment.client_rate_usd.toFixed(2)}` : null}
            </span>
          </div>
        ),
    },
    {
      key: 'actions',
      align: 'center',
      render: (record) => {
        return editingKey === record.id ? (
          <div
            style={{
              display: 'flex',
              gap: '16px',
              justifyContent: 'center',
            }}
          >
            <CloseOutlined
              onClick={cancelEditRate}
              style={{ color: 'red', cursor: 'pointer' }}
            />
            <RollbackOutlined
              onClick={handleResetRates}
              style={{ color: 'blue', cursor: 'pointer' }}
            />
            <CheckOutlined
              onClick={() => handleUpdateRates(record)}
              style={{ color: 'green', cursor: 'pointer' }}
            />
          </div>
        ) : (
          <EditOutlined
            onClick={() => editRate(record)}
            style={{
              fontSize: '1.2em',
              color: 'gray',
              cursor: 'pointer'
            }}
          />
        );
      },
    }
  ];

  if (error) {
    return <Typography variant="h6" color="error">Error: {error}</Typography>;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
        colorPrimary: '#F57D20',
        colorLinkHover: '#F57D20',
        colorLink: '#F57D20',
        },
      }}
    >
      {contextHolder}
      <Box display="flex" style={{ width: '98vw', marginTop: '20px' }}>
        <Box width="250px">
          <SideMenu />
        </Box>
        <Box flexGrow={1}>
        <Card style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', }}>
          <CardContent>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
              <Box style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '5px', flexDirection: "column" }}>
                <Typography variant="h5" component="div">
                  Equipment
                </Typography>
              </Box>
            </Box>
            <Table
              columns={columns}
              dataSource={equipment}
              pagination={false}
              rowKey={(record) => record.id}
            />
          </CardContent>
        </Card>
        </Box>
      </Box>
    </ConfigProvider>
  );
};


export default EquipmentPage;
