// EventHistoryPage.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import {
  Table, ConfigProvider, Select, Card, Tooltip, Button, Modal,
  Checkbox, Form, Popover, Radio, Input, Dropdown,
  message, notification
} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, SearchOutlined, ExportOutlined, DownloadOutlined, RedoOutlined, StopOutlined } from '@ant-design/icons';
import SideMenu from '../components/SideMenu';
import {
  fetchEvents,
  fetchSaltDispatchRoutes,
  fetchSnowDispatchRoutes,
  reDispatchSaltRoute,
  reDispatchSnowRoute,
  cancelSaltDispatchSite,
  cancelSnowDispatchSite,
  checkIfLastSiteOnSaltRoute,
  checkIfLastSiteOnSubcontractorSnowRoute,
  evaluateArchiveRoute,
  archiveRoute,
  updateSaltRouteSubcontractorAcknowledgement,
  updateSnowSiteSubcontractorAcknowledgement,
  fetchEventHistoryCSV
} from '../api';
import { useCurrentEvent } from '../context/EventContext';
import { useConstants } from '../context/ConstantsContext';
import { useUser } from '../context/UserContext';
import { getFormattedDate, formatUtcDateTime } from '../utils/dateUtils';
import { displayResultNotifications } from '../utils/notificationUtils';
import '../styles/ButtonStyles.css'
import '../styles/CustomCheckbox.css'

const EventHistoryPage = () => {
  const constants = useConstants();
  const { user } = useUser();
  const { currentEvent, setCurrentEvent } = useCurrentEvent();
  const currentEventRef = useRef(currentEvent);
  const setCurrentEventRef = useRef(setCurrentEvent);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dispatchRoutes, setDispatchRoutes] = useState([]);
  const [selectedDispatchRoute, setSelectedDispatchRoute] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredDispatchRoutes, setFilteredDispatchRoutes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [redispatchConfirmOpen, setRedispatchConfirmOpen] = useState(false);
  const [archiveConfirmOpen, setArchiveConfirmOpen] = useState(false);
  const [isLastSiteOnRoute, setIsLastSiteOnRoute] = useState(false);
  const [archiveEvaluationData, setArchiveEvaluationData] = useState({ subcontractors: [], numSubcontractors: 0, sites: [], numSites: 0 });
  const [cancelSite, setCancelSite] = useState(null);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [redispatchLoading, setRedispatchLoading] = useState(false);
  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();
  const [notificationApi, notifContextHolder] = notification.useNotification();

  const loadEvents = useCallback(async () => {
    try {
      const response = await fetchEvents({ active: 'false', order: 'desc' });
      const eventsData = response.map(event => ({
        label: `${event.event_name} - ${event.service_type}`,
        value: event.event_id,
        ...event
      }));
      setEvents(eventsData);

      if (currentEventRef.current) {
        const matchedEvent = eventsData.find(event => event.value === currentEventRef.current.event_id);
        setSelectedEvent(matchedEvent);
      } else if (eventsData.length > 0) {
        await setCurrentEventRef.current(eventsData[0].value);
        setSelectedEvent(eventsData[0]);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
      messageApi.error('Error fetching events');
    }
  }, [messageApi]);

  const loadDispatchRoutes = useCallback(async () => {
    if (!selectedEvent) return;
    setLoading(true);
    try {
      const fetchRoutes = selectedEvent.event_type === constants.event_type.snow ? fetchSnowDispatchRoutes : fetchSaltDispatchRoutes;
      const routesData = await fetchRoutes(selectedEvent.value);
      setDispatchRoutes(routesData);
    } catch (error) {
      messageApi.error('Error fetching dispatch routes');
    } finally {
      setLoading(false);
    }
  }, [selectedEvent, constants, messageApi]);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  useEffect(() => {
    loadDispatchRoutes();
  }, [selectedEvent, loadDispatchRoutes]);

  // Filter dispatch routes based on search query
  useEffect(() => {
    const filteredData = dispatchRoutes.filter(route => {
      const routeName = route.route_name || '';
      const subcontractorName = selectedEvent.event_type === constants.event_type.salt
        ? route.subcontractor_name || ''
        : '';
      const siteMatch = route.sites?.some(site => {
        const siteNameMatch = site.site_name.toLowerCase().includes(searchQuery.toLowerCase());
        const siteSubcontractorMatch = selectedEvent.event_type === constants.event_type.snow
          ? site.subcontractor_name?.toLowerCase().includes(searchQuery.toLowerCase())
          : false;
        return siteNameMatch || siteSubcontractorMatch;
      });
      return (
        routeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subcontractorName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        siteMatch
      );
    });
    setFilteredDispatchRoutes(filteredData);
  }, [searchQuery, dispatchRoutes, selectedEvent, constants]);

  // Function to check if it's the last site for the salt route
  const checkIfLastSaltRouteSite = async (saltDispatchSiteId) => {
    try {
      const result = await checkIfLastSiteOnSaltRoute(saltDispatchSiteId);
      if (result.is_last_site) console.log('Site detected as last site on salt route.');
      setIsLastSiteOnRoute(result.is_last_site);
    } catch (error) {
      console.error("Error checking if site is last on route:", error);
      setIsLastSiteOnRoute(false);
    }
  };

  // Function to check if it's the last site for the subcontractor
  const checkIfLastSnowRouteSite = async (snowDispatchSiteId) => {
    try {
      const result = await checkIfLastSiteOnSubcontractorSnowRoute(snowDispatchSiteId);
      if (result.is_last_site) console.log('Site detected as last site on Yeti subcontractor route.');
      setIsLastSiteOnRoute(result.is_last_site);
    } catch (error) {
      console.error("Error checking if site is last on route:", error);
      setIsLastSiteOnRoute(false);
    }
  };

  const handleEventChange = async (value) => {
    const selectedEventObj = events.find(event => event.value === value);
    setSearchQuery('');
    setSelectedEvent(selectedEventObj);
    await setCurrentEventRef.current(value);
  };

  const confirmRedispatch = () => {
    setRedispatchConfirmOpen(true);
  };

  const handleRedispatch = async () => {
    if (selectedDispatchRoute) {
      try {
        setRedispatchLoading(true);
        (selectedEvent.event_type === constants.event_type.snow)
          ? await reDispatchSnowRoute(selectedDispatchRoute.dispatch_route_id)
          : await reDispatchSaltRoute(selectedDispatchRoute.dispatch_route_id);
        messageApi.success('Redispatch route created; it can be be dispatched again from the Dispatch Routes Page!');
        const updatedRoutes = (selectedEvent.event_type === constants.event_type.snow)
          ? await fetchSnowDispatchRoutes(selectedEvent.event_id)
          : await fetchSaltDispatchRoutes(selectedEvent.event_id);
        setDispatchRoutes(updatedRoutes);
        setSelectedDispatchRoute(null);
      } catch (error) {
        messageApi.error('Failed to create redispatch route');
      } finally {
        setRedispatchConfirmOpen(false);
        setRedispatchLoading(false);
      }
    }
  };

  const evaluateArchive = async () => {
    if (!selectedDispatchRoute) return;
  
    try {
      const payload = selectedEvent?.event_type === constants?.event_type.snow
        ? { snow_dispatch_route_id: selectedDispatchRoute.dispatch_route_id }
        : { salt_dispatch_route_id: selectedDispatchRoute.dispatch_route_id };
        
      const response = await evaluateArchiveRoute(payload);
  
      setArchiveEvaluationData({
        subcontractors: response?.subcontractors || [],
        numSubcontractors: response?.subcontractors.length,
        sites: response?.sites || [],
        numSites: response?.sites.length,

      });
    } catch (error) {
      console.error('Error evaluating archive route:', error);
      messageApi.error('Failed to fetch archival evaluation data');
      setArchiveEvaluationData({ subcontractors: [], sites: [] });
    }
  };
  
  const confirmArchive = async () => {
    if (!selectedDispatchRoute) {
      messageApi.error("No route selected for archiving.");
      return;
    }
    try {
      await evaluateArchive();
      setArchiveConfirmOpen(true);
    } catch (error) {
      console.error("Error during archive preparation:", error);
      messageApi.error("Failed to prepare archive confirmation.");
    }
  };

  const handleArchive = async () => {
    if (selectedDispatchRoute) {
      try {
        setArchiveLoading(true);

        const values = await form.validateFields();
        const sendCancellationLetter = values.sendCancellationNotice || false;

        const payload = selectedEvent.event_type === constants.event_type.snow
          ? {
              snow_dispatch_route_id: selectedDispatchRoute.dispatch_route_id,
              send_cancellation_letter: sendCancellationLetter,
              user_email: user.email,
            }
          : {
              salt_dispatch_route_id: selectedDispatchRoute.dispatch_route_id,
              send_cancellation_letter: sendCancellationLetter,
              user_email: user.email,
            };
        
        const result = await archiveRoute(payload);
        displayResultNotifications(result, 'Route Cancellation', notificationApi);

        const updatedRoutes = (selectedEvent.event_type === constants.event_type.snow)
          ? await fetchSnowDispatchRoutes(selectedEvent.event_id)
          : await fetchSaltDispatchRoutes(selectedEvent.event_id);

        setDispatchRoutes(updatedRoutes);
        setSelectedDispatchRoute(null);
      } catch (error) {
        const errorMessage = error.message || 'Failed to archive the route';
        messageApi.error(errorMessage);
        console.error(error);
      } finally {
        setArchiveConfirmOpen(false);
        setArchiveLoading(false);
      }
    }
  };

  const updateAcknowledgement = async (record, value) => {
    try {
      if (selectedEvent.event_type === constants.event_type.snow) {
        await updateSnowSiteSubcontractorAcknowledgement(record.snow_dispatch_route_id, record.id, value);
      } else {
        await updateSaltRouteSubcontractorAcknowledgement(record.dispatch_route_id, value);
        const updatedRoutes = await fetchSaltDispatchRoutes(selectedEvent.event_id);
        setDispatchRoutes(updatedRoutes);
      }
      
      message.success('Subcontractor acknowledgement updated successfully');
    } catch (error) {
      message.error('Failed to update acknowledgement');
      console.error(error);
    }
  };

  const AcknowledgementEditor = ({ acknowledgement, record, onUpdate }) => {
    const [ackValue, setAckValue] = useState(acknowledgement);
  
    const handleUpdate = async (newValue) => {
      setAckValue(newValue); 
      await onUpdate(record, newValue);
    };
  
    const content = (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => handleUpdate(true)}>
          <Radio checked={ackValue === true} /> True
        </div>
        <div style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handleUpdate(false)}>
          <Radio checked={ackValue === false} /> False
        </div>
      </div>
    );
  
    return (
      <Popover
        content={content}
        trigger="click"
        overlayStyle={{ zIndex: 9999 }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            width: '100%',
            height: '100%',
          }}
        >
          {ackValue ? (
            <CheckCircleOutlined style={{ color: 'green' }} />
          ) : (
            <CloseCircleOutlined style={{ color: 'red' }} />
          )}
        </div>
      </Popover>
    );
  };

  const renderAcknowledgementColumn = (acknowledgement, record) => (
    <AcknowledgementEditor
      acknowledgement={acknowledgement}
      record={record}
      onUpdate={updateAcknowledgement}
    />
  );
  const showSiteCancelConfirmation = (site) => {
    if (selectedEvent?.event_type === constants?.event_type.snow) {
      setCancelSite(site);
      checkIfLastSnowRouteSite(site.id);
      setCancelModalOpen(true);
    } else if (selectedEvent?.event_type === constants?.event_type.salt) {
      setCancelSite(site);
      checkIfLastSaltRouteSite(site.id);
      setCancelModalOpen(true);
    } else {
      console.error("Unknown event type or no event selected.");
    }
  };

  const handleCancelDispatch = async (site, sendCancellationNotice) => {
    try {
      setCancelLoading(true);
  
      if (!site?.cancellation_notified_at) {
        if (selectedEvent.event_type === constants.event_type.snow) {
          await cancelSnowDispatchSite(site.id, sendCancellationNotice, user.email);
        } else {
          await cancelSaltDispatchSite(site.id, sendCancellationNotice, user.email);
        }
      } else {
        if (selectedEvent.event_type === constants.event_type.snow) {
          await cancelSnowDispatchSite(site.id, false, user.email);
        } else {
          await cancelSaltDispatchSite(site.id, false, user.email);
        }
      }
  
      messageApi.success('Dispatch site canceled successfully');
  
      const updatedRoutes = (selectedEvent.event_type === constants.event_type.snow)
        ? await fetchSnowDispatchRoutes(selectedEvent.event_id)
        : await fetchSaltDispatchRoutes(selectedEvent.event_id);
      setDispatchRoutes(updatedRoutes);
    } catch (error) {
      messageApi.error('Failed to cancel the dispatch site');
    } finally {
      setCancelLoading(false);
      setCancelModalOpen(false);
      form.resetFields();
    }
  };

  const handleCancelModalOk = () => {
    form
      .validateFields()
      .then(values => {
        handleCancelDispatch(cancelSite, values.sendCancellationNotice);
      })
      .catch(info => {
        console.error('Validate Failed:', info);
      });
  };

  const handleExportCsvClick = async () => {
    if (!selectedEvent) {
      messageApi.error("Please select an event to export CSV data.");
      return;
    }
    try {
      const csvData = await fetchEventHistoryCSV(selectedEvent.value);
      const url = window.URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }));
      const a = document.createElement('a');
      a.href = url;
      a.download = `Event_History_${selectedEvent.event_name}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      messageApi.error("Error downloading event history CSV");
    }
  };

  const exportMenuItems = [
    {
      key: 'csv',
      label: (
        <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <span>CSV</span>
          <DownloadOutlined />
        </span>
      ),
      onClick: handleExportCsvClick,
    },
    // {
    //   key: 'pdf',
    //   label: 'Export as PDF',
    //   onClick: handleExportPdfClick,
    // },
  ];  
  
  const getStatusIcon = (value) => {
    return value ? (
      <CheckCircleOutlined style={{ color: 'green' }} />
    ) : (
      <CloseCircleOutlined style={{ color: 'red' }} />
    );
  };

  const getBillingStatusIcon = (status) => {
    if (status === constants.status.pending) return <CloseCircleOutlined style={{ color: 'red' }} />;
    if (status === constants.status.awaiting) return <ClockCircleOutlined style={{ color: '#DAA520' }} />;  // (Goldenrod)
    if (status === constants.status.approved) return <CheckCircleOutlined style={{ color: 'green' }} />;
    return '-';
  };

  const formatDateTime = (dispatchedAt) => {
    if (!dispatchedAt) return '-';
    const date = new Date(dispatchedAt);
    const dispatchDate = date.toISOString().split('T')[0];
  
    const dispatchTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
    
    return (
      <span>
        {dispatchDate}
        <br />
        {dispatchTime}
      </span>
    );
  };

  const cancelIcon = (site) => (
    <Tooltip title="Cancel Dispatch">
      <CloseCircleOutlined
        style={{ color: 'lightgrey', cursor: 'pointer', fontSize: '18px' }}
        onMouseEnter={(e) => (e.currentTarget.style.color = 'red')}
        onMouseLeave={(e) => (e.currentTarget.style.color = 'lightgrey')}
        onClick={() => showSiteCancelConfirmation(site)}
      />
    </Tooltip>
  );

  // Define site details table columns
  const siteColumns = [
    {
      title: <span style={{ fontSize: '12px' }}>Site Name</span>,
      dataIndex: 'site_name',
      key: 'site_name',
      render: (value) => value || 'N/A',
    },
    {
      title: <span style={{ fontSize: '12px' }}>Location</span>,
      dataIndex: 'location',
      key: 'location',
      render: (value) => value || 'N/A',
    },
    (selectedEvent?.event_type === constants?.event_type.snow) && {
      title: <span style={{ fontSize: '12px' }}>Subcontractor</span>,
      dataIndex: 'subcontractor_name',
      key: 'subcontractor_name',
      render: (value) => value || 'N/A',
    },
    {
      title: <span style={{ fontSize: '12px' }}>Notified</span>,
      dataIndex: 'notified_at',
      key: 'notified_at',
      width: '8%',
      align: 'center',
      render: (value) => (
        <Tooltip title={value ? formatUtcDateTime(value) : 'Not Notified'}>
          {getStatusIcon(Boolean(value))}
        </Tooltip>
      ),
    },
    {
      title: <span style={{ fontSize: '12px' }}>Dispatched</span>,
      dataIndex: 'dispatched_at',
      key: 'dispatched_at',
      width: '8%',
      align: 'center',
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      render: (value) => (
        <Tooltip title={value ? formatUtcDateTime(value) : 'Not Dispatched'}>
          {getStatusIcon(Boolean(value))}
        </Tooltip>
      ),
    },
    (selectedEvent?.event_type === constants?.event_type.snow) && {
      title: <span style={{ fontSize: '12px' }}>Subcontractor Acknowledgement</span>,
      dataIndex: 'subcontractor_acknowledgement',
      key: 'subcontractor_acknowledgement',
      align: 'center',
      render: (acknowledgement, record) =>
        renderAcknowledgementColumn(acknowledgement, record),
    },
    {
      title: <span style={{ fontSize: '12px' }}>Serviced</span>,
      dataIndex: 'serviced_at',
      key: 'serviced_at',
      width: '8%',
      align: 'center',
      render: (value) => (
        <Tooltip title={value ? formatUtcDateTime(value) : 'Not Serviced'}>
          {getStatusIcon(Boolean(value))}
        </Tooltip>
      ),
    },
    {
      title: <span style={{ fontSize: '12px' }}>Invoice Status</span>,
      dataIndex: 'invoice_status',
      key: 'invoice_status',
      width: '8%',
      align: 'center',
      render: (status) => getBillingStatusIcon(status),
    },
    (selectedEvent?.event_type === constants?.event_type.snow) && {
      title: <span style={{ fontSize: '12px' }}>PO Status</span>,
      dataIndex: 'purchase_order_status',
      key: 'purchase_order_status',
      width: '8%',
      align: 'center',
      render: (status) => getBillingStatusIcon(status),
    },
    {
      title: <span style={{ fontSize: '12px' }}>Cancellation Letter Sent At</span>,
      dataIndex: 'cancellation_notified_at',
      key: 'cancellation_notified_at',
      align: 'center',
      width: '8%',
      render: (_, record) => 
        record.cancellation_notified_at ? (
          <Tooltip title={record.cancellation_notified_at ? formatUtcDateTime(record.cancellation_notified_at) : 'Service Cancellation Letter Not Sent'}>
            {getStatusIcon(Boolean(record.cancellation_notified_at))}
          </Tooltip>
        ) : null,
    },
    {
      title: <span style={{ fontSize: '12px' }}>Actions</span>,
      key: 'actions',
      align: 'center',
      width: '8%',
      render: (_, record) =>
        !record.serviced_at && !record.cancelled_at && cancelIcon(record), // Show cancel icon only if site is not serviced
    },
  ].filter(Boolean); // Filter out null/undefined columns

  // Expanded row rendering for dispatch route site details
  const expandedRowRenderSites = (dispatchRoute) => (
    <Table
      columns={siteColumns}
      dataSource={dispatchRoute.sites}
      pagination={false}
      rowKey="site_id"
      rowClassName={(record) => record.cancelled_at ? 'cancelled-row' : ''}
      tableLayout="fixed"
    />
  );

  // Define the main table columns with the required fields
  const routeColumns = [
    {
      title: 'Route Name',
      dataIndex: 'route_name',
      key: 'route_name',
      width: '30%',
      sorter: (a, b) => a.route_name.localeCompare(b.route_name),
    },
    {
      title: 'Dispatched',
      dataIndex: 'dispatched_at',
      key: 'dispatched_at',
      align: 'center',
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      onFilter: (value, record) => Boolean(record.dispatched_at) === value,
      render: (value) => (
        <Tooltip title={value ? formatUtcDateTime(value) : 'Not Dispatched'}>
          {getStatusIcon(Boolean(value))}
        </Tooltip>
      ),
      sorter: (a, b) => new Date(a.dispatched_at) - new Date(b.dispatched_at),
    },
    {
      title: 'Dispatched By',
      dataIndex: 'dispatched_by',
      key: 'dispatched_by',
      render: (value) => value || '-',
      sorter: (a, b) => (a.dispatched_by || '').localeCompare(b.dispatched_by || ''),
    },
    (selectedEvent?.event_type === constants?.event_type.salt) && {
      title: 'Subcontractor',
      dataIndex: 'subcontractor_name',
      key: 'subcontractor_name',
      render: (value) => value || 'Unassigned',
      sorter: (a, b) => (a.subcontractor_name || '').localeCompare(b.subcontractor_name || ''),
    },
    (selectedEvent?.event_type === constants?.event_type.salt) && {
      title: 'Subcontractor Acknowledgement',
      dataIndex: 'subcontractor_acknowledgement',
      key: 'subcontractor_acknowledgement',
      align: 'center',
      render: (acknowledgement, record) =>
        renderAcknowledgementColumn(acknowledgement, record),
    },
    (selectedEvent?.event_type === constants?.event_type.salt) && {
      title: 'Purchase Order Status',
      dataIndex: 'purchase_order_status',
      key: 'purchase_order_status',
      align: 'center',
      render: (status) => getBillingStatusIcon(status),
    },
  ].filter(Boolean); // Filter out null/undefined entries

  const [tableHeight, setTableHeight] = useState('100vh');
  const titleBoxRef = useRef(null);
  const eventDetailsRef = useRef(null);
  const actionBoxRef = useRef(null);
  const tableRef = useRef(null);

  useEffect(() => {
    const calculateTableHeight = () => {
      const headerHeight = 72;
      const titleBoxHeight = titleBoxRef.current ? titleBoxRef.current.offsetHeight : 32;
      const actionBoxHeight = actionBoxRef.current ? actionBoxRef.current.offsetHeight : 32;
      const eventDetailsHeight = eventDetailsRef.current ? eventDetailsRef.current.offsetHeight + 20: 74;
      const padding = 16;

      // Calculate height of the table header
      const tableHeaderHeight = tableRef.current ? tableRef.current.querySelector('.ant-table-thead').offsetHeight : 48;

      const totalHeightAroundTableRows =
        headerHeight +
        titleBoxHeight +
        actionBoxHeight +
        eventDetailsHeight +
        padding * 6 +
        tableHeaderHeight;

      setTableHeight(`calc(100vh - ${totalHeightAroundTableRows}px)`);
    };

    if (selectedEvent) {
        calculateTableHeight();
    }

    window.addEventListener('resize', calculateTableHeight);
    return () => window.removeEventListener('resize', calculateTableHeight);
  }, [selectedEvent]);

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#F57D20', colorLinkHover: '#F57D20', colorLink: '#F57D20' } }}>
      {contextHolder}
      {notifContextHolder}
      <Box display="flex">
        {/* Side Menu */}
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>
        
        {/* Main Content */}
        <Card
          style={{
            height: 'calc(100vh - 72px - 16px)',
            marginRight: '16px',
            overflow: 'hidden',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
          }}
        >

          {/* Header and Event Selection */}
          <Box ref={titleBoxRef} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <Typography variant="h5" component="div">
              Event History
            </Typography>
            <Select
              value={selectedEvent?.value}
              onChange={handleEventChange}
              placeholder="Select Event"
              options={events}
              style={{ width: '320px' }}
              optionFilterProp="label"
            />
          </Box>

          {/* Event Details */}
          {selectedEvent && (
            <Box
              ref={eventDetailsRef}
              marginTop="16px"
              padding="10px"
              border="1px solid #ddd"
              borderRadius="8px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              backgroundColor="#f9f9f9"
              boxShadow="0px 2px 8px rgba(0, 0, 0, 0.1)"
            >
              <Typography variant="h6" component="div" style={{ fontWeight: '500', color: '#333' }}>
                {selectedEvent.label}
              </Typography>
              <Typography variant="body1" component="div" style={{ color: '#666' }}>
                {getFormattedDate(selectedEvent.event_start)}
              </Typography>
            </Box>
          )}
          
          <Box ref={actionBoxRef} display="flex" justifyContent="space-between" alignItems="center" marginTop="16px">
            <Input
              type="text"
              placeholder="Search Routes, Sites, Subcontractors"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
              style={{ width: '300px' }}
              prefix={<SearchOutlined style={{ color: "#d9d9d9" }} fontSize="small" />}
            />

            <Box display="flex" gap="10px">
              <Button
                type="primary"
                disabled={!selectedDispatchRoute || !selectedDispatchRoute.dispatched_at || !selectedDispatchRoute.is_active}
                onClick={confirmRedispatch}
                style={{
                  backgroundColor:
                    selectedDispatchRoute && selectedDispatchRoute.dispatched_at && selectedDispatchRoute.is_active ? '#f57e1f' : '', // Orange if route is selected and dispatched
                  borderColor:
                    selectedDispatchRoute && selectedDispatchRoute.dispatched_at && selectedDispatchRoute.is_active ? '#f57e1f' : '', // Orange border if enabled
                  color: selectedDispatchRoute && selectedDispatchRoute.dispatched_at && selectedDispatchRoute.is_active ? 'white' : '', // White text when enabled
                  
                }}
              >
                Redispatch Selected Route <RedoOutlined />
              </Button>

              <Button
                type="primary"
                disabled={!selectedDispatchRoute || !selectedDispatchRoute.dispatched_at || !selectedDispatchRoute.is_active}
                onClick={confirmArchive}
                style={{
                  backgroundColor:
                    selectedDispatchRoute && selectedDispatchRoute.dispatched_at && selectedDispatchRoute.is_active ? '#f57e1f' : '', // Orange if route is selected and dispatched
                  borderColor:
                    selectedDispatchRoute && selectedDispatchRoute.dispatched_at && selectedDispatchRoute.is_active ? '#f57e1f' : '', // Orange border if enabled
                  color: selectedDispatchRoute && selectedDispatchRoute.dispatched_at && selectedDispatchRoute.is_active ? 'white' : '', // White text when enabled
                  
                }}
              >
                Archive Selected Route <StopOutlined />
              </Button>

              <Dropdown menu={{ items: exportMenuItems }} trigger={['click']} disabled={!selectedEvent}>
                <Button type="primary">
                  Export <ExportOutlined />
                </Button>
              </Dropdown>
            </Box>
          </Box>

          {/* Scrollable Table */}
          <Box ref={tableRef} sx={{ overflow: 'hidden', marginTop: '16px' }}>
            <Table
              columns={routeColumns}
              dataSource={filteredDispatchRoutes}
              rowKey="dispatch_route_id"
              loading={loading}
              expandable={{
                expandedRowRender: (record) => expandedRowRenderSites(record),
              }}
              pagination={false}
              onRow={(record) => ({
                onClick: () => {
                  if (selectedDispatchRoute?.dispatch_route_id === record.dispatch_route_id) {
                    setSelectedDispatchRoute(null);
                  } else {
                    setSelectedDispatchRoute(record);
                  }
                },
                className: selectedDispatchRoute?.dispatch_route_id === record.dispatch_route_id
                  ? 'custom-row-selected'
                  : '',
                style: {
                  backgroundColor: !record.is_active ? '#f0f0f0' : '',
                  color: !record.is_active ? '#b0b0b0' : '',
                },
              })}
              scroll={{ y: tableHeight }}
              sticky
            />
          </Box>

          <style>
            {`
              .cancelled-row {
                background-color: #ffcccc !important;
              }

              .cancelled-row:hover td {
                background-color: #ffcccc !important;
              }

              .custom-row-selected {
                background-color: orange !important;
              }

              .custom-row-selected:hover td {
                background-color: orange !important;
              }
            `}
          </style>
        </Card>
      </Box>

      <Modal
        open={cancelModalOpen}
        title="Cancel Site"
        onOk={handleCancelModalOk}
        onCancel={() => setCancelModalOpen(false)}
        okText="Yes, Cancel Site"
        cancelText="Close"
        confirmLoading={cancelLoading}
        okButtonProps={{
          disabled: cancelLoading
        }}
        cancelButtonProps={{
          disabled: cancelLoading
        }}
      >
      {cancelSite?.cancellation_notified_at ? (
        <p>Cancellation letter already sent to client on {formatUtcDateTime(cancelSite.cancellation_notified_at)}</p>
      ) : cancelSite?.notified_at ? (
        <Form form={form} layout="vertical">
          <Form.Item name="sendCancellationNotice" valuePropName="checked">
            <Checkbox className="custom-checkbox">Send Cancellation Letter</Checkbox>
          </Form.Item>
        </Form>
      ) : (
        <p>
          This site was not notified of service, no cancellation letter is required.
          <br />
        </p>
      )}
      {(selectedEvent?.event_type === constants?.event_type.snow) && isLastSiteOnRoute && (
        <p style={{ marginTop: '16px', color: 'red' }}>
          Note: Cancelling this site will archive the subcontractor's Yeti route as this is the last active site assigned to them.
        </p>
      )}
      {(selectedEvent?.event_type === constants?.event_type.salt) && isLastSiteOnRoute && (
        <p style={{ marginTop: '16px', color: 'red' }}>
          Note: Cancelling this site will archive this salt route as this is the last active site on the route.
        </p>
      )}
      <p>Are you sure you want to cancel this site?</p>
      </Modal>

      {/* Redispatch Confirmation Modal */}
      <Modal
        title="Confirm Redispatch"
        open={redispatchConfirmOpen}
        onOk={handleRedispatch}
        onCancel={() => setRedispatchConfirmOpen(false)}
        okText="Yes, Redispatch"
        cancelText="Cancel"
        confirmLoading={redispatchLoading}
        okButtonProps={{
          disabled: redispatchLoading,
        }}
        cancelButtonProps={{
          disabled: redispatchLoading,
        }}
      >
        <p>Are you sure you want to redispatch this route?</p>
      </Modal>

      {/* Archive Confirmation Modal */}
      <Modal
        title="Confirm Archive"
        open={archiveConfirmOpen}
        onOk={handleArchive}
        onCancel={() => setArchiveConfirmOpen(false)}
        okText="Yes, Archive"
        cancelText="Cancel"
        confirmLoading={archiveLoading}
        okButtonProps={{
          disabled: archiveLoading,
        }}
        cancelButtonProps={{
          disabled: archiveLoading,
        }}
      >
        <p>Are you sure you want to archive {selectedDispatchRoute?.route_name}?</p>

        {archiveEvaluationData.subcontractors.length > 0 && (
          <div>
            <h4>
              This action will archive {archiveEvaluationData.numSubcontractors} Yeti route{archiveEvaluationData.numSubcontractors > 1 ? 's' : ''} assigned to the following subcontractor{archiveEvaluationData.numSubcontractors > 1 ? 's' : ''}:
            </h4>
            <ul>
              {archiveEvaluationData.subcontractors.map((subcontractor, index) => (
                <li key={`subcontractor-${index}`}>{subcontractor}</li>
              ))}
            </ul>
          </div>
        )}

        {archiveEvaluationData.sites.length > 0 && (
          <div>
            <h4>The following site{archiveEvaluationData.numSites > 1 ? 's' : ''}/client{archiveEvaluationData.numSites > 1 ? 's' : ''} will be sent a cancellation letter if you choose to send it:</h4>
            <ul>
              {archiveEvaluationData.sites.map((site, index) => (
                <li key={`site-${index}`}>{site}</li>
              ))}
            </ul>
          </div>
        )}

        {archiveEvaluationData.sites.length > 0 && (
          <Form form={form} layout="vertical">
            <Form.Item name="sendCancellationNotice" valuePropName="checked">
              <Checkbox className="custom-checkbox">Send Cancellation Letter</Checkbox>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </ConfigProvider>
  );
};

export default EventHistoryPage;