import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Search as SearchIcon} from '@mui/icons-material';
import {
  ConfigProvider, Table, Button, Select, Card,
  Modal, Input, Tooltip,
  Descriptions, notification, message
} from 'antd';
import { SyncOutlined, CheckOutlined, CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import {
  fetchEvents,
  fetchInvoices,
  deleteInvoices,
  approveInvoice,
  fetchInvoicePDF,
  synchronizeInvoice,
  updateInvoiceStatus
} from '../api';
import SideMenu from '../components/SideMenu';
import InvoiceDetails from '../components/InvoiceDetails';
import { getFormattedDate } from '../utils/dateUtils';
import { useUser } from '../context/UserContext';
import { useConstants } from '../context/ConstantsContext';
import { StatusEditor, InvoiceList } from '../components/InvoiceAndPurchaseOrderComponents';


const ClientInvoices = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const constants = useConstants();
  const [constantsLoading, setConstantsLoading] = useState(true);
  const { user } = useUser();

  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('invoice_id');
  const [filteredStatus, setFilteredStatus] = useState([]);

  const [selected, setSelected] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [invoicePdfData, setInvoicePdfData] = useState(null);

  const [isApproving, setIsApproving] = useState(false);
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [selectedApproveInvoice, setSelectedApproveInvoice] = useState(null);
  const [approveAsDraft, setApproveAsDraft] = useState(false);

  const [loadingStates, setLoadingStates] = useState({});
  const [allSelectedKeys, setAllSelectedKeys] = useState(new Set());
  const [filteredRowKeys, setFilteredRowKeys] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [batchLoadingStates, setBatchLoadingStates] = useState({});
  const [batchMessages, setBatchMessages] = useState({});
  const [isBatchSyncModalOpen, setIsBatchSyncModalOpen] = useState(false);
  const [isBatchApproveModalOpen, setIsBatchApproveModalOpen] = useState(false);

  useEffect(() => {
    if (constants) {
      setConstantsLoading(false);
      setFilteredStatus([
        constants.status.pending,
        constants.status.incomplete,
        constants.status.awaiting,
        constants.status.approved,
      ]);
    }
  }, [constants]);

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const eventList = await fetchEvents();
        const eventOptions = eventList.map(event => ({
          label: `${event.event_name} - ${event.service_type}`,
          value: event.event_id,
          event_type: event.event_type,
          event_start: event.event_start,
        }));
        setEvents(eventOptions);
        if (eventOptions.length > 0) {
          setSelectedEventId(eventOptions[0].value);
          setSelectedEventDetails(eventOptions[0]);
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    loadEvents();
  }, [])

  useEffect(() => {
    if (selectedEventId !== null) {
      setLoading(true);
      const loadInvoices = async () => {
        try {
          const data = await fetchInvoices(selectedEventId);
          console.log(data);
          setInvoices(data);
        } catch (error) {
          console.error('Error fetching invoices:', error);
        } finally {
          setLoading(false);
        }
      };
      setAllSelectedKeys(new Set());
      setSelectedInvoices([]);
      loadInvoices();
    }
  }, [selectedEventId]);

  useEffect(() => {
    const filteredData = invoices.filter(invoice => {
      const clientName = invoice.client_name || 'N/A';
      const siteName = invoice.site_name || 'N/A';
  
      return (
        clientName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        siteName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    
    setFilteredInvoices(filteredData);
  }, [searchQuery, invoices]);

  useEffect(() => {
    const currentFilteredKeys = filteredInvoices.map(invoice => invoice.key);
    setFilteredRowKeys([...allSelectedKeys].filter(key => currentFilteredKeys.includes(key)));
  }, [filteredInvoices, allSelectedKeys]);

  const onSelectChange = (newSelectedKeys) => {
    const updatedSelectedKeys = new Set(allSelectedKeys);
    newSelectedKeys.forEach(key => updatedSelectedKeys.add(key));
    filteredRowKeys.forEach(key => {
      if (!newSelectedKeys.includes(key)) updatedSelectedKeys.delete(key);
    });
    setAllSelectedKeys(updatedSelectedKeys);
    setFilteredRowKeys(newSelectedKeys);

    const updatedSelectedInvoices = invoices.filter((invoice) => updatedSelectedKeys.has(invoice.key));
    setSelectedInvoices(updatedSelectedInvoices);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedInvoices = [...filteredInvoices].sort((a, b) => {
    const aValue = a[orderBy] !== null && a[orderBy] !== undefined ? a[orderBy] : '';
    const bValue = b[orderBy] !== null && b[orderBy] !== undefined ? b[orderBy] : '';
  
    if (orderBy === 'invoice_id') {
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    } else {
      return order === 'asc'
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    }
  });

  const handleEventChange = (value, options) => {
    setSelectedEventId(value);
    setSelectedEventDetails(options);
  };

  const handleDeleteClick = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteInvoices(selected);
      setInvoices((prevInvoices) =>
        prevInvoices.filter((invoice) => !selected.includes(invoice.invoice_id))
      );
      setSelected([]);
    } catch (error) {
      console.error('Error deleting invoices:', error);
    } finally {
      setDeleteOpen(false);
    }
  };

  const handleStatusUpdate = (invoiceId, newStatus) => {
    setInvoices((prevInvoices) =>
      prevInvoices.map((invoice) => (invoice.invoice_id === invoiceId ? { ...invoice, status: newStatus } : invoice))
    );
  };

  const handleDetailClick = async (invoice) => {
    try {
      setSelectedInvoice(invoice);
      setDetailOpen(true);

      if (invoice.pdf_complete) {
        const blob = await fetchInvoicePDF(invoice.invoice_id);
        setInvoicePdfData(blob);
      } else {
        setInvoicePdfData(null);
      }
    } catch (error) {
      console.error('Error fetching invoice details:', error);
    }
  };

  const handleDetailClose = () => {
    setSelectedInvoice(null);
    setDetailOpen(false);
    setInvoicePdfData(null);
  };

  const renderPDF = (pdfData) => {
    if (!pdfData) {
      return null;
    }
    const pdfUrl = URL.createObjectURL(pdfData);
    return (
      <object
        data={pdfUrl}
        type="application/pdf"
        width="100%"
        height="auto"
        style={{ marginTop: '20px', minHeight: '900px' }}
      >
        <p>Your browser does not support PDFs. Please download the PDF to view it: <a href={pdfUrl}>Download PDF</a>.</p>
      </object>
    );
  };
  
  const handleSyncInvoiceClick = async (invoiceId) => {
    setLoadingStates((prev) => ({ ...prev, [invoiceId]: true }));
    try {
      const messages = await synchronizeInvoice(invoiceId);
      const data = await fetchInvoices(selectedEventId);
      setInvoices(data);

      notification.open({
        message: 'Sync Results',
        description: (
          <ul>
            {messages.map((msg, index) => (
              <li key={index}>
                {msg}
              </li>
            ))}
          </ul>
        ),
        duration: 0,
      });
    } catch (error) {
      messageApi.error(error.message || 'Failed to update invoice amount & generate PDF');
    } finally {
      setLoadingStates((prev) => ({ ...prev, [invoiceId]: false }));
    }
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const confirmBatchSync = async () => {
    const syncPromises = selectedInvoices.map(async (invoice, index) => {
      await sleep(index *2000); // Delay by 2000ms per purchase order to stagger requests
      setBatchLoadingStates((prev) => ({ ...prev, [invoice.invoice_id]: true }));
      setBatchMessages((prev) => ({ ...prev, [invoice.invoice_id]: null }));
  
      try {
        const messages = await synchronizeInvoice(invoice.invoice_id);
        const successMessage = messages.join('; ');
        setBatchMessages((prev) => ({ ...prev, [invoice.invoice_id]: successMessage }));
      } catch (error) {
        const errorMessage = error.message || `Failed to sync Invoice-${invoice.invoice_id}`;
        setBatchMessages((prev) => ({ ...prev, [invoice.invoice_id]: errorMessage }));
      } finally {
        setBatchLoadingStates((prev) => ({ ...prev, [invoice.invoice_id]: false }));
      }
    });
  
    await Promise.all(syncPromises);
    const updatedInvoices = await fetchInvoices(selectedEventId);
    setInvoices(updatedInvoices);
  };


  const handleBatchSyncModalClose = async () => {
    setBatchLoadingStates({});
    setBatchMessages({});
    setIsBatchSyncModalOpen(false);
  };

  
  const handleViewPDFClick = async (invoiceId) => {
    const newTab = window.open(`/invoices/${invoiceId}/pdf`, '_blank');
    if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
      messageApi.error('Pop-up blocked! Please allow pop-ups for this site to view the PDF.');
    }
  };

  const handleApproveClick = (invoice) => {
    setSelectedApproveInvoice(invoice);
    setApproveDialogOpen(true);
  };

  const confirmApproveInvoice = async () => {
    setIsApproving(true);
    try {
      const response = await approveInvoice(selectedApproveInvoice.invoice_id, user.email);
      if (response.warning) {
        messageApi.warning(response.warning)
      } else if (response.message) {
        messageApi.success(response.message)
      } else {
        messageApi.success('Invoice Approved')
      }
      const data = await fetchInvoices(selectedEventId);
      setInvoices(data);
    } catch (error) {
      console.error('Failed to approve the invoice:', error);
      if (error.response && error.response.data && error.response.data.error) {
        messageApi.error(error.response.data.error);
      } else {
        messageApi.error('Failed to Approve Invoice');
      }
    } finally {
      setIsApproving(false);
      setSelectedApproveInvoice(null);
      setApproveAsDraft(false);
      setApproveDialogOpen(false);
    }
  };

  const confirmBatchApproval = async () => {
    const approvalPromises = selectedInvoices.map(async (invoice) => {
      setIsApproving(true);
      setBatchLoadingStates((prev) => ({ ...prev, [invoice.invoice_id]: true }));
      setBatchMessages((prev) => ({ ...prev, [invoice.invoice_id]: null }));
  
      try {
        const invoiceApproveResponse = await approveInvoice(invoice.invoice_id, user.email);
        const { message, errors, warning } = invoiceApproveResponse;
  
        let responseMessage = errors && errors.length > 0 ? 
        `${message}: ${errors.join('; ')}` : 
        message || 'Approved successfully';

        if (warning) {
          responseMessage = ` Warning: ${warning}`;
        }

        setBatchMessages((prev) => ({ ...prev, [invoice.invoice_id]: responseMessage }));
      } catch (error) {
        const errorMessage = error.response?.data?.error || `Failed to approve Invoice-${invoice.invoice_id}`;
        setBatchMessages((prev) => ({ ...prev, [invoice.invoice_id]: errorMessage }));
      } finally {
        setIsApproving(false);
        setBatchLoadingStates((prev) => ({ ...prev, [invoice.invoice_id]: false }));
      }
    });
  
    await Promise.all(approvalPromises);
    const updatedInvoices = await fetchInvoices(selectedEventId);
    setInvoices(updatedInvoices);
  };


  const handleBatchApproveModalClose = async () => {
    setBatchLoadingStates({});
    setBatchMessages({});
    setIsBatchApproveModalOpen(false);
  };
  

  const rowSelection = {
    selectedRowKeys: filteredRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "invoice_id",
      key: "invoice_id",
      sorter: true,
      sortOrder: orderBy === 'invoice_id' && order,
      onHeaderCell: () => ({ onClick: () => handleRequestSort('invoice_id') }),
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      sorter: true,
      sortOrder: orderBy === 'client_name' && order,
      onHeaderCell: () => ({ onClick: () => handleRequestSort('client_name') }),
    },
    {
      title: 'Site Name',
      dataIndex: 'site_name',
      key: 'site_name',
      sorter: true,
      sortOrder: orderBy === 'site_name' && order,
      onHeaderCell: () => ({ onClick: () => handleRequestSort('site_name') }),
    },
    // {
    //   title: 'Amount ($ - no GST)',
    //   key: 'total_amount_sum',
    //   render: (record) => record.total_amount_sum.toFixed(2)
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      filters: [
        { text: 'Pending', value: constants?.status.pending },
        { text: 'Incomplete', value: constants?.status.incomplete },
        { text: 'Awaiting Approval', value: constants?.status.awaiting },
        { text: 'Approved', value: constants?.status.approved },
        { text: 'Cancelled', value: constants?.status.cancelled },
      ],
      filteredValue: filteredStatus,
      onFilter: (value, record) => {
        return record.status === value;
      },
      render: (status, record) => (
        <StatusEditor 
          status={status} 
          record={record} 
          statusUpdateFunction={updateInvoiceStatus} 
          statusType="Invoice"
          handleStatusUpdate={handleStatusUpdate}
          messageApi={messageApi}
        />
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record) => (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px'
          }}
        >
          <Tooltip title="Invoice Details">
            <IconButton onClick={() => handleDetailClick(record)}>
              <InfoIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Recalculate and generate the latest PDF for this invoice">
            <SyncOutlined
              onClick={() => handleSyncInvoiceClick(record.invoice_id)}
              style={{ fontSize: '18px', color: record.status === constants.status.approved ? '#d9d9d9' : '#1890ff', cursor: record.status === constants.status.approved ? 'not-allowed' : 'pointer' }}
              spin={loadingStates[record.invoice_id]}
              disabled={record.status === constants.status.approved || loadingStates[record.invoice_id]}
            />
          </Tooltip>

          <Tooltip title="View Invoice PDF">
            <IconButton onClick={() => handleViewPDFClick(record.invoice_id)} type="default" disabled={!record.pdf_complete}>
              <PictureAsPdfIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="This invoice is out of date with its service histories">
            <WarningOutlined
              style={{
                color: 'orange',
                fontSize: '18px',
                visibility: (!record.up_to_date && record.pdf_complete) ? 'visible' : 'hidden'
              }}
            />
          </Tooltip>

          <Tooltip
            title={
              record.status === constants.status.approved
                ? "Invoice Approved"
                : record.status === constants.status.pending
                ? "Please sync invoice before approval"
                : "Approve Invoice"
            }
            placement="top"
          >
            <span>
              {record.status !== constants.status.pending ? (
                <IconButton
                  onClick={() => handleApproveClick(record)}
                  type="primary"
                  disabled={record.status !== constants.status.awaiting}
                  style={{ cursor: record.status === constants.status.awaiting ? 'pointer' : 'not-allowed' }}
                >
                  {record.status === constants.status.awaiting ? (
                    <CheckCircleOutlined style={{ color: '#F57D20' }} />
                  ) : (
                    <CheckCircleOutlined style={{ color: 'green' }} />
                  )}
                </IconButton>
              ) : (
                <IconButton disabled>
                  <CheckCircleOutlined style={{ color: 'grey' }} />
                </IconButton>
              )}
            </span>
          </Tooltip>
        </Box>
      ),
      align: 'center',
    },
  ];

  const [tableHeight, setTableHeight] = useState('100vh');
  const titleBoxRef = useRef(null);
  const eventDetailsRef = useRef(null);
  const actionBoxRef = useRef(null);
  const tableRef = useRef(null);

  useEffect(() => {
    const calculateTableHeight = () => {
      const headerHeight = 72;
      const titleBoxHeight = titleBoxRef.current ? titleBoxRef.current.offsetHeight : 32;
      const actionBoxHeight = actionBoxRef.current ? actionBoxRef.current.offsetHeight : 32;
      const eventDetailsHeight = eventDetailsRef.current ? eventDetailsRef.current.offsetHeight + 20: 74;
      const padding = 16;

      // Calculate height of the table header
      const tableHeaderHeight = tableRef.current ? tableRef.current.querySelector('.ant-table-thead').offsetHeight : 48;

      const totalHeightAroundTableRows =
        headerHeight +
        titleBoxHeight +
        actionBoxHeight +
        eventDetailsHeight +
        padding * 6 +
        tableHeaderHeight;
      console.log(totalHeightAroundTableRows);
      setTableHeight(`calc(100vh - ${totalHeightAroundTableRows}px)`);
    };

    if (selectedEventDetails) {
        calculateTableHeight();
    }

    window.addEventListener('resize', calculateTableHeight);
    return () => window.removeEventListener('resize', calculateTableHeight);
  }, [selectedEventDetails]);

  if (constantsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#F57D20', colorLinkHover: '#F57D20', colorLink: '#F57D20' } }}>
      {contextHolder}
      <Box display="flex">
        {/* Side Menu */}
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>
        
        {/* Main Content */}
        <Card
          style={{
            height: 'calc(100vh - 72px - 16px)',
            marginRight: '16px',
            overflow: 'hidden',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
          }}
        >

          {/* Header and Event Selection */}
          <Box ref={titleBoxRef} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <Typography variant="h5" component="div">
              Client Invoices
            </Typography>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Select
                value={selectedEventId}
                onChange={handleEventChange}
                showSearch
                placeholder="Select Event"
                options={events}
                style={{ width: '320px' }}
                optionFilterProp="label"
              />
            </Box>
          </Box>

          {/* Event Details */}
          {selectedEventDetails && (
            <Box
              ref={eventDetailsRef}
              marginTop="16px"
              padding="10px"
              border="1px solid #ddd"
              borderRadius="8px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              backgroundColor="#f9f9f9"
              boxShadow="0px 2px 8px rgba(0, 0, 0, 0.1)"
            >
              <Typography variant="h6" component="div" style={{ fontWeight: '500', color: '#333' }}>
                {selectedEventDetails.label}
              </Typography>
              <Typography variant="body1" component="div" style={{ color: '#666' }}>
                {getFormattedDate(selectedEventDetails.event_start)}
              </Typography>
            </Box>
          )}

          {/* Search and Action Buttons */}
          <Box ref={actionBoxRef} display="flex" justifyContent="space-between" alignItems="center" marginTop="16px">
            <Input
              type="text"
              placeholder="Search Invoices"
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ width: '250px' }}
              prefix={<SearchIcon style={{ color: "#d9d9d9" }} fontSize="small" />}
            />
            
            <Box display="flex" gap="10px">
              <Tooltip title="Delete Selected Invoices">
                <Button
                  style={{ color: '#FF6961' }}
                  onClick={handleDeleteClick}
                  disabled={allSelectedKeys.length === 0}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Sync Selected Invoices">
                <Button onClick={() => setIsBatchSyncModalOpen(true)}>
                  Sync <SyncOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="Approve Selected Invoices">
                <Button type="primary" onClick={() => setIsBatchApproveModalOpen(true)}>
                  Approve <CheckOutlined />
                </Button>
              </Tooltip>
            </Box>
          </Box>

          {/* Scrollable Table */}
          <Box ref={tableRef} sx={{ overflow: 'hidden', marginTop: '16px' }}>
            <Table
              columns={columns}
              dataSource={sortedInvoices}
              rowKey={(record) => record.invoice_id}
              rowSelection={rowSelection}
              pagination={false}
              scroll={{
                x: 'max-content',
                y: tableHeight,
              }}
              onChange={(pagination, filters) => {
                if (filters.status) {
                  setFilteredStatus(filters.status);
                }
              }}
              sticky
            />
          </Box>
        </Card>
      </Box>

      <Modal 
        open={deleteOpen} 
        onClose={handleDeleteClose} 
        title="Confirm Delete" 
        centered 
        onCancel={handleDeleteClose} 
        footer={[
          <Button key='cancel-invoice-deletion' onClick={handleDeleteClose}>Close</Button>, 
          <Button key='confirm-invoice-deletion' onClick={handleConfirmDelete} type="primary">Delete</Button>
        ]}>
        Are you sure you want to delete the selected invoices? This action cannot be undone.
      </Modal>

      <Modal
        open={detailOpen}
        onCancel={handleDetailClose}
        footer={null}
        width='60%'
      >
        <InvoiceDetails invoice={selectedInvoice} />
        {renderPDF(invoicePdfData)}
      </Modal>

      {/* Approve Confirmation Modal */}
      <Modal
        title="Confirm Approval"
        open={approveDialogOpen}
        onOk={confirmApproveInvoice}
        onCancel={() => setApproveDialogOpen(false)}
        confirmLoading={isApproving}
        footer={[
          // <Checkbox
          //   key="draft-checkbox"
          //   onChange={(e) => setApproveAsDraft(e.target.checked)}
          //   style={{ float: 'left', lineHeight: '32px', marginRight: 'auto' }}
          // >
          //   Approve as Draft
          // </Checkbox>,
          <Button key="cancel" onClick={() => setApproveDialogOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={isApproving} onClick={confirmApproveInvoice}>
            Approve
          </Button>,
        ]}
      >
        <Descriptions
          column={1}
          size="small"
          bordered
        >
          <Descriptions.Item label="Event Name">{selectedApproveInvoice?.event_name}</Descriptions.Item>
          <Descriptions.Item label="Event Date">{getFormattedDate(selectedApproveInvoice?.event_date)}</Descriptions.Item>
          <Descriptions.Item label="Client">{selectedApproveInvoice?.client_name || 'Undefined'}</Descriptions.Item>
          <Descriptions.Item label="Site">{selectedApproveInvoice?.site_name || 'Undefined'}</Descriptions.Item>
          <Descriptions.Item label="Service Type">{selectedApproveInvoice?.service_type || 'Undefined'}</Descriptions.Item>
          <Descriptions.Item label="Contract Type">{selectedApproveInvoice?.contract_type || 'Undefined'}</Descriptions.Item>
          {/* <Descriptions.Item label="Total Amount">{selectedApproveInvoice?.total_amount_sum ? `$${selectedApproveInvoice.total_amount_sum.toFixed(2)}` : 'N/A'}</Descriptions.Item> */}
        </Descriptions>
      </Modal>

      {/* Batch Sync Confirmation Modal */}
      <Modal
        title="Confirm Batch Sync"
        open={isBatchSyncModalOpen}
        okText="Sync"
        onOk={confirmBatchSync}
        onCancel={handleBatchSyncModalClose}
        width="60%"
      >
        <p>You are about to sync the following invoices:</p>
        <InvoiceList
          invoices={selectedInvoices}
          batchMessages={batchMessages}
          batchLoadingStates={batchLoadingStates}
        />
        <p>This will update their service histories, recalculate amounts, and generate PDFs.</p>
      </Modal>

      {/* Batch Approve Confirmation Modal */}
      <Modal
        title="Confirm Batch Approve"
        open={isBatchApproveModalOpen}
        onCancel={handleBatchApproveModalClose}
        width="60%"
        footer={[
          <Button key="cancel" onClick={handleBatchApproveModalClose}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={isApproving} onClick={confirmBatchApproval}>
            Approve
          </Button>,
        ]}
      >
        <p>You are about to approve the following invoices:</p>
        <InvoiceList
          invoices={selectedInvoices}
          batchMessages={batchMessages}
          batchLoadingStates={batchLoadingStates}
        />
      </Modal>
    </ConfigProvider>
  );
};

export default ClientInvoices;