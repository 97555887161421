// frontend/src/components/InvoiceAndPurchaseOrderComponents.js
import React, { useState, useEffect, useRef } from 'react';
import { Tag, Popover, Radio, Collapse, Descriptions, Spin, Input, Tooltip } from 'antd';
import { LoadingOutlined, CheckOutlined, CloseOutlined, RollbackOutlined, EditOutlined, EditFilled } from '@ant-design/icons';
import { useConstants } from '../context/ConstantsContext';
import { getFormattedDate } from '../utils/dateUtils';
import { formatCurrency } from '../utils/stringUtils';

const { Panel } = Collapse;

const StatusEditor = ({ status, record, statusUpdateFunction, statusType, handleStatusUpdate, messageApi }) => {
  const constants = useConstants();

  const onStatusChange = async (newStatus) => {
    try {
      await statusUpdateFunction(record.key, newStatus);
      handleStatusUpdate(record.key, newStatus);
      messageApi.success(`${statusType} Status Updated`);
    } catch (error) {
      messageApi.error(`Failed to update ${statusType} status`);
    }
  };

  const content = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ cursor: 'pointer', marginBottom: '8px' }} onClick={() => onStatusChange(constants.status.pending)}>
        <Radio checked={status === constants.status.pending} /> Pending
      </div>
      <div style={{ cursor: 'pointer', marginBottom: '8px' }} onClick={() => onStatusChange(constants.status.incomplete)}>
        <Radio checked={status === constants.status.incomplete} /> Incomplete
      </div>
      <div style={{ cursor: 'pointer', marginBottom: '8px' }} onClick={() => onStatusChange(constants.status.awaiting)}>
        <Radio checked={status === constants.status.awaiting} /> Awaiting Approval
      </div>
      <div style={{ cursor: 'pointer' }} onClick={() => onStatusChange(constants.status.cancelled)}>
        <Radio checked={status === constants.status.cancelled} /> Cancelled
      </div>
    </div>
  );

  const colorMap = {
    [constants.status.approved]: {
      text: 'green',
      border: 'green',
      background: 'rgba(0, 128, 0, 0.1)',
    },
    [constants.status.incomplete]: {
      text: '#DAA520',
      border: '#DAA520',
      background: 'rgba(218, 165, 32, 0.1)',
    },
    [constants.status.awaiting]: {
      text: 'blue',
      border: 'blue',
      background: 'rgba(0, 0, 255, 0.1)',
    },
    [constants.status.pending]: {
      text: 'red',
      border: 'red',
      background: 'rgba(255, 0, 0, 0.1)',
    },
    [constants.status.cancelled]: {
      text: 'grey',
      border: 'grey',
      background: 'rgba(128, 128, 128, 0.1)',
    },
  };

  const currentColor = colorMap[status] || {};

  return (
    record.status === constants.status.approved ? (
      <Tag
        style={{
          color: currentColor.text,
          borderColor: currentColor.border,
          backgroundColor: currentColor.background,
        }}
      >
        {status}
      </Tag>
    ) : (
      <Popover
        content={content}
        trigger="click"
        overlayStyle={{ zIndex: 9999 }}
        onClick={(e) => e.stopPropagation()}
      >
        <Tag
          style={{
            color: currentColor.text,
            borderColor: currentColor.border,
            backgroundColor: currentColor.background,
            cursor: 'pointer',
          }}
        >
          {status}
        </Tag>
      </Popover>
    )
  );
};

const AmountEditor = ({ record, onAmountChange, constants, isEditIconVisible }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingValue, setEditingValue] = useState(record.custom_amount ?? '');
  const editorRef = useRef(null);

  const isCustomAmount = record.custom_amount !== null;
  const isEditable = (record.event_type === constants.event_type.salt) && (record.status !== constants.status.approved); 

  useEffect(() => {
    if (isEditing) {
      setEditingValue(record.custom_amount ?? '');
    }
  }, [isEditing, record.custom_amount]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editorRef.current && !editorRef.current.contains(event.target)) {
        setIsEditing(false);
      }
    };

    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing]);

  const handleSave = async () => {
    const newValue = editingValue.trim() === '' ? null : parseFloat(editingValue);
    await onAmountChange(record.key, newValue);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditingValue(record.custom_amount ?? '');
    setIsEditing(false);
  };

  const handleReset = async () => {
    await onAmountChange(record.key, null);
    setIsEditing(false);
  };

  return (
    <div ref={editorRef} style={{ display: 'flex', alignItems: 'center' }}>
      {isEditing ? (
        <>
          <Input
            type="number"
            value={editingValue}
            onChange={(e) => setEditingValue(e.target.value)}
            style={{
              width: '100px',
              marginRight: '8px',
              backgroundColor: isCustomAmount ? 'white' : '#f5f5f5',
            }}
            placeholder={formatCurrency(
              record.custom_amount ?? record.total_amount_sum,
              record.country,
              constants
            )}
            autoFocus
          />
          <CloseOutlined
            onClick={handleCancel}
            style={{ color: 'red', cursor: 'pointer', marginRight: '8px' }}
          />
          <Tooltip title="Reset to default amount">
            <RollbackOutlined
              onClick={handleReset}
              style={{ color: 'blue', cursor: 'pointer', marginRight: '8px' }}
            />
          </Tooltip>
          <CheckOutlined
            onClick={handleSave}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </>
      ) : (
        <>
          {isEditable ? (
            <Tooltip title={isCustomAmount ? "Click to edit custom amount" : "Click to set custom amount"}>
              <span
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  color: isCustomAmount ? 'black' : 'grey',
                }}
                onClick={() => setIsEditing(true)}
              >
                {formatCurrency(record.custom_amount ?? record.total_amount_sum, record.country, constants)}
                {isCustomAmount ? (
                  <EditFilled style={{ marginLeft: '8px', fontSize: '0.9em', color: '#F57D20' }} />
                ) : (
                  isEditIconVisible && (
                    <EditOutlined style={{ marginLeft: '8px', fontSize: '0.9em', color: 'gray' }} />
                  )
                )}
              </span>
            </Tooltip>
          ) : (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {formatCurrency(record.total_amount_sum, record.country, constants)}
            </span>
          )}
        </>
      )}
    </div>
  );
};


const PurchaseOrderList = ({ purchaseOrders, batchMessages, batchLoadingStates, constants }) => {
  return (
    <Collapse accordion>
      {purchaseOrders.map((po) => (
        <Panel
          header={
            batchMessages[po.po_id] ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ fontWeight: 'bold' }}>{po.subcontractor_name}</span>
                <span style={{ color: batchMessages[po.po_id].includes('Failed' || 'failed') ? 'red' : 'green' }}>
                {batchMessages[po.po_id]}
                </span>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ width: '25%', textAlign: 'left' }}>{po.subcontractor_name || 'Subcontractor Not Defined'}</span>
                <span style={{ width: '25%', textAlign: 'left' }}>{po.route_name || 'Route Not Defined'}</span>
                <span style={{ width: '15%', textAlign: 'left' }}>
                  {formatCurrency(po.custom_amount ?? po.total_amount_sum, po.country, constants)}
                  {po.custom_amount && <EditFilled style={{ marginLeft: '8px', fontSize: '0.9em', color: '#F57D20' }} />}
                </span>
                {po.event_type === constants.event_type.snow && <span style={{ width: '25%', textAlign: 'left' }}>{po.site_name || 'Site Unavailable'}</span>}
                {batchLoadingStates[po.po_id] && (
                  <Spin
                    indicator={<LoadingOutlined spin />}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  />
                )}
              </div>
            )
          }
          key={po.po_id}
        >
          <Descriptions bordered column={1} size="small">
            <Descriptions.Item label="Event Name">{po.event_name}</Descriptions.Item>
            <Descriptions.Item label="Event Date">{getFormattedDate(po.event_date)}</Descriptions.Item>
            <Descriptions.Item label="Subcontractor">{po.subcontractor_name}</Descriptions.Item>
            <Descriptions.Item label="Site">{po.site_name || 'Site Not Defined'}</Descriptions.Item>
            <Descriptions.Item label="Route">{po.route_name || 'Not Defined'}</Descriptions.Item>
            <Descriptions.Item label="Service Type">{po.service_type}</Descriptions.Item>
            <Descriptions.Item label="Contract Type">{po.contract_type || 'Contract Type Not Defined'}</Descriptions.Item>
            { po.country === constants.country.canada &&
              <>
                <Descriptions.Item label="Net Total">{formatCurrency(po.custom_amount ?? po.total_amount_sum, po.country, constants)}</Descriptions.Item>
                <Descriptions.Item label={`GST ${constants.taxes.cad_tax_rate * 100}%`}>{formatCurrency((po.custom_amount ?? po.total_amount_sum) * constants.taxes.cad_tax_rate, po.country, constants)}</Descriptions.Item>
                <Descriptions.Item label="Gross Total">
                  {formatCurrency((po.custom_amount ?? po.total_amount_sum) * (1 + constants.taxes.cad_tax_rate), po.country, constants)}
                  {po.custom_amount && <EditFilled style={{ marginLeft: '8px', fontSize: '0.9em', color: '#F57D20' }} />}
                </Descriptions.Item>
              </>
            }
            { po.country === constants.country.usa &&
              <Descriptions.Item label="Gross Total">
                {formatCurrency(po.custom_amount ?? po.total_amount_sum, po.country, constants)}
                {po.custom_amount && <EditFilled style={{ marginLeft: '8px', fontSize: '0.9em', color: '#F57D20' }} />}
              </Descriptions.Item>
            }
            <Descriptions.Item label="Status">{po.status}</Descriptions.Item>
          </Descriptions>
        </Panel>
      ))}
    </Collapse>
  );
};


const InvoiceList = ({ invoices, batchMessages, batchLoadingStates }) => {
  return (
    <Collapse accordion>
      {invoices.map((invoice) => (
        <Panel
          header={
            batchMessages[invoice.invoice_id] ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ fontWeight: 'bold' }}>Invoice-{invoice.invoice_id}</span>
                <span style={{ color: batchMessages[invoice.invoice_id].includes('Failed') ? 'red' : 'green' }}>
                {batchMessages[invoice.invoice_id]}
                </span>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ width: '15%', fontWeight: 'bold' }}>Invoice-{invoice.invoice_id}</span>
                {/* <span style={{ width: '20%', textAlign: 'left' }}>{invoice.total_amount_sum ? `$${invoice.total_amount_sum.toFixed(2)}` : 'N/A'}</span> */}
                <span style={{ width: '30%', textAlign: 'left' }}>{invoice.client_name || 'Client Not Defined'}</span>
                <span style={{ width: '30%', textAlign: 'left' }}>{invoice.site_name || 'Site Not Defined'}</span>
                {batchLoadingStates[invoice.invoice_id] && (
                  <Spin
                    indicator={<LoadingOutlined spin />}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  />
                )}
              </div>
            )
          }
          key={invoice.invoice_id}
        >
          <Descriptions bordered column={1} size="small">
            <Descriptions.Item label="Event Name">{invoice.event_name}</Descriptions.Item>
            <Descriptions.Item label="Event Date">{getFormattedDate(invoice.event_date)}</Descriptions.Item>
            <Descriptions.Item label="Client">{invoice.client_nam || 'Client Not Defined'}</Descriptions.Item>
            <Descriptions.Item label="Site">{invoice.site_name || 'Site Not Defined'}</Descriptions.Item>
            <Descriptions.Item label="Service Type">{invoice.service_type}</Descriptions.Item>
            <Descriptions.Item label="Contract Type">{invoice.contract_type || 'Contract Type Not Defined'}</Descriptions.Item>
            {/* <Descriptions.Item label="Total Amount">{invoice.total_amount_sum ? `$${invoice.total_amount_sum.toFixed(2)}` : 'N/A'}</Descriptions.Item> */}
            <Descriptions.Item label="Status">{invoice.status}</Descriptions.Item>
          </Descriptions>
        </Panel>
      ))}
    </Collapse>
  );
};

export { StatusEditor, AmountEditor, PurchaseOrderList, InvoiceList };