// frontend/src/utils/notificationUtils.js

export const displayResultNotifications = (result, operation, notificationApi) => {
    if (!result || !result.status) return;
  
    const successMessages = result.successes || [];
    const errorMessages = result.errors || [];
  
    let notificationType = 'info';
    let messageTitle = 'Status';
  
    switch (result.status) {
      case 'success':
        notificationType = 'success';
        messageTitle = `${operation} Successful`;
        break;
      case 'partial success':
        notificationType = 'warning';
        messageTitle = `${operation} Partially Successful`;
        break;
      case 'failure':
        notificationType = 'error';
        messageTitle = `${operation} Failed`;
        break;
      default:
        notificationType = 'info';
    }
  
    notificationApi[notificationType]({
      message: messageTitle,
      description: (
        <div>
          {successMessages.length > 0 && (
            <>
              <div>
                <strong>Successes:</strong>
                <ul>
                  {successMessages.map((msg, index) => (
                    <li key={`success-${index}`}>{msg}</li>
                  ))}
                </ul>
              </div>
              {errorMessages.length > 0 && <hr />}
            </>
          )}
          {errorMessages.length > 0 && (
            <div>
              <strong>Errors:</strong>
              <ul>
                {errorMessages.map((msg, index) => (
                  <li key={`error-${index}`}>{msg}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ),
      duration: 0,
    });
  };