import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ConfigProvider, Tag, Modal, Input, message, notification, Spin, Radio, Divider, Typography as AntTypography } from 'antd';
import { Box, Typography } from '@mui/material';
import SnowIcon from '@mui/icons-material/AcUnit';
import CloudIcon from '@mui/icons-material/Cloud';
import RainIcon from '@mui/icons-material/WaterDrop';
import SunIcon from '@mui/icons-material/WbSunny';
import AntiIcingIcon from '@mui/icons-material/DeviceThermostat';
import DeIcingIcon from '@mui/icons-material/SevereCold';

import { fetchEvents, fetchWeatherStations, fetchAssociatedRouteSiteStations, generateEvent } from '../api';
import SideMenu from '../components/SideMenu';
import { useUser } from '../context/UserContext';
import { useConstants } from '../context/ConstantsContext';
import { useCurrentEvent } from '../context/EventContext';

const tagsData = ['All', 'USA', 'Canada', 'Snow in Forecast'];
const { Title } = AntTypography;

const DashboardPage = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const constants = useConstants();
  const [constantsLoading, setConstantsLoading] = useState(true);
  const [selectedTags, setSelectedTags] = useState(['All']);
  const [selectedStations, setSelectedStations] = useState([]);
  const [additionalStations, setAdditionalStations] = useState([]);
  const [weatherStations, setWeatherStations] = useState([]);
  const [events, setEvents] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [eventType, setEventType] = useState(null);
  const [eventName, setEventName] = useState('');
  const [serviceType, setServiceType] = useState(null);
  const [forecastTime, setForecastTime] = useState('');
  const { currentEvent, setCurrentEvent } = useCurrentEvent();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (constants) {
      setConstantsLoading(false);
    }
  }, [constants]);

  const loadActiveEvents = useCallback(async () => {
      try {
        const events = await fetchEvents({ active: 'true' });
        setEvents(events);
      } catch (error) {
        messageApi.warning('No active events found.');
      }
  }, [messageApi]);

  const loadWeatherStations = useCallback(async () => {
    try {
      let country = null;
      let snowForcasted = null;

      const selectedCountries = ['USA', 'Canada'].filter(tag => selectedTags.includes(tag));
      if (selectedCountries.length === 1) {
        country = selectedCountries[0];
      }

      if (selectedTags.includes('Snow in Forecast')) {
        snowForcasted = true;
      } else if (selectedTags.length > 0 && !selectedTags.includes('All')) {
        snowForcasted = false;
      }

      const stations = await fetchWeatherStations({ country: country, snowForcasted: snowForcasted });

      stations.sort((a, b) => {
        let cmp = a.country.localeCompare(b.country);
        if (cmp !== 0) return cmp;

        cmp = a.province_state.localeCompare(b.province_state);
        if (cmp !== 0) return cmp;

        return a.station_name.localeCompare(b.station_name);
      });

      setWeatherStations(stations);
    } catch (error) {
      messageApi.error('Failed to fetch weather stations.');
    }
  }, [messageApi, selectedTags]);

  // Fetch weather stations from the API when component is mounted or selected tags change
  useEffect(() => {
    loadActiveEvents();
    loadWeatherStations();
  }, [loadActiveEvents, loadWeatherStations]);

  // Handle tag changes
  const handleTagChange = (tag, checked) => {
    if (tag === 'All') {
      setSelectedTags(checked ? ['All'] : []);
    } else {
      const nextSelectedTags = checked
        ? [...selectedTags.filter((t) => t !== 'All'), tag]
        : selectedTags.filter((t) => t !== tag);
      setSelectedTags(nextSelectedTags.length > 0 ? nextSelectedTags : ['All']);
    }
  };

  // Handle station selection
  const handleStationSelect = (station) => {
    setSelectedStations((prevSelectedStations) => {
      const stationExists = prevSelectedStations.some(
        (s) => s.weather_station_id === station.weather_station_id
      );

      if (stationExists) {
        // Remove the station if it exists in the selected stations
        return prevSelectedStations.filter(
          (s) => s.weather_station_id !== station.weather_station_id
        );
      } else {
        // Add the station with the appropriate default pdf_source
        return [
          ...prevSelectedStations,
          {
            ...station,
            pdf_source:
              station.country === constants.country.canada
                ? constants.forecast_source.ec
                : constants.forecast_source.nws,
          },
        ];
      }
    });
  };

  // Show modal to generate event
  const handleGenerateEvent = async () => {
    try {
      setLoading(true);
      const stationIds = selectedStations.map(station => station.weather_station_id);
      const additionalStationsData = await fetchAssociatedRouteSiteStations(stationIds);

      setAdditionalStations(additionalStationsData.map(station => ({
        ...station,
        pdf_source: station.country === constants.country.canada
          ? constants.forecast_source.ec
          : constants.forecast_source.nws
      })));

      setIsModalVisible(true);
    } catch (error) {
      messageApi.error('Failed to fetch additional weather stations associated with routes.');
    } finally {
      setLoading(false);
    }
  };

  const handleForecastSourceChange = (stationId, source, isAdditional = false) => {
    const updateStations = (stations) =>
      stations.map((station) =>
        station.weather_station_id === stationId ? { ...station, pdf_source: source } : station
      );
  
    if (isAdditional) {
      setAdditionalStations((prevAdditionalStations) => updateStations(prevAdditionalStations));
    } else {
      setSelectedStations((prevSelectedStations) => updateStations(prevSelectedStations));
    }
  };

  // Abort event generation
  const handleCancel = () => {
    setIsModalVisible(false);
    setEventName('');
    setEventType(null);
    setServiceType(null);
    setForecastTime('');
  };

  // Generate event on confirmation
  const handleConfirm = async () => {
    if (!eventName || !eventType || selectedStations.length === 0 || (eventType === constants.event_type.salt && !serviceType) || !forecastTime) {
      messageApi.error('Please provide an event name, type, service type (if salt), forecast time, and select at least one weather station.');
      return;
    }

    try {
      setLoading(true);
      const response = await generateEvent(eventName, eventType, selectedStations, additionalStations, forecastTime, serviceType);
      if (response && response.event_id) {
        await setCurrentEvent(response.event_id);
        messageApi.success(`Event ${response.event_name} generated successfully!`);
      } else {
        messageApi.error('Failed to retrieve event ID.');
      }

      // Display forecast errors, if any
      if (response.forecast_errors && response.forecast_errors.length > 0) {
        notification.warning({
          message: 'Forecast Errors',
          description: (
            <div>
              <p>The following forecasts were not found in the Google Drive:</p>
              <ul style={{ paddingLeft: '10px' }}>
                {response.forecast_errors.map(([stationName, errorMessage], index) => (
                  <li key={index} style={{ marginBottom: '8px' }}>
                    <b>{stationName}</b>
                    <br />
                    <em>{errorMessage}</em>
                  </li>
                ))}
              </ul>
            </div>
          ),
          duration: 0
        });
      }

      loadActiveEvents();
      handleCancel();
    } catch (error) {
      if (error.response?.data?.error) {
        messageApi.error(error.response.data.error);
      } else {
        messageApi.error('Failed to generate the event.');
      }
    } finally {
      setLoading(false);
    }
  };

  const getWeatherIcon = (forecast) => {
    if (!forecast) return null;
  
    const lowerForecast = forecast.toLowerCase();
  
    if (lowerForecast.includes('snow')) {
      return <SnowIcon style={{ fontSize: '16px', color: '#F57D20' }} />;
    } else if (lowerForecast.includes('rain')) {
      return <RainIcon style={{ fontSize: '16px', color: '#F57D20' }} />;
    } else if (lowerForecast.includes('showers')) {
      return <RainIcon style={{ fontSize: '16px', color: '#F57D20' }} />;
    } else if (lowerForecast.includes('cloud')) {
      return <CloudIcon style={{ fontSize: '16px', color: '#F57D20' }} />;
    } else if (lowerForecast.includes('clear')) {
      return <SunIcon style={{ fontSize: '16px', color: '#F57D20' }} />;
    }
  
    return null;
  };

  if (constantsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spin />
      </Box>
    );
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F57D20',
          colorLinkHover: '#F57D20',
          colorLink: '#F57D20',
        },
      }}
    >
      {contextHolder}
      <Box display="flex" sx={{ width: '98vw', marginTop: '20px' }}>
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              marginBottom: '10px',
            }}
          >
            <Typography style={{ fontSize: '36px', fontWeight: '500', color: 'black' }}>
              Welcome <b>{user?.first_name}</b>, get ready to fight some snow!
            </Typography>
          </Box>

          {/* Active Events */}
          {events.length > 0 && (
            <Box
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Typography style={{ fontSize: '16px', fontWeight: '500', color: 'black', marginBottom: '5px' }}>
                Active Events:
              </Typography>

              {events.map((event) => (
                <Box
                  key={event.id}
                  style={{
                    backgroundColor: '#F57D20',
                    borderRadius: '5px',
                    width: '100%',
                    padding: '15px',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <Box style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px' }}>
                      <Typography style={{ fontSize: '24px', fontWeight: '600', color: 'white' }}>
                        {`${event.event_name} - ${event.service_type}`}
                      </Typography>
                    </Box>
                  </Box>
                  <Button type="text" style={{ color: 'white', border: 'none', fontWeight: '600' }} onClick={() => navigate('/events')}>
                    View
                  </Button>
                </Box>
              ))}
            </Box>
          )}

          {/* If no events, show message */}
          {events.length === 0 && (
            <Typography style={{ fontSize: '18px', fontWeight: '600', color: 'grey' }}>
              No active events.
            </Typography>
          )}

          {/* Filters */}
          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              marginTop: '16px',
              marginLeft: '-5px',
            }}
          >
            {tagsData.map((tag) => (
              <Tag.CheckableTag
                key={tag}
                checked={selectedTags.includes(tag)}
                onChange={(checked) => handleTagChange(tag, checked)}
                style={{ padding: '5px 10px', margin: '5px', fontSize: '14px' }}
              >
                {tag}
              </Tag.CheckableTag>
            ))}
            <Button type="primary" style={{ marginLeft: 'auto' }} onClick={handleGenerateEvent} disabled={selectedStations.length === 0}>
              Generate Event
            </Button>
          </Box>

          {/* Stations */}
          <Box style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'flex-start', marginLeft: '-10px' }}>
            {weatherStations.map((station) => (
              <Box
                key={station.weather_station_id}
                onClick={() => handleStationSelect(station)}
                style={{
                  backgroundColor: station.currentTemperture < 0 ? '#d4ebf2' : 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  padding: '15px',
                  borderRadius: '5px',
                  width: '264px',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                  margin: '10px',
                  gap: '5px',
                  cursor: 'pointer',
                  border: selectedStations.some(s => s.weather_station_id === station.weather_station_id) ? '2px solid #F57D20' : '2px solid transparent', // Apply border to selected stations
                  transition: 'border-color 0.3s',
                  ':hover': {
                    border: '2px solid #d6d6d6', // Hover effect
                  },
                }}
              >
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', gap: '10px' }}>
                  <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography style={{ fontSize: '20px', fontWeight: '600' }}>{station.station_name}</Typography>
                    <Typography style={{ fontSize: '14px', fontWeight: '400' }}>{station.province_state}</Typography>
                  </Box>
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #d6d6d6', height: '30px', width: '30px', borderRadius: '5px', backgroundColor: '#fafafa' }}>
                    {getWeatherIcon(station.curr_forecast)}
                  </Box>
                </Box>

                <Divider style={{ margin: '8px 0' }} />
                
                {/* <Typography style={{ fontSize: '18px', fontWeight: '500' }}>{station.curr_forecast}</Typography> */}
                <Typography style={{ fontSize: '14px', fontWeight: '400' }}>
                  Current: <b>{station.curr_temp} {station.country === 'Canada' ? '°C' : '°F'}</b>
                </Typography>
                <Typography style={{ fontSize: '14px', fontWeight: '400' }}>
                  Forecast Low: <b>{station.curr_forecasted_lo} {station.country === 'Canada' ? '°C' : '°F'}</b>
                </Typography>
                <Typography style={{ fontSize: '14px', fontWeight: '400' }}>
                  Forecast High: <b>{station.curr_forecasted_hi} {station.country === 'Canada' ? '°C' : '°F'}</b>
                </Typography>
                <Typography style={{ fontSize: '14px', fontWeight: '400' }}>
                  Forecast Accumulation: <b>{ station.curr_forecasted_accum ? `${station.curr_forecasted_accum} ${station.country === 'Canada' ? 'cm' : 'in'}` : 'Not Available'}</b>
                </Typography>
              </Box>
            ))}
            {weatherStations.length === 0 && (
              <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100px' }}>
                <Typography style={{ fontSize: '18px', fontWeight: '600', color: '#d6d6d6' }}>No stations found</Typography>
              </Box>
            )}
          </Box>

          {/* Modal for Generating Event */}
          <Modal
            title={<Typography style={{ fontSize: '24px', fontWeight: '600' }}>Start New Event</Typography>}
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            {/* Event Name Section */}
            <Box marginBottom="16px">
              <Title level={5}>Event Name</Title>
              <Input
                placeholder="Enter Event Name"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                style={{ marginTop: '8px' }}
              />
            </Box>

            <Divider />

            {/* Event Type Section */}
            <Box marginBottom="16px">
              <Title level={5}>Event Type</Title>
              <Box display="flex" justifyContent="space-around" marginTop="8px" marginBottom="16px">
                <Button
                  type={eventType === constants.event_type.salt ? 'primary' : 'default'}
                  icon={<CloudIcon />}
                  onClick={() => setEventType(constants.event_type.salt)}
                >
                  Salt
                </Button>
                <Button
                  type={eventType === constants.event_type.snow ? 'primary' : 'default'}
                  icon={<SnowIcon />}
                  onClick={() => setEventType(constants.event_type.snow)}
                >
                  Snow
                </Button>
              </Box>
            </Box>

            {/* Service Type Options for Salt */}
            {eventType === constants.event_type.salt && (
              <Box marginBottom="16px">
                <Divider />
                <Box marginTop="16px" marginBottom="16px">
                  <Title level={5}>Service Type</Title>
                  <Box display="flex" justifyContent="space-around" marginTop="8px">
                    <Button
                      type={serviceType === 'Anti-Icing' ? 'primary' : 'default'}
                      icon={<AntiIcingIcon />}
                      onClick={() => setServiceType('Anti-Icing')}
                    >
                      Anti-Icing
                    </Button>
                    <Button
                      type={serviceType === 'De-Icing' ? 'primary' : 'default'}
                      icon={<DeIcingIcon />}
                      onClick={() => setServiceType('De-Icing')}
                    >
                      De-Icing
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}

            <Divider />
            {/* Forecast Time */}
            <Box marginTop="16px">
              <Title level={5}>Forecast Time</Title>
              <Box display="flex" justifyContent="space-around" marginTop="8px" marginBottom="16px">
                <Button
                  type={forecastTime === '8am' ? 'primary' : 'default'}
                  onClick={() => setForecastTime('8am')}
                >
                  8am
                </Button>
                <Button
                  type={forecastTime === '11am' ? 'primary' : 'default'}
                  onClick={() => setForecastTime('11am')}
                >
                  11am
                </Button>
              </Box>
            </Box>

            {/* Selected Stations Forecast Source Section */}
            <Box marginTop="16px">
              <Title level={5}>Weather Stations Forecast Source</Title>
              {selectedStations.map(station => (
                <Box key={station.weather_station_id} display="flex" justifyContent="space-between" alignItems="center" marginY="8px">
                  <Typography sx={{ marginLeft: '16px' }}>{station.station_name}</Typography>
                  <Radio.Group
                    value={station.pdf_source}
                    onChange={(e) => handleForecastSourceChange(station.weather_station_id, e.target.value)}
                  >
                    {station.country === constants.country.canada &&
                      <Radio.Button value={constants.forecast_source.ec}>Env Canada</Radio.Button>
                    }
                    {station.country === constants.country.usa &&
                      <Radio.Button value={constants.forecast_source.nws}>NWS</Radio.Button>
                    }
                    <Radio.Button value={constants.forecast_source.wn}>Weather Network</Radio.Button>
                  </Radio.Group>
                </Box>
              ))}
            </Box>

            {/* Additional Stations Forecast Source Section */}
            {additionalStations.length > 0 &&
              <Box marginTop="16px" marginBottom="32px">
                <Title level={5}>
                  Additional Stations Forecast Source<br />(Associated With Event Route Sites)
                </Title>
                {additionalStations.map(station => (
                  <Box key={station.weather_station_id} display="flex" justifyContent="space-between" alignItems="center" marginY="10px">
                    <Typography sx={{ marginLeft: '16px' }}>{station.station_name}</Typography>
                    <Radio.Group
                      value={station.pdf_source}
                      onChange={(e) => handleForecastSourceChange(station.weather_station_id, e.target.value, true)}
                    >
                      <Radio.Button value={station.country === constants.country.canada ? constants.forecast_source.ec : constants.forecast_source.nws}>{station.country === constants.country.canada ? 'Env Canada' : 'NWS'}</Radio.Button>
                      <Radio.Button value={constants.forecast_source.wn}>Weather Network</Radio.Button>
                    </Radio.Group>
                  </Box>
                ))}
              </Box>
            }

            {/* Footer Buttons */}
            <Box display="flex" justifyContent="space-between">
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" onClick={handleConfirm} loading={loading}>
                Start Event
              </Button>
            </Box>
          </Modal>
        </Box>
      </Box>
    </ConfigProvider>
  );
};

export default DashboardPage;
