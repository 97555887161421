import React, { useState, useEffect } from 'react';
import { fetchWeatherData } from '../api';
import { Typography, Grid, Skeleton } from '@mui/material';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import CloudIcon from '@mui/icons-material/Cloud';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import SearchIcon from '@mui/icons-material/Search';
import { Input } from 'antd';

const cityUrls = {
  // TODO: add more
  vancouver: 'https://weather.gc.ca/city/pages/bc-74_metric_e.html',
  squamish: 'https://weather.gc.ca/city/pages/bc-50_metric_e.html',
  richmond: 'https://weather.gc.ca/city/pages/bc-96_metric_e.html',
  pitt_meadows: 'https://weather.gc.ca/city/pages/bc-35_metric_e.html',
  duncan: 'https://weather.gc.ca/city/pages/bc-97_metric_e.html',
  victoria: 'https://weather.gc.ca/city/pages/bc-85_metric_e.html',
  portland: 'https://forecast.weather.gov/MapClick.php?lat=45.52344714800046&lon=-122.67620703599971',
  seattle: 'https://forecast.weather.gov/MapClick.php?lat=47.6032&lon=-122.3303',
  bothell: 'https://forecast.weather.gov/MapClick.php?lat=47.7601&lon=-122.2054',
  edmonds: 'https://forecast.weather.gov/MapClick.php?lat=47.8116&lon=-122.3766',
  snoqualmie: 'https://forecast.weather.gov/MapClick.php?lat=47.5272&lon=-121.8242',
  bellevue: 'https://forecast.weather.gov/MapClick.php?lat=47.6137&lon=-122.1909',
  seatac: 'https://forecast.weather.gov/MapClick.php?lat=47.4453&lon=-122.2945',
  gresham: 'https://forecast.weather.gov/MapClick.php?lat=45.4967&lon=-122.4276',
  clackamas: 'https://forecast.weather.gov/MapClick.php?lat=45.4075&lon=-122.5688',
  happy_valley: 'https://forecast.weather.gov/MapClick.php?lat=45.447&lon=-122.5308',
  milwaukie: 'https://forecast.weather.gov/MapClick.php?lat=45.4455&lon=-122.6417',
  hillsboro: 'https://forecast.weather.gov/MapClick.php?lat=45.524&lon=-122.9897',
  beaverton: 'https://forecast.weather.gov/MapClick.php?lat=45.4866&lon=-122.7956',
  duvall: 'https://forecast.weather.gov/MapClick.php?lat=47.7423&lon=-121.9857',
  kirkland: 'https://forecast.weather.gov/MapClick.php?lat=47.6769&lon=-122.206',
};

const WeatherData = ({ eventId = null }) => {
  const [weatherData, setWeatherData] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchWeatherData(eventId);
        setWeatherData(data);
      } catch (error) {
        console.error('Error fetching weather data:', error);
        setWeatherData(null);
      }
    };
    fetchData();
  }, [eventId]);

  const capitalizeCity = (city) => {
    return city
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const renderWeatherItem = (city, data, country) => {
    const cityNameCapitalized = capitalizeCity(city);
    const countryFlag = country === 'Canada' ? '🇨🇦' : '🇺🇸';
  
    const getIcon = (summary) => {
      const forecast = summary.toLowerCase();
      if (forecast.includes('sunny')) return <WbSunnyIcon fontSize="inherit" />;
      if (forecast.includes('cloudy')) return <CloudIcon fontSize="inherit" />;
      if (forecast.includes('snow')) return <AcUnitIcon fontSize="inherit" />;
      if (forecast.includes('clear')) return <NightsStayIcon fontSize="inherit" />;
      return <CloudIcon fontSize="inherit" />; // default icon
    };
  
    const dayTemperature = country === 'US' ? `${data.day.temperature}°F` : `${data.day.temperature}°C`;
    const nightTemperature = country === 'US' ? `${data.night.temperature}°F` : `${data.night.temperature}°C`;
    const accumulation = data.accumulation
      ? `${data.accumulation} ${country === 'Canada' ? 'cm' : 'in'}`
      : '';

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          padding: '10px',
          backgroundColor: '#fafafa',
          borderRadius: '10px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease',
        }}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#eaeaea')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#fafafa')}
        onClick={() => window.open(cityUrls[city], '_blank', 'noopener noreferrer')}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            paddingLeft: '5px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '5px',
            }}
          >
            <span style={{ fontSize: '18px', fontWeight: '500' }}>
              {cityNameCapitalized}
            </span>
            <span style={{ fontSize: '12px' }}>{countryFlag}</span>
          </div>
          <span style={{ fontSize: '14px' }}>
            {`Accumulation: ${accumulation}`}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexDirection: 'column',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', width: '120px' }}>
            <Typography style={{ fontSize: '14px', marginRight: '10px' }}>Day:</Typography>
            {getIcon(data.day.summary)}
            <Typography style={{ fontSize: '16px', marginLeft: '5px' }}>{dayTemperature}</Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', width: '120px' }}>
            <Typography style={{ fontSize: '14px', marginRight: '10px' }}>Night:</Typography>
            {getIcon(data.night.summary)}
            <Typography style={{ fontSize: '16px', marginLeft: '5px' }}>{nightTemperature}</Typography>
          </div>
        </div>
      </div>
    );
  };

  const renderWeatherSection = (locations) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          gap: '5px',
          width: '100%',
        }}
      >
        {locations.map((location) => {
          if (weatherData && weatherData[location.city]) {
            return renderWeatherItem(
              location.city,
              weatherData[location.city],
              location.country
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  };

  const canadianLocations = [
    'vancouver',
    'north_vancouver',
    'west_vancouver',
    'richmond',
    'burnaby',
    'new_westminster',
    'delta',
    'surrey',
    'langley',
    'abbotsford',
    'coquitlam',
    'port_coquitlam',
    'port_moody',
    'pitt_meadows',
    'duncan',
    'victoria',
    'squamish'
  ];
  const usLocations = [
    'mount_vernon',
    'marysville',
    'bothell',
    'mill_creek',
    'woodinville',
    'duvall',
    'edmonds',
    'snoqualmie',
    'bellevue',
    'kirkland',
    'seattle',
    'kent',
    'seatac',
    'federal_way',
    'auburn',
    'puyallup',
    'lacey',
    'lakewood',
    'tacoma',
    'kelso',
    'portland',
    'gresham',
    'clackamas',
    'happy_valley',
    'hillsboro',
    'milwaukie',
    'beaverton'
  ];

  const locations = [
    ...canadianLocations.map((city) => ({ city, country: 'Canada' })),
    ...usLocations.map((city) => ({ city, country: 'US' })),
  ];

  const filteredLocations = locations.filter((location) =>
    location.city.includes(search.toLowerCase())
  );

  return (
    <div
      style={{
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        backgroundColor: 'white',
        padding: '15px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography variant="h5" align="left">
        Weather Forecasts
      </Typography>
      <Input
        placeholder="Search city"
        onChange={(e) => setSearch(e.target.value)}
        style={{ width: '100%', marginTop: '10px' }}
        prefix={<SearchIcon style={{ color: '#c2c2c2' }} fontSize="12px" />}
      />
      {!weatherData ? (
        <Skeleton variant="rectangular" width="100%" height={400} />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
            marginTop: '15px',
            overflowY: 'auto',
          }}
        >
          {renderWeatherSection(filteredLocations)}
        </div>
      )}
    </div>
  );
};

export default WeatherData;