import React, { useState } from 'react';
import { Descriptions, Tag, List, Typography, Divider, Card, Button, message } from 'antd';
import { DownloadOutlined, EditFilled } from '@ant-design/icons';
import { useConstants } from '../context/ConstantsContext';
import { fetchForecastPDF } from '../api';
import { formatCurrency } from '../utils/stringUtils';
import { formatUtcDateTime } from '../utils/dateUtils';

const PurchaseOrderDetails = ({ purchaseOrder }) => {
  const constants = useConstants();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const colorMap = {
    [constants.status.approved]: {
      text: 'green',
      border: 'green',
      background: 'rgba(0, 128, 0, 0.1)',
    },
    [constants.status.incomplete]: {
      text: '#DAA520',
      border: '#DAA520',
      background: 'rgba(218, 165, 32, 0.1)',
    },
    [constants.status.awaiting]: {
      text: 'blue',
      border: 'blue',
      background: 'rgba(0, 0, 255, 0.1)',
    },
    [constants.status.pending]: {
      text: 'red',
      border: 'red',
      background: 'rgba(255, 0, 0, 0.1)',
    },
    [constants.status.cancelled]: {
      text: 'grey',
      border: 'grey',
      background: 'rgba(128, 128, 128, 0.1)',
    },
  };

  if (!purchaseOrder) {
    return <Typography>No purchase order data available</Typography>;
  }

  const onDownloadForecastPdf = async (forecast) => {
    try {
      // Fetch the PDF as a blob
      const pdfBlob = await fetchForecastPDF(forecast.forecast_id);

      // Create a URL for the blob
      const pdfUrl = window.URL.createObjectURL(new Blob([pdfBlob], { type: 'application/pdf' }));

      // Create a temporary link element and trigger the download
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = `${forecast.station_name} Forecast ${forecast.date}.pdf`;
      link.click();

      // Revoke the object URL after the download
      window.URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      messageApi.error('Failed to download the forecast PDF');
      console.error('Error fetching forecast PDF:', error);
    }
  };

  const renderServiceHistories = () => {
    if (!purchaseOrder.service_histories || purchaseOrder.service_histories.length === 0) {
      return <Typography>No service histories available</Typography>;
    }

    return (
      <List
        itemLayout="horizontal"
        bordered
        dataSource={purchaseOrder.service_histories}
        renderItem={(history) => (
          <Card>
            <Descriptions title={`Service History ID: ${history.id}`} bordered column={1} size="small" labelStyle={{ width: '150px' }}>
              <Descriptions.Item label="Event Name">{history.event_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Route Name">{history.route_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Subcontractor Name">{history.subcontractor_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Site Name">{history.site_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Client Name">{history.client_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Services">{history.services || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Started At">{formatUtcDateTime(history.started_at)}</Descriptions.Item>
              <Descriptions.Item label="Ended At">{formatUtcDateTime(history.ended_at)}</Descriptions.Item>
              <Descriptions.Item label="Duration">{history.duration ? `${history.duration} hours` : 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Equipment Used">
                {history.equipment && history.equipment.length > 0 ? (
                  <List
                    dataSource={history.equipment}
                    renderItem={(equipment) => (
                      <List.Item>
                        <Typography>{equipment.name} - {equipment.rate ? `${equipment.rate} $/hr` : 'Rate Unavailable'}</Typography>
                      </List.Item>
                    )}
                    bordered={false}
                    size="small"
                  />
                ) : (
                  <Typography>N/A</Typography>
                )}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        )}
      />
    );
  };

  return (
    <>
      {contextHolder}

      {/* Purchase Order Details */}
      <Descriptions title="Purchase Order Details" bordered column={1} labelStyle={{ width: '150px' }}>
        <Descriptions.Item label="PO #">{purchaseOrder.precoro_idn}</Descriptions.Item>
        <Descriptions.Item label="PO ID">{purchaseOrder.po_id}</Descriptions.Item>
        <Descriptions.Item label="Contract Type">{purchaseOrder.contract_type || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Total Duration">{purchaseOrder.total_duration.toFixed(3)} hours</Descriptions.Item>
        { purchaseOrder.country === constants.country.canada &&
          <>
            <Descriptions.Item label="Net Total">{formatCurrency(purchaseOrder.custom_amount ?? purchaseOrder.total_amount_sum, purchaseOrder.country, constants)}</Descriptions.Item>
            <Descriptions.Item label={`GST ${constants.taxes.cad_tax_rate * 100}%`}>{formatCurrency((purchaseOrder.custom_amount ?? purchaseOrder.total_amount_sum) * constants.taxes.cad_tax_rate, purchaseOrder.country, constants)}</Descriptions.Item>
            <Descriptions.Item label="Gross Total">
              {formatCurrency((purchaseOrder.custom_amount ?? purchaseOrder.total_amount_sum) * (1 + constants.taxes.cad_tax_rate), purchaseOrder.country, constants)}
              {purchaseOrder.custom_amount && <EditFilled style={{ marginLeft: '8px', fontSize: '0.9em', color: '#F57D20' }} />}
            </Descriptions.Item>
          </>
        }
        { purchaseOrder.country === constants.country.usa &&
          <Descriptions.Item label="Gross Total">
            {formatCurrency((purchaseOrder.custom_amount ?? purchaseOrder.total_amount_sum), purchaseOrder.country, constants)}
            {purchaseOrder.custom_amount && <EditFilled style={{ marginLeft: '8px', fontSize: '0.9em', color: '#F57D20' }} />}
          </Descriptions.Item>
        }
        <Descriptions.Item label="Status">
          <Tag
            style={{
              color: colorMap[purchaseOrder.status]?.text,
              borderColor: colorMap[purchaseOrder.status]?.border,
              backgroundColor: colorMap[purchaseOrder.status]?.background,
            }}
          >
            {purchaseOrder.status}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Created By">{purchaseOrder.created_by || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Created At">{formatUtcDateTime(purchaseOrder.created_at)}</Descriptions.Item>
      </Descriptions>

      <Divider />

      {/* Event & Subcontractor Information */}
      <Descriptions title="Subcontractor & Event Information" bordered column={1} labelStyle={{ width: '150px' }}>
        <Descriptions.Item label="Subcontractor Name">{purchaseOrder.subcontractor_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Site Name">{purchaseOrder.site_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Route Name">{purchaseOrder.route_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Zone">{purchaseOrder.zone_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Event Name">{purchaseOrder.event_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Event Type">{purchaseOrder.event_type || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Service Type">{purchaseOrder.service_type || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Event Date">{purchaseOrder.event_date || 'N/A'}</Descriptions.Item>
      </Descriptions>

      <Divider />

      {/* Weather Details */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
        <Typography.Title level={5} style={{ margin: 0 }}>Weather Details</Typography.Title>
        <Button
          type="secondary"
          icon={<DownloadOutlined />}
          loading={loadingPdf}
          onClick={() => onDownloadForecastPdf(purchaseOrder.forecast)}
        >
          Download PDF
        </Button>
      </div>
      <Descriptions bordered column={1} labelStyle={{ width: '150px' }}>
        <Descriptions.Item label="Station Name">{purchaseOrder.forecast.station_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Country">{purchaseOrder.forecast.country || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Forecast Date">{purchaseOrder.forecast.date || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Forecast">{purchaseOrder.forecast.forecast || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Low Temp">{purchaseOrder.forecast.low_temp}°C</Descriptions.Item>
        <Descriptions.Item label="High Temp">{purchaseOrder.forecast.high_temp}°C</Descriptions.Item>
        <Descriptions.Item label="Forecasted Accumulation">{purchaseOrder.forecast.forecast_accum} cm</Descriptions.Item>
        <Descriptions.Item label="Actual Accumulation">
          {purchaseOrder.forecast.actual_accum != null ? (
            `${purchaseOrder.forecast.actual_accum} cm`
          ) : (
            <Tag color="red">Weather Data Incomplete</Tag>
          )}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      {/* Service Histories */}
      {renderServiceHistories()}
    </>
  );
};

export default PurchaseOrderDetails;