// WeatherHistoryPage.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { Table, Button, ConfigProvider, Select, message, Card, Tag, Input, Tooltip } from 'antd';
import SideMenu from '../components/SideMenu';
import {
  fetchEvents,
  fetchEventForecasts,
  updateForecastAccumulation,
  fetchForecastPDF,
  syncForecastPDF
} from '../api';
import { useCurrentEvent } from '../context/EventContext';
import { useConstants } from '../context/ConstantsContext';
import '../styles/ButtonStyles.css'
import '../styles/CustomCheckbox.css'
import { DownloadOutlined, CheckOutlined, CloseOutlined, EditOutlined, WarningOutlined, SyncOutlined} from '@ant-design/icons';

const EventHistoryPage = () => {
  const constants = useConstants();
  const { currentEvent, setCurrentEvent } = useCurrentEvent();
  const [events, setEvents] = useState([]);
  const [forecasts, setForecasts] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [editingKey, setEditingKey] = useState(null);
  const [editingValue, setEditingValue] = useState(null);
  const [syncingForecasts, setSyncingForecasts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const loadEvents = useCallback(async () => {
    const events = await fetchEvents({ active: 'false', order: 'desc' });
    setEvents(events)
  }, []);

  const loadForecasts = async (eventId) => {
    setLoading(true);
    try {
      const forecast_data = await fetchEventForecasts(eventId);
      
      const sortedForecasts = forecast_data.sort((a, b) => {
        if (a.country !== b.country) {
          return a.country.localeCompare(b.country);
        }
        if (a.province_state !== b.province_state) {
          return a.province_state.localeCompare(b.province_state);
        }
        return a.station_name.localeCompare(b.station_name);
      });
      
      setForecasts(sortedForecasts);
    } catch (error) {
      console.error('Error fetching forecasts:', error);
      messageApi.error('Failed to fetch forecasts');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadEvents();
    setSelectedEvent(currentEvent);
    if (currentEvent) {
      loadForecasts(currentEvent.event_id);
    }
  }, [loadEvents, currentEvent]);

  const handleEventChange = async (eventId) => {
    try {
      const selectedEventObj = events.find(event => event.event_id === eventId);
      if (selectedEventObj) {
        setCurrentEvent(eventId);
        loadForecasts(eventId);
      }
    } catch (error) {
      messageApi.error('Error fetching event details');
      console.error(error);
    }
  };

  const editAccumulation = (record) => {
    setEditingKey(record.forecast_id);
    setEditingValue(record.actual_accum);
  };

  const cancelEditAccumulation = () => {
    setEditingKey(null);
    setEditingValue(null);
  };

  const saveEditAccumulation = async (record) => {
    try {
      await updateForecastAccumulation(record.forecast_id, editingValue);
      const updatedForecasts = forecasts.map((item) =>
        item.forecast_id === record.forecast_id
          ? { ...item, actual_accum: editingValue }
          : item
      );
      setForecasts(updatedForecasts);
      messageApi.success('Forecast actual accumulation updated successfully');
      cancelEditAccumulation();
    } catch (error) {
      messageApi.error('Failed to update forecast actual accumulation');
      cancelEditAccumulation();
    }
  };

  const handleForecastSync = async (forecastId) => {
    setSyncingForecasts((prev) => [...prev, forecastId]);
    try {
      const response = await syncForecastPDF(forecastId);
      if (response.message) {
        messageApi.success(response.message);
      } else {
        messageApi.success('Forecast PDF synced successfully');
      }
      loadForecasts(currentEvent.event_id);
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Failed to fetch forecast PDF from the Google Drive';
      messageApi.error(errorMessage);
      console.error('Error syncing forecast PDF:', error);
    } finally {
      setSyncingForecasts((prev) => prev.filter((id) => id !== forecastId));
    }
  };

  const forecastColumns = [
    {
      title: 'Weather Station Name',
      dataIndex: 'station_name',
      key: 'station_name',
      width: '20%',
    },
    {
      title: 'Forecast',
      dataIndex: 'forecast',
      key: 'forecast',
      width: '25%',
    },
    {
      title: 'Forecast Low',
      dataIndex: 'low_temp',
      key: 'low_temp',
      width: '10%',
      render: (low_temp, record) => (
        <span>
          {low_temp}°{record.country === constants.country.canada ? 'C' : 'F'}
        </span>
      ),
    },
    {
      title: 'Forecast High',
      dataIndex: 'high_temp',
      key: 'high_temp',
      width: '10%',
      render: (high_temp, record) => (
        <span>
          {high_temp}°{record.country === constants.country.canada ? 'C' : 'F'}
        </span>
      ),
    },
    {
      title: 'Forecast Accum',
      dataIndex: 'forecast_accum',
      key: 'forecast_accum',
      width: '10%',
      render: (forecast_accum, record) => (
        <span>
          {forecast_accum} {record.country === constants.country.canada ? 'cm' : 'inches'}
        </span>
      ),
    },
    {
      title: 'Actual Accum',
      dataIndex: 'actual_accum',
      key: 'actual_accum',
      width: '15%',
      align: 'center',
      render: (actual_accum, record) =>
        editingKey === record.forecast_id ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Input
              value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              style={{ width: '60%', marginRight: '8px' }}
              autoFocus
            />
            <CheckOutlined
              onClick={() => saveEditAccumulation(record)}
              style={{ color: 'green', cursor: 'pointer', marginRight: '8px' }}
            />
            <CloseOutlined
              onClick={cancelEditAccumulation}
              style={{ color: 'red', cursor: 'pointer' }}
            />
          </div>
        ) : actual_accum !== null ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span
              onClick={() => editAccumulation(record)}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '5px' }}
            >
              {actual_accum} {record.country === constants.country.canada ? 'cm' : 'inches'}
              <EditOutlined style={{ fontSize: '0.8em', color: 'gray' }} />
            </span>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Tag color="red" onClick={() => editAccumulation(record)}>
              Weather Data Incomplete
            </Tag>
          </div>
        ),
    },
    {
      title: 'Forecast PDF',
      dataIndex: 'forecast_id',
      key: 'forecast_id',
      width: '10%',
      align: 'center',
      render: (forecast_id, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* Download or Warning Icon */}
          {record.pdf_available ? (
            <Button
              type="secondary"
              shape="circle"
              icon={<DownloadOutlined />}
              onClick={async (e) => {
                try {
                  // Fetch the PDF as a blob
                  const pdfBlob = await fetchForecastPDF(forecast_id);
        
                  // Create a URL for the blob
                  const pdfUrl = window.URL.createObjectURL(new Blob([pdfBlob], { type: 'application/pdf' }));
        
                  // Create a temporary link element and trigger the download
                  const link = document.createElement('a');
                  link.href = pdfUrl;
                  link.download = `${record.station_name} Forecast ${record.date}.pdf`;
                  link.click();
        
                  // Revoke the object URL after the download
                  window.URL.revokeObjectURL(pdfUrl);
                } catch (error) {
                  messageApi.error('Failed to download the forecast PDF');
                  console.error('Error fetching forecast PDF:', error);
                }
              }}
            />
          ) : (
            <Tooltip
              title={
                <>
                  The forecast PDF is unavailable. <br />
                  Please upload the PDF to the Google Drive at: <br />
                  {record.pdf_path}
                </>
              }
            >
              <Button
                type="secondary"
                shape="circle"
                icon={<WarningOutlined style={{ color: 'goldenrod' }} />}
              />
            </Tooltip>
          )}
    
          {/* Edit Button */}
          <Tooltip
            title="Sync Forecast PDF with Google Drive"
          >
            <Button
              type="secondary"
              shape="circle"
              icon={
                syncingForecasts.includes(record.forecast_id) ? (
                  <SyncOutlined spin />
                ) : (
                  <SyncOutlined />
                )
              }
              onClick={() => handleForecastSync(record.forecast_id)}
              disabled={syncingForecasts.includes(record.forecast_id)}
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  const [tableHeight, setTableHeight] = useState('100vh');
  const titleBoxRef = useRef(null);
  const tableRef = useRef(null);

  useEffect(() => {
    const calculateTableHeight = () => {
      const headerHeight = 72;
      const titleBoxHeight = titleBoxRef.current ? titleBoxRef.current.offsetHeight : 32;
      const padding = 16;

      // Calculate height of the table header
      const tableHeaderHeight = tableRef.current ? tableRef.current.querySelector('.ant-table-thead').offsetHeight : 48;

      const totalHeightAroundTableRows =
        headerHeight +
        titleBoxHeight +
        padding * 4 +
        tableHeaderHeight;

      setTableHeight(`calc(100vh - ${totalHeightAroundTableRows}px)`);
    };

    if (selectedEvent) {
        calculateTableHeight();
    }

    window.addEventListener('resize', calculateTableHeight);
    return () => window.removeEventListener('resize', calculateTableHeight);
  }, [selectedEvent]);

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#F57D20', colorLinkHover: '#F57D20', colorLink: '#F57D20' } }}>
      {contextHolder}
      <Box display="flex">
        {/* Side Menu */}
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>
        
        {/* Main Content */}
        <Card
          style={{
            height: 'calc(100vh - 72px - 16px)',
            marginRight: '16px',
            overflow: 'hidden',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
          }}
        >

          {/* Header and Event Selection */}
          <Box ref={titleBoxRef} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <Typography variant="h5" component="div">
              Weather History
            </Typography>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Select
                placeholder="Select Event"
                style={{ width: 320 }}
                options={events.map((event) => ({
                  label: `${event.event_name} - ${event.service_type}`,
                  value: event.event_id,
                }))}
                value={selectedEvent?.event_id || null}
                onChange={handleEventChange}
              />
            </Box>
          </Box>

          <Box ref={tableRef} sx={{ overflow: 'hidden', marginTop: '16px' }}>
            <Table
              loading={loading}
              dataSource={forecasts}
              columns={forecastColumns}
              rowKey="forecast_id"
              pagination={false}
              scroll={{ y: tableHeight }}
              locale={{
                emptyText: 'No weather stations',
              }}
            />
          </Box>
        </Card>
      </Box>
    </ConfigProvider>
  );
};

export default EventHistoryPage;
